import { Header as NavBar } from '../Components/Header';
import shape02 from "../../../Assets/img/shape02.svg";
import shape05 from "../../../Assets/img/shape05.svg";
import shape06 from "../../../Assets/img/shape06.svg";
import shape07 from "../../../Assets/img/shape07.svg";
import figura03 from "../../../Assets/img/figura03.svg";
import { NoticiasSlider } from '../Components/NoticiasSlider';
import { home_novedades } from '../diccionario';
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg';
import googlePlayBadge from "../../../Assets/img/playStoreLogo.png";
import appStoreBadge from "../../../Assets/img/badge_app-store_black_es.svg";
import useSEOConfig from '../../../hooks/seo/useSEOConfig';

const accordionData = [
    {
        title: "SOBRE NOSOTROS",
        items: [
            {
                question: "¿Quiénes somos?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Somos un bróker integral digital de seguros, acompañamos a nuestros clientes en todo el proceso de cotización, comparación de coberturas, y contratación de seguros.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Ofrecemos una amplia variedad de seguros para individuos y empresas, entre ellos; auto, moto, hogar, prepaga médica, ART, AP, agro, caución, embarcación y muchos más. Sea cual sea tu necesidad, estamos aquí para ayudarte a encontrar la mejor opción.</p>
                </>
            },
            {
                question: "¿Dónde nos encontrás?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Tenemos a disposición todos nuestros canales digitales para que nos escribas desde la comodidad de tu casa, oficina, mientras viajas, ¡de dónde sea! Contactános por:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>WhatsApp: <a href="https://wa.me/5491169568002" target="_blank" rel='noreferrer' className="text-decoration-underline">+54 9 11 6956-8002</a></li>
                        <li>Teléfono:&nbsp;<a href="tel:08104447348" target="_blank" rel='noreferrer' className="text-decoration-underline">0810-444-7348</a></li>
                    </ul>

                    <p className="fs-15 fs-lg-16 lh-16 mb-0">Además si sos cliente lo podés hacer desde la <b>App MiSegurarse</b>. Descargala:</p>
                    <ul className="list-unstyled mb-20">
                        <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&amp;hl=en&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel='noreferrer'><img className="mt-2" height="38" alt="Disponible en Google Play" src={googlePlayBadge} /></a></li>
                        <li className="list-inline-item"><a href="https://apps.apple.com/ar/app/mi-segurarse/id1237342800?l=es&ls=1" target="_blank" rel='noreferrer'><img className="mt-2" height="38" alt="Descárgalo en el App Store" src={appStoreBadge} /></a></li>
                    </ul>
                    <p className="fs-15 fs-lg-16 lh-16 mb-0">Horarios:</p>
                    <ul className="fs-15 fs-lg-16 lh-16">
                        <li>Ventas: lunes a viernes de 08:30 a 20:30hs. </li>
                        <li>Atención al cliente: lunes a viernes de 09:00 a 18:00hs.</li>
                    </ul>
                </>
            },
            {
                question: "¿Por qué elegirnos?",
                answer: <p className="fs-15 fs-lg-16 lh-16 mb-20">Simplificamos todos tus trámites y te acercamos la mejor propuesta. Ofrecemos servicios antes, durante y después de la contratación, que incluyen asistencia en emisiones de pólizas, gestión de cobranzas y sinestros. Estamos comprometidos en brindarte continuo soporte y que tengas la mejor experiencia aseguradora junto a nosotros.​</p>
            },
            {
                question: "¿Con qué compañías trabajamos?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Tenemos la mayor y mejor oferta aseguradora online conectada a nuestros servidores en real time. Reconocidas por su trayectoria y respaldo. ​</p>
                    <ul className="fs-15 fs-lg-16 lh-16">
                        <li>Sancor Seguros</li>
                        <li>Experta</li>
                        <li>Mercantil Andina</li>
                        <li>Provincia</li>
                        <li>San Cristobal</li>
                        <li>Allianz</li>
                        <li>Zurich</li>
                        <li>Galicia Seguros</li>
                        <li>Mapfre</li>
                        <li>La Meridional</li>
                        <li>Integrity</li>
                        <li>SMG</li>
                        <li>Chubb</li>
                        <li>Orbis</li>
                        <li>Prevención Salud</li>
                        <li>Federación Patronal</li>
                        <li>Berkley</li>
                        <li>Galeno</li>
                        <li>Cruz Suiza</li>
                    </ul>
                </>
            }
        ]
    },
    {
        title: "APP MI SEGURARSE",
        items: [
            {
                question: "¿Qué puedo hacer desde la App?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Nuestra App MiSegurarse te permite autogestionar tus pólizas y seguros estés donde estés 24/7. Vas a poder:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Ver y descargar tus pólizas</li>
                        <li>Tener siempre en el celu documentación actualizada</li>
                        <li>Denunciar un siniestro (ya sea un choque, robo de ruedas, roturas de cristales, entre otros)</li>
                        <li>Solicitar asistencia mecánica</li>
                        <li>Autorizar a un tercero a que circule con tu vehículo</li>
                        <li>Controlar el estado de tu cuota, modificar el medio de pago, y descargar el comprobante</li>
                    </ul>
                </>
            },
            {
                question: "¿Cómo descargo la App?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-0">¡Es muy fácil! Hacé clic en el botón que corresponda a tu sistema operativo y seguí los pasos indicados</p>
                    <ul className="list-unstyled mb-20">
                        <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&amp;hl=en&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel='noreferrer'><img className="mt-2" height="38" alt="Disponible en Google Play" src={googlePlayBadge} /></a></li>
                        <li className="list-inline-item"><a href="https://apps.apple.com/ar/app/mi-segurarse/id1237342800?l=es&ls=1" target="_blank" rel='noreferrer'><img className="mt-2" height="38" alt="Descárgalo en el App Store" src={appStoreBadge} /></a></li>
                    </ul>
                </>
            },
            {
                question: "¿Cómo me registro?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">¡Es muy fácil! Sólo tenes que seguir estos pasos:</p>
                    <ol className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Descargate la App</li>
                        <li>Hace clic en el botón "Registrarse"</li>
                        <li>Completá tu Tipo y N° de documento</li>
                        <li>Crea tu contraseña (¡que sea fácil de recordar! 😉)</li>
                        <li>Ingresá a tu mail y hacé clic en el link que te enviamos para activar tu cuenta</li>
                    </ol>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">¡Listo! Ya podés autogestionar tu seguro desde cualquier lugar 24/7</p>
                </>
            }
        ]
    },
    {
        title: "SOBRE MI PÓLIZA Y COBERTURA",
        items: [
            {
                question: "Ya contraté mi seguro, ¿cuándo se hace efectiva la cobertura?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">La cobertura no comienza en la fecha en que solicitas la cotización o completas la solicitud de seguro, sino en la <b>fecha de inicio que se establece en la póliza</b>.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Tené en cuenta que, en algunos casos, la emisión de la póliza puede requerir inspección. Una vez que la inspección se realice y la aseguradora elegida la confirme, estarás seguro.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">No te preocupes, que una vez que la inspección sea aprobada, te enviaremos la póliza a tu mail y ya estarás asegurado.</p>
                </>
            },
            {
                question: "¿Cuánto demora la emisión de póliza?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">El tiempo para emitir una póliza de seguro varía de acuerdo a diversos factores.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">En el caso de las pólizas de automotor que requieren una inspección vehicular, el proceso puede demorar hasta 96 horas una vez aprobada la inspección por la compañía aseguradora.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Nuestro objetivo en Segurarse es garantizar que obtengas una cobertura completa y precisa, por lo que la inspección vehicular es una parte importante de este proceso.</p>
                </>
            }
        ]
    },
    {
        title: "MI DOCUMENTACIÓN",
        items: [
            {
                question: "¿Qué documentación necesito para circular?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Para transitar con tu vehículo debés llevar con vos:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Licencia de conducir</li>
                        <li>Documento de identidad</li>
                        <li>Verificación técnica vehicular</li>
                        <li>Oblea de Gas (según corresponda)</li>
                        <li>Tarjeta de circulación y/o póliza vigente (si sos cliente nuestro, los encontrás en la App MiSegurarse)</li>
                    </ul>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">En el caso de circular por países del Mercosur, es necesario contar con el certificado de Mercosur. </p>
                </>
            },
            {
                question: "¿Dónde puedo descargar mi póliza?",
                answer: <p className="fs-15 fs-lg-16 lh-16 mb-20">¡Es muy fácil! Para descargar tu póliza, ingresá a la App MiSegurarse, hacé clic en "Ver más" dentro de la póliza que desees, y luego hacé clic en Descargar documentación. Recordá que es necesario tener visualizador de PDF en tu celular  para visualizarla.</p>
            },
            {
                question: "Veo errores en mi póliza ¿cómo los modifico?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Si detectas errores en la emisión de tu póliza, ingresá a la App MiSegurarse, y completá los datos correctos. Es importante adjuntar la documentación respaldatoria. Si precisas más asesoramiento contactanos a través de nuestros <a href="/contacto" className="text-decoration-underline">canales de atención</a>.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20"></p>
                </>
            },
            {
                question: "No veo la renovación de mi póliza ¿qué hago?",
                answer: <p className="fs-15 fs-lg-16 lh-16 mb-20">En la App Mi Segurarse, en la sección de Pólizas Vigente, podrás encontrar la póliza y verificar su vigencia. En el caso que esté vencida, ingresa a "Consultas" y seleccioná "Otras consultas" y solicita la actualización de tu póliza.</p>
            }
        ]
    },
    {
        title: "CÓMO CONTRATAR Y EMITIR UN SEGURO",
        items: [
            {
                question: "¿Cómo contratar un seguro?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">En Segurarse, podés contratar el seguro que estás buscando sin moverte de tu casa. Tenemos seguros <a href="/seguros-individuos" className="text-decoration-underline">para Individuos</a> y <a href="/seguros-corporativos" className="text-decoration-underline">para Empresas</a></p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Solo debes ingresar unos pocos datos del bien a asegurar para que podamos cotizarlo, y acercarte las mejores propuestas del mercado que se adapten a vos.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">También, podés <a href="/contacto" className="text-decoration-underline">comunicarte con nosotros aquí</a> y te asesoramos para que puedas tomar una decisión inteligente.</p>
                </>
            }
        ]
    },
    {
        title: "FACTURACIÓN Y ESTADO DE CUENTAS",
        items: [
            {
                question: "¿Cuáles son los medios de pago disponibles?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Contás con los siguientes medios de pago:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Tarjeta de Crédito.</li>
                    </ul>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Los medios de pago están sujetos a los términos y condiciones de la contratación de la póliza.</p>
                </>
            },
            {
                question: "¿Cómo puedo verificar si tengo deuda?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">En caso de tener algun pago rechazado, en la App MiSegurarse tu póliza va a aparecer con un mensaje de advertencia y los pasos a seguir para regularizar el pago.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">De todos modos, ¡no te procupes! Si se rechaza algun pago de tu cuenta, nos comunicaremos con vos por mail y por WhatsApp para regularizar el saldo pendiente.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Si alguna vez recibis el mensaje de falta de pago o pago rechazado, debes hacer clic en "Ir al sitio web" y allí encontrarás el paso a paso para regularizar la situación.</p>
                </>
            }
        ]
    },
    {
        title: "INSPECCIÓN DE VEHÍCULO",
        items: [
            {
                question: "¿Cómo hago la inspección de mi vehículo?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Realizar la inspección de tu vehículo es muy fácil; podes enviar las fotos solicitadas a través de la App MiSegurarse o a nuestro WhatsApp:{" "}
                        <a href="https://wa.me/5491169568002" target="_blank" rel='noreferrer' className="text-decoration-underline">+54 11 6956-8002.</a></p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Para la inspección, las fotos de tu vehículo deben seguir estas condiciones:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Tomarse el mismo día y lugar</li>
                        <li>Sacarse con formato horizontal y tener buena iluminación</li>
                        <li>Tener buena legibilidad cuándo sean documentos</li>
                        <li>Fechadas según correponda</li>
                    </ul>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Te compartimos un breve  <a href="https://www.youtube.com/watch?v=KCiUSkI1hsM" target="_blank" rel='noreferrer'
                        className="text-decoration-underline">vídeo con ejemplos</a>.</p>
                </>
            },
            {
                question: "¿Qué pasa si me piden foto de la rueda de auxilio y no tengo la rueda?",
                answer: <p className="fs-15 fs-lg-16 lh-16 mb-20">En ese caso, debes enviarnos la foto del espacio contenedor de la rueda de auxilio vacio.</p>
            },
            {
                question: "¿Cuánto tiempo tengo para hacer la inspección?",
                answer: <p className="fs-15 fs-lg-16 lh-16 mb-20">La inspección se debe realizar lo antes posible ya que la cobertura elegida iniciará una vez que la inspección sea aprobada por la compañía aseguradora.</p>
            }
        ]
    },
    {
        title: "DENUNCIAR SINIESTRO Y SOLICITAR ASISTENCIA",
        items: [
            {
                question: "¿Qué debo hacer si tuve un siniestro?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">En en caso de tener un siniestro, podés hacer la denuncia administrativa por la App MiSegurarse ingresando a la sección "Siniestros", tendrás un paso a paso que te guiará en la denuncia del mismo.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">También, podés realizar la denuncia administrativa de lunes a viernes de 9:00 a 18:00hs a través de nuestro WhatsApp <a href="https://wa.me/5491169568002" target="_blank" rel='noreferrer' className="text-decoration-underline">+54 11 6956-8002</a> o comunicándote al <a href="tel:08104447348" target="_blank" rel='noreferrer' className="text-decoration-underline">0810-444-7348</a> (opción 2).</p>
                </>
            },
            {
                question: "¿Cómo puedo ver el estado de un siniestro?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">¡Es muy fácil! En la sección "Siniestros" dentro de la App MiSegurarse podrás visualizar la información de los siniestros denunciados y el estado actual de los mismos.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">De todos modos, nuestro equipo se va a comunicar con vos para mantenerte informado de todas las novedades.</p>
                </>
            },
            {
                question: "¿Cuáles son los datos que tengo que presentar para denunciar un choque con otra persona?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-10">Para realizar la denuncia vamos a necesitar que nos indiques:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Fecha, hora y lugar del hecho</li>
                        <li>Datos de los terceros involucrados. Sus datos personales (nombre completo, teléfono y mail) y los datos del vehículo (patente, tipo de seguro y compañía aseguradora)</li>
                    </ul>
                </>
            },
            {
                question: "Me robaron las ruedas, ¿qué debo hacer?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">¡Lamentamos escuchar eso! Pero no te preocupes, te vamos a ayudar en todo el proceso de reclamo. Primero necesitamos que saques fotos de la unidad y realices la denuncia policial correspondiente.</p>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Una vez que tengas esta información, podes denunciar el siniestro desde la App MiSegurarse en la sección "Siniestros" o a través de nuestros <a href="/contacto" className="text-decoration-underline">canales de atención</a>.</p>
                </>
            },
            {
                question: "Me robaron el auto, ¿qué debo hacer?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">¡Lamentamos escuchar eso! Pero no te preocupes, te vamos a ayudar en todo el proceso de reclamo. Para poder hacerlo, una vez que hayas hecho la denuncia policial, necesitamos que nos envíes la información del siniestro a través de la App MiSegurarse en la sección "Siniestros", o a través de nuestros <a href="/contacto" className="text-decoration-underline">canales de atención</a>.</p>
                </>
            },
            {
                question: "¿Cómo realizo una denuncia por granizo?",
                answer: <p className="fs-15 fs-lg-16 lh-16 mb-20">Podés hacerlo a través de la App MiSegurarse, ingresás a la sección "Siniestros" e informás lo ocurrido completando los campos requeridos o a través de nuestros <a href="/contacto" className="text-decoration-underline">canales de atención</a>.</p>
            },
            {
                question: "¿Dónde me contacto si necesito ayuda mecánica?",
                answer: <>
                    <p className="fs-15 fs-lg-16 lh-16 mb-20">Para solicitar asistencia mecánica, podés hacerlo ingresando desde la App Mi Segurarse; hace clic en "Ver más" sobre la póliza del vehículo que necesite atención, luego hacé clic en "Pedir asistencia". En caso de no tener la App descargada, te dejamos a continuación los números de teléfono para comunciarte con tu compañía aseguradora:</p>
                    <ul className="fs-15 fs-lg-16 lh-16 mb-20">
                        <li>Allianz: 0800-888-24324/37</li>
                        <li>Sancor: 0800-222-8887</li>
                        <li>Orbis: 0800-666-0948 (autos)</li>
                        <li>Orbis: 0800-333-9111 (motos)</li>
                        <li>Provincia: 0800-333-2020</li>
                        <li>Experta: 0800-777-7278</li>
                        <li>Mapfre: 0810-666-7427</li>
                        <li>Zurich: 0800-222-1600</li>
                        <li>Galicia Seguros: 0800-999-76925</li>
                        <li>Galeno: 0800-777-5433</li>
                        <li>Mercantil: 0800-777-2634</li>
                        <li>Meridional: 0800-333-4358</li>
                        <li>Integrity: 0800-222-3700</li>
                        <li>Libra: 0800-999-6500</li>
                        <li>SMG: 0810-333-3764</li>
                    </ul>
                </>
            }
        ]
    }
];

export const PreguntasFrecuentes = () => {

    useSEOConfig(
        {
            title: "Preguntas frecuentes - Consultá nuestra sección y despejá tus dudas",
            description: "Nuestra sección de preguntas frecuentes está a disposición para que tengas toda la información que necesitás a tu alcance y no te quedes con dudas, ¡conocela!",
            keywords: "Seguros de Auto, Aseguradoras, Seguros ART, Cotizar seguro online, App mobile, Contratar seguro, Seguro para auto, Cotizador de seguros online",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Nuestra sección de preguntas frecuentes está a disposición para que tengas toda la información que necesitás a tu alcance y no te quedes con dudas, ¡conocela!",
            twitterTitle: "Segurarse - Bróker integral de Seguros digitales",
            twitterDescription: "Nuestra sección de preguntas frecuentes está a disposición para que tengas toda la información que necesitás a tu alcance y no te quedes con dudas, ¡conocela!"
        }
    )

    return (
        <>
            <NavBar rubro="auto" />
            <SegSvg/>
            <main>
                <div className='pb-50 pb-lg-80 position-relative min-vh-100'>
                    <img className="d-none d-lg-block pos02" src={shape02} alt="" />
                    <img className="d-none d-lg-block pos08" src={shape07} alt="" />

                    <div className="pt-45 pb-25 pt-lg-80 pb-lg-60 mb-16 mb-lg-m30 bg-f9">
                        <div className="container">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start mb-10">Preguntas Frecuentes</h2>
                            <p className="fs-16 fs-lg-20 text-black text-center text-lg-start mb-0">¿Cómo te podemos ayudar?</p>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='accordion accordion-faq-topic' id="accordionFaqTopics">

                            {
                                accordionData.map((data, i) => (
                                    <div key={data.title} className="accordion-item border-1 border-primary rounded-10 mb-16">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button rounded-8 collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target={`#collapse` + (i + 1)} aria-expanded="false" aria-controls={`collapse` + (i + 1)}>
                                                <svg className="svg-icon icon-faq-topic">
                                                    <use href={`#icon-faq-topic-${i + 1}`}></use>
                                                </svg>
                                                <span>{data.title}</span>
                                            </button>
                                        </h3>
                                        <div id={`collapse` + (i + 1)} className="accordion-collapse collapse" data-bs-parent="#accordionFaqTopics">
                                            <div className="accordion-body">
                                                <div className="accordion accordion-flush accordion-faq" id="">

                                                    {
                                                        data.items.map((item, i) => (
                                                            <div className="accordion-item">
                                                                <h3 className="accordion-header">
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target={`#collapse` + (i + 1) + "1"} aria-expanded="true" aria-controls={`collapse` + (i + 1) + "1"}>
                                                                        {item.question}
                                                                    </button>
                                                                </h3>
                                                                <div id={`collapse` + (i + 1) + "1"} className="accordion-collapse collapse">
                                                                    <div className="accordion-body">
                                                                        {item.answer}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="bg-f4 py-30 py-lg-50 position-relative">
                    <img className="d-none d-lg-block pos06" src={shape06} alt="" />
                    <div className="container px-lg-5">
                        <div className="row justify-content-evenly">
                            <div className="col-lg-auto text-center text-lg-start">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-lg-1">¿No resolvimos
                                    <br className="d-sm-none" /> tu consulta?</h2>
                                <p className="fw-bold fs-25 fs-lg-25 text-primary mb-18 mb-lg-0">¡Estamos para vos!</p>
                            </div>
                            <div className="col-lg-auto text-center text-lg-left">
                                <a href="/contacto" className="transition-base d-inline-block w-250p py-12 rounded-3 fs-22 fs-lg-24 fw-bold lh-1 text-center bg-primary bg-lg-gray-dark hover-bg-primary text-white">CONTACTO</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-primary pt-30 pb-35 position-relative">
                    <img className="d-none d-lg-block pos07" src={shape05} alt="" />
                    <div className="maxw-730p mx-auto px-20 px-lg-0">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center">Para cada riesgo, <br className="d-sm-none" /> tenemos una solución</h2>
                        <p className="fs-16 fs-lg-20 text-white lh-12 mb-35 text-center">Conocé nuestra amplia variedad de seguros, protegé tu inversión y anticipate a cualquier imprevisto.</p>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-evenly gap-4 gap-lg-0">
                            <a href="/seguros-individuos" className="transition-base d-inline-block w-250p py-12 rounded-3 bg-gray-dark text-white fs-22 fw-bold lh-1 text-center hover-primary hover-bg-white">INDIVIDUOS</a>
                            <a href="/seguros-corporativos" className="transition-base d-inline-block w-250p py-12 rounded-3 bg-gray-dark text-white fs-22 fw-bold lh-1 text-center hover-primary hover-bg-white">CORPORATIVOS</a>
                        </div>
                    </div>
                </div>


                <div className="bg-f4 pt-50 pb-40 py-lg-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="d-none d-lg-block col-lg-6">
                                <img src={figura03} width="351" height="308" alt="" />
                            </div>
                            <div className="col-lg-6 text-center text-lg-start">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 mb-20">¡Sumate a nuestra comunidad!</h2>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/segurarse.com.ar/" target='_blank' rel='noreferrer' title="Facebook"><svg className="svg-icon" width="34" height="34"><use xlinkHref="#icon-facebook"></use></svg></a></li>
                                    <li className="list-inline-item"><a href="https://www.instagram.com/segurarse/" target='_blank' rel='noreferrer' title="Instagram"><svg className="svg-icon" width="34" height="34"><use xlinkHref="#icon-instagram"></use></svg></a></li>
                                    <li className="list-inline-item"><a href="https://www.linkedin.com/company/segurarse/?originalSubdomain=ar" target='_blank' rel='noreferrer' title="LinkedIn"><svg className="svg-icon" width="34" height="34"><use xlinkHref="#icon-linkedin"></use></svg></a></li>
                                    <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCwiQfVHEgD5BTo5cFBH-EoA#BeneficiosSegurarse:%20Desde%20un%2020%20y%20hasta%20un%2035%20de%20bonificaci%C3%B3n&utm_term=allianzben--7--none--20-30--ENVIO%20SIMPLE" target='_blank' rel='noreferrer' title="YouTube"><svg className="svg-icon" width="34" height="34"><use xlinkHref="#icon-youtube"></use></svg></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='py-5 mb-4'>
                    <div className='container'>
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-center">Visitá nuestro blog</h2>
                        <p className="fs-16 fs-lg-20 text-center mb-35 mb-lg-45">¡full información interesante!</p>

                        <NoticiasSlider
                            items={home_novedades}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}
