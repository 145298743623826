import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import { Header as NavBar } from '../Components/Header'
import hero from "../../../Assets/img/figura-hero-01.svg";
import shape from "../../../Assets/img/shape02.svg";
import shape1 from "../../../Assets/img/shape01.svg";
import shape3 from "../../../Assets/img/shape03.svg";
import shape4 from "../../../Assets/img/shape04.svg";
import img2 from "../../../Assets/img/figura02.svg";
import img3 from "../../../Assets/img/figura01.svg";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Sustentabilidad_historias } from '../diccionario';
import { useEffect, useRef } from 'react';
import useSEOConfig from '../../../hooks/seo/useSEOConfig';

type Animation = {
    element: HTMLElement;
    animation: string;
    prefix?: string;
};

const animateCSS = (element: HTMLElement, animation: string, prefix: string = 'animate__'): Promise<string> => {
    return new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        element.classList.add(`${prefix}animated`, animationName);

        function handleAnimationEnd(event: AnimationEvent) {
            event.stopPropagation();
            element.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        element.addEventListener('animationend', handleAnimationEnd, { once: true });
    });
};

export const Sustentabilidad = () => {

    const containerRef = useRef<HTMLDivElement>(null);

    useSEOConfig(
        {
            title: "Segurarse Sustentable - Conocé nuestro compromiso en acción",
            description: "Nuestro Programa de Sustentabilidad y acciones sociales tienen el foco en contribuir a la educación en todas sus formas y al bienestar de nuestra comunidad.",
            keywords: "RSE, Sustentabilidad, Compromiso Social, Voluntariado Corporativo, Accion social, Educacion y formacion, Compromiso en acción",
            ogTitle: "Segurarse Sustentable - Conocé nuestro compromiso en acción",
            ogDescription: "Nuestro Programa de Sustentabilidad y acciones sociales tienen el foco en contribuir a la educación en todas sus formas y al bienestar de nuestra comunidad.",
            twitterTitle: "Segurarse Sustentable - Conocé nuestro compromiso en acción",
            twitterDescription: "Nuestro Programa de Sustentabilidad y acciones sociales tienen el foco en contribuir a la educación en todas sus formas y al bienestar de nuestra comunidad.",
        }
    )


    useEffect(() => {
        const options = {
            root: null,
            threshold: 0, // 0 significa que el elemento empieza a ser visible
        };

        const callback: IntersectionObserverCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const target = entry.target as HTMLElement;
                    target.classList.remove('invisible');
                    const animation = target.dataset.animate;
                    if (animation) {
                        animateCSS(target, animation);
                    }
                    observer.unobserve(target); // Dejar de observar después de animar
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        // Observar todos los elementos que tienen el atributo [data-animate]
        const targets = containerRef.current?.querySelectorAll('[data-animate]') || [];
        targets.forEach((target) => observer.observe(target));

        // Cleanup al desmontar el componente
        return () => observer.disconnect();
    }, []);

    return (
        <>
            <SegSvg />
            <NavBar rubro='auto' />
            <main className='bg-white'>
                <div className="pt-30 pb-50 px-3 py-lg-80 position-relative">
                    <img className="d-none d-lg-block pos02" src={shape} alt='' />
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start">Creemos que las empresas prosperan en sociedades que crecen.</h2>
                                <p className="fs-16 fs-lg-20 text-black text-center text-lg-start mb-50">Con esta convicción, trabajamos todos los días para profesionalizar nuestra estrategia de relaciones con la comunidad.</p>
                                <div className="d-flex align-items-center">
                                    <img src={img2} className="w-64p w-lg-90p me-20 me-lg-30" alt='' />
                                    <p className="fs-15 fs-lg-20 text-4b8b53 fw-bold mb-0">Estamos convencidos que apoyar a las nuevas generaciones en su formación y a la sociedad en su conjunto nos permite crear un futuro mejor.</p>
                                </div>
                            </div>
                            <div className="d-none d-lg-block col-lg-auto">
                                <img src={hero} width="372" height="383" className="d-none d-lg-block" alt='' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-violet py-30 pt-lg-40 pb-lg-45 position-relative">
                    <img className="pos01" src={shape1} alt='' />
                    <div className="container">
                        <p className="fs-20 fs-lg-25 text-center text-white maxw-90 mx-auto mb-0">
                            Nuestro Programa de Sustentabilidad y acciones sociales tienen el{" "}
                            <span className="fw-bold">foco en contribuir a la educación en todas sus formas y al bienestar de nuestra comunidad.
                            </span>
                        </p>
                    </div>
                </div>

                <div className="py-30 py-lg-90 bg-white">
                    <div className="container" ref={containerRef}>
                        <h2 className="mb-30 mb-lg-50 ff-mukta fs-30 fs-lg-40 fw-bold lh-11 text-black text-center">Ejes de acción que promovemos día a día</h2>

                        <div className="d-flex flex-wrap column-gap-3 column-gap-lg-40p row-gap-30p row-gap-lg-35p justify-content-center col-md-12">

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-voluntariado"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Voluntariado corporativo</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-salud"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Vida<br /> Saludable</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-calidad"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Calidad de<br /> servicio</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-transparencia"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Transparencia en la gestión del negocio</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-seleccion"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Prácticas de selección y contratación de proveedores</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-planeta"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Cuidado del planeta para futuras generaciones</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-reciclar"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Utilización<br /> eficiente de los recursos</div>
                            </div>

                            <div className="item-ejes rounded-18 shadow-1 px-1 py-4 text-center animate__delay-05s" data-animate="zoomIn">
                                <svg className="svg-icon icon-ejes mb-3"><use xlinkHref="#icon-ejes-educacion"></use></svg>
                                <div className="fs-15 fs-lg-20 lh-12 fw-bold text-black text-center">Compromiso con la educación y formación</div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="py-5 mb-4 pt-lg-2 pb-lg-90 mb-lg-0 position-relative overflow-hidden bg-white">
                    <img className="d-none d-lg-block pos03" src={shape3} alt='' />
                    <img className="d-none d-lg-block pos04" src={shape4} alt='' />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-center text-black pt-3">Nuestro compromiso en acción</h2>
                        <p className="fs-16 fs-lg-20 text-center text-black mb-30">Conocé todo lo que ya hicimos, y lo mejor... ¡todavía queda mucho más por hacer!</p>

                        <div
                        >
                            <Splide
                                options={{
                                    type: 'loop',
                                    focus: 0,
                                    drag: true,
                                    flickMaxPages: 1,
                                    flickPower: 50,
                                    perPage: 1,
                                    perMove: 1,
                                    autoplay: true,
                                    interval: 4000,
                                    arrows: false,
                                    mediaQuery: 'min',
                                    gap: '0.5rem',
                                    padding: '2rem',
                                    breakpoints: {
                                        576: {
                                            perPage: 1,
                                            padding: '0',
                                        },
                                        768: {
                                            perPage: 2,
                                            gap: '1.5rem',
                                        },
                                        992: {
                                            perPage: 3,
                                        },
                                    }
                                }}
                            >
                                {
                                    Sustentabilidad_historias.map((item, i) => (
                                        <SplideSlide key={i} className='pb-4'>
                                            <div className="card-nota">
                                                <div className="card-nota-header">
                                                    <img className="card-nota-image" src={item.img} alt='' />
                                                    <div className="position-absolute inset-0 bg-d1 blend-multipy"></div>
                                                    <h3 className="ff-mukta fs-21 fs-lg-24 fw-bold lh-11 text-white mb-0 z-0">
                                                        {item.titulo}
                                                    </h3>
                                                </div>
                                                <div className="card-nota-body">
                                                    <p className="fs-12 fs-lg-14 text-black lh-12 mb-0">
                                                        {`${item.descripcion}`}
                                                    </p>
                                                </div>
                                                <div className="card-nota-footer">
                                                    <a className="d-inline-block px-30 py-10 py-lg-12 fs-12 fs-lg-14 lh-sm fw-bold text-white bg-primary rounded-3" href={item.link} target='_blank' rel='noreferrer' tabIndex={-1}>Ver más</a>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    ))
                                }
                            </Splide>
                        </div>
                    </div>
                </div>

                <div className="bg-f4 py-30 py-lg-35">
                    <div className="container">
                        <div className="d-flex flex-column flex-lg-row align-items-center mx-auto maxw-760p">
                            <div className="mb-30 mb-lg-0 me-lg-3">
                                <img width="216" height="155" src={img3} alt='' />
                            </div>
                            <p className="mb-0 text-center text-lg-start ff-mukta fs-25 fw-bold text-black lh-12">
                                Si queres conocer más<br className="d-md-none" /> de nuestro programa,
                                <span className="d-block d-sm-inline-block fw-normal">escribinos a</span>{" "}
                                <a href="mailto:sustentabilidad@segurarse.com.ar" className="fs-20 fs-lg-25 text-black">sustentabilidad@segurarse.com.ar</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
