import React, { useEffect, useRef, useState } from 'react'
import { DatosBeneficiario } from './DatosBeneficiario'
import { IFechaNacimiento, useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SegSelect, SegSelectOption } from '../../../SegUI/SegSelect/SegSelect';
import styled from '@emotion/styled';
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer';
import { SegInput } from '../../../SegUI/SegInput/SegInput';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import { esAnioBisiesto } from '../../../../helpers/anioBisiesto';

const DivError = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;

const ButtonClose = styled.button`
    position:absolute;
    background-color: white;
    height: 24px;
    width: 24px;
    border-radius: 40%;
    right: 12px;
    top: -12px;
`;

export interface IDatosDelBeneficiario {
    nombre: string;
    apellido: string;
    fechaNac: IFechaNacimiento;
    tipoDoc: string;
    numDoc: string;
}

interface IDocumento {
    tipoDoc: string;
    numero: string;
    profesion: string;
}
const tiposDoc = [
    { value: 'dni', label: 'DNI' },
    { value: 'cuit', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' },
];

interface Props {
    beneficiario: IDatosDelBeneficiario | undefined;
    agregarBeneficiario?: any;
    setModalAbierto: any;
}

export const ModalBeneficiarios = ({ beneficiario, agregarBeneficiario, setModalAbierto }: Props) => {

    const { handleSubmit, control, setValue, formState, trigger, reset, getValues, watch } = useForm<IDatosDelBeneficiario>();
    const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
    const [errorNumero, setErrorNumero] = useState<boolean>(true);
    const closeButtonRef: any = useRef();;
    const methods = useForm<IDocumento>();
    const setMesValue = watch("fechaNac.mes");
    const setDiaValue = watch("fechaNac.dia");

    const mesFocus = useRef<HTMLInputElement | null>(null);
    const anioFocus = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        // Selecciona el offcanvas por su ID
        const offcanvasElement: any = document.getElementById('offcanvasBeneficiario');

        // Maneja el evento 'hidden.bs.offcanvas'
        const handleOffcanvasClose = () => {
            // Aquí puedes realizar cualquier acción adicional
            setModalAbierto(false);
            reset()
        };

        // Agrega el listener del evento
        offcanvasElement.addEventListener('hidden.bs.offcanvas', handleOffcanvasClose);

        // Limpia el listener cuando el componente se desmonta
        return () => {
            offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffcanvasClose);
        };
    }, []);


    useEffect(() => {
        if (beneficiario) {
            setValue("nombre", beneficiario.nombre);
            setValue("apellido", beneficiario.apellido);
            setTipoDoc(tiposDoc.find(t => t.value === beneficiario.tipoDoc));
            setValue("numDoc", beneficiario.numDoc);
            setValue("fechaNac.dia", beneficiario.fechaNac.dia);
            setValue("fechaNac.mes", beneficiario.fechaNac.mes);
            setValue("fechaNac.anio", beneficiario.fechaNac.anio);
            trigger()
        } else {
            reset()
        }
    }, [beneficiario])

    useEffect(() => {
        if (setDiaValue && setDiaValue.length === 2 && mesFocus.current) {
            mesFocus.current.focus();
        }
    }, [setDiaValue]);

    useEffect(() => {
        if (setMesValue && setMesValue.length === 2 && anioFocus.current) {
            anioFocus.current.focus();
        }
    }, [setMesValue]);

    const onSubmit: SubmitHandler<IDatosDelBeneficiario> = (values: IDatosDelBeneficiario) => {
        const { nombre, apellido, numDoc, fechaNac } = values;
        agregarBeneficiario({
            nombre,
            apellido,
            numDoc,
            tipoDoc: tipoDoc?.value || "",
            fechaNac
        });

        if (closeButtonRef.current) {
            reset()
            closeButtonRef.current.click();
        }
    }

    return (
        <>
            <div className="offcanvas offcanvas-bottom"
                tabIndex={-1} id="offcanvasBeneficiario" aria-labelledby="offcanvasBottomLabel"
                style={{
                    minHeight: window.innerWidth > 778 ? "80vh" : "90vh",
                    backgroundColor: window.innerWidth > 778 ? "transparent" : "",
                    borderTop: window.innerWidth > 778 ? "0 solid white" : ""
                }} >
                <div className="offcanvas-body ">
                    {
                        window.innerWidth < 778 &&
                        <div style={{ position: "relative", display: "flex", justifyContent: "end" }}>
                            <button ref={closeButtonRef} type="button"
                                className="btn-close text-reset p-2 bg-white"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            //style={{ position: "absolute",}}
                            ></button>
                        </div>
                    }
                    <div className='px-20 pb-40 pb-lg-60 maxw-1170p mx-auto' style={{ position: "relative", marginTop: window.innerWidth < 778 ? "-16px" : "" }}>
                        {
                            window.innerWidth > 778 &&
                            <ButtonClose ref={closeButtonRef} type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></ButtonClose>
                        }
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <SegFormEmisionContainer title='Datos del beneficiario' 
                            tooltip='Persona que recibirá los beneficios o pagos en caso de siniestro o fallecimiento del asegurado.'>
                                <div className="col-md-6">
                                    <Controller
                                        name="nombre"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) => !!value || "Ingresá el nombre",
                                                pattern: (value) =>
                                                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                    "Nombre inválido",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="Nombre"
                                                name="nombre"
                                                type="text"
                                                width={100}
                                                placeHolder="Ej: Juan"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("nombre", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("nombre");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.nombre
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.nombre
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Controller
                                        name="apellido"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) => !!value || "Ingresá el apellido",
                                                pattern: (value) =>
                                                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                    "Apellido inválido",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="Apellido"
                                                name="apellido"
                                                type="text"
                                                width={100}
                                                placeHolder="Ej: López"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("apellido", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("apellido");
                                                }}
                                                value={field.value}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.apellido
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.apellido
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Tipo documento</label>
                                    <FormProvider {...methods}>
                                        <SegSelect
                                            label=""
                                            selected={tipoDoc}
                                            setSelected={setTipoDoc}
                                            name="tipoDoc"
                                            options={tiposDoc}
                                            isGrupable
                                            width={"100%"}
                                            openDefault={false}
                                            placeholder=""
                                        />
                                    </FormProvider>
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Número de documento</label>
                                    <Controller
                                        name="numDoc"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if (!value) {
                                                        setErrorNumero(true)
                                                        return "Ingresá el Número"
                                                    } else {
                                                        setErrorNumero(false)
                                                    }
                                                },
                                                pattern: (value) => {
                                                    if (tipoDoc?.value === 'dni') {
                                                        if (/^\d{7,8}$/.test(value)) {
                                                            setErrorNumero(false)
                                                        } else {
                                                            setErrorNumero(true)
                                                            return "DNI inválido"
                                                        }
                                                    } else if (tipoDoc?.value === 'cuit' || tipoDoc?.value === 'cuil') {
                                                        if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                            setErrorNumero(false)
                                                        } else {
                                                            setErrorNumero(true)
                                                            return `${tipoDoc?.label} inválido`
                                                        }
                                                    }
                                                }
                                            },
                                        }}
                                        render={({ field, fieldState }) => (

                                            <SegInput
                                                disabled={!tipoDoc}
                                                className="col-8"
                                                label=""
                                                name="setNumero"
                                                type="number"
                                                width={100}
                                                placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("numDoc", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("numDoc");
                                                }}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors?.numDoc
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.numDoc
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Fecha de nacimiento</label>
                                    <div className='d-flex align-items-end gap-1 justify-content-between'>
                                        <div className='col-md-4'>
                                            <Controller
                                                name="fechaNac.dia"
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        customValidation: (value) => {
                                                            const day = parseInt(value, 10);
                                                            const month = parseInt(getValues("fechaNac.mes"), 10);
                                                            const year = parseInt(getValues("fechaNac.anio"), 10);

                                                            if (!value) return "Día debe incluir 2 dígitos";
                                                            if (isNaN(day) || day <= 0) return "Día no puede ser menor o igual a 0";
                                                            if (day > 31) return "Día no puede ser mayor a 31";
                                                            if (month === 2 && day > 29) return "Febrero no puede tener más de 29 días";
                                                            if (month === 2 && day === 29 && !esAnioBisiesto(year)) {
                                                                return "El año no es bisiesto, febrero tiene máximo 28 días";
                                                            }
                                                            return true;
                                                        },
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <SegInput
                                                        label=""
                                                        name="Dia"
                                                        type="number"
                                                        width={100}
                                                        placeHolder="Día"
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                            field.onChange(trimmedValue);
                                                            setValue("fechaNac.dia", trimmedValue);
                                                            trigger("fechaNac.dia");
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <Controller
                                                name="fechaNac.mes"
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        customValidation: (value) => {
                                                            if (!value) {
                                                                return "Mes debe incluir 2 dígitos";
                                                            }
                                                            const parsedValue = parseInt(value, 10);
                                                            if (isNaN(parsedValue) || parsedValue > 12) {
                                                                return "Mes no puede ser mayor a 12";
                                                            }
                                                            if (isNaN(parsedValue) || parsedValue <= 0) {
                                                                return "Día no puede ser 0";
                                                            }
                                                            const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                            if (isSingleDigitWithoutZero) {
                                                                return "Mes debe incluir 2 dígitos";
                                                            }
                                                            return true;
                                                        },
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <SegInput
                                                        ref={mesFocus}
                                                        label=""
                                                        name="Mes"
                                                        type="number"
                                                        width={100}
                                                        placeHolder="Mes"
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                            field.onChange(trimmedValue);
                                                            setValue("fechaNac.mes", trimmedValue);
                                                            trigger("fechaNac.mes");
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <Controller
                                                name="fechaNac.anio"
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        required: (value) =>
                                                            !!value || "Ingresá el año de nacimiento",
                                                        pattern: (value) =>
                                                            /^(19\d{2}|20\d{2})$/.test(value) || "Año debe incluir 4 dígitos",
                                                        ageValidation: (value) => {
                                                            const year = parseInt(value, 10);
                                                            const currentYear = new Date().getFullYear();
                                                            const age = currentYear - year;
                                                            if (age < 18) return "Edad mínima es de 18 años";
                                                            if (age > 65) return "Edad máxima es de 65 años";
                                                            return true;
                                                        },
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <SegInput
                                                        ref={anioFocus}
                                                        label=""
                                                        name="Anio"
                                                        type="number"
                                                        placeHolder="Año"
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                                                            field.onChange(trimmedValue);
                                                            setValue("fechaNac.anio", trimmedValue);
                                                            trigger("fechaNac.anio");
                                                        }}
                                                        width={100}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {formState.errors.fechaNac &&
                                        <DivError>
                                            {[formState.errors.fechaNac.dia?.message, formState.errors.fechaNac.mes?.message, formState.errors.fechaNac.anio?.message]
                                                .filter(Boolean) // Filtramos valores nulos o indefinidos
                                                .join(', ')}
                                        </DivError>
                                    }

                                </div>
                                <div className={"col-12"}>
                                    <SegButton label='Agregar' type='submit' disabled={!formState.isValid} className={window.innerWidth < 768 ? 'w-100' : 'fs-15'} />
                                </div>
                            </SegFormEmisionContainer>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
