import React, { useEffect, useState } from 'react';
import './App.css';
import { AppRouter } from './Routers/AppRouter/AppRouter';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './Assets/css/styles1.css';
import './Assets/css/precios.css';
import './Assets/css/styles3.css';
import styled from '@emotion/styled';
import segurarseWhite from '../src/Assets/img/logo_segurarse_blanco.svg'
import { SegFooter } from './Components/SegUI/SegFooter/SegFooter';
import { useLocation } from 'react-router-dom';
import LandingFooter from './Pages/Landings/Components/LandingFooter'
import useSEOConfig from './hooks/seo/useSEOConfig';

const PageContainer = styled.div`
    // overflow: scroll;
    height: 200px;

    /* Para navegadores basados en WebKit (Chrome, Safari, Opera) */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Para Firefox */
    scrollbar-width: none;

    /* Para Internet Explorer y Edge */
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
`;

const Content = styled.div`
    /* padding-bottom: 24px; */
    /* margin-bottom: 12px; */
`;


function App() {
  const location = useLocation();
  useEffect(() => {
    // Obtén la clave de reCAPTCHA desde las variables de entorno
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

    // Carga dinámicamente el script de reCAPTCHA
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, []);

  const landingFooterPaths = [
    "/sustentabilidad",
    "/contacto",
    "/preguntas-frecuentes",
    "/politicas-privacidad",
    "/formulario-arrepentimiento",
    "/quienes-somos",
    "/nuestro-oneteam",
  ];

  const isLandingFooter = landingFooterPaths.includes(location.pathname) || location.pathname === "/" || location.pathname.includes("/seguros-individuos") || location.pathname.includes("/seguros-corporativos");

  useSEOConfig(
    {
      author: "Segurarse",
      canonicalUrl: "https://www.segurarse.com.ar",
      ogSiteName: "Segurarse",
      twitterCard: "summary_large_image",
      ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
      twitterImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
      twitterSite: "@Segurarse",
    }
  )

  return (
    <PageContainer>
      <Content>
        <AppRouter />
      </Content>

      {isLandingFooter ? (
        <LandingFooter />
      ) : (
        <SegFooter
          img={segurarseWhite}
          text='Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste, Buenos Aires, Argentina.'
            linkAdicional="https://segurarse.com.ar/politicas-privacidad"
          textAdicional="Políticas de Privacidad"
        />
      )}

    </PageContainer>
  );
}

export default App;
