import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';


export interface EmpresaProps {
    origen: string;
    images: {
        logo: string;
        favicon?: string;
    },
    colors: {
        primary: string;
        secondary: string;
        tertiary: string;
        accent: string;
        icons: string;
        navbar: string;
        segu_stepper: string;
    },
    config: {
        cookies: boolean;
    }
}

interface ConfigStore {
    config: EmpresaProps;
    origen: string;
    setConfig: (value: EmpresaProps, origen: string) => void;
    resetConfig: () => void;
}

const initialConfig: EmpresaProps = {
    images: {
        logo: "",
    },
    colors: {
        accent: "",
        primary: "",
        secondary: "",
        tertiary: "",
        icons: "",
        navbar: "",
        segu_stepper: ""
    },
    config: {
        cookies: true,
    },
    origen: "SEGURARSE",
}

export const useConfiguracionEmpresa = create<ConfigStore>()(
    persist(
        (set) => ({
            config: initialConfig,
            origen: "SEGURARSE",
            setConfig: (values: EmpresaProps, origen: string) => set(
                {
                    config: {
                        colors: values.colors,
                        images: values.images,
                        config: values.config,
                        origen: values.origen,
                    },
                    origen
                }),
            resetConfig: () => set({ config: initialConfig, origen: "" }),
        }),
        {
            name: "config-empresa",
            storage: createJSONStorage(() => sessionStorage),
        }
    ));