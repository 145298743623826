import React from 'react'

export const PasosCotizar = () => {
    return (
        <div className="py-5 bg-f4">
            <div className="container">
                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-5">
                    ¿Cómo contratar?
                </h2>

                <div className="position-relative bg-line-lg">
                    <div className="row px-5 mb-50 position-relative">
                        {[
                            {
                                step: '1',
                                text: 'Compará precios y coberturas',
                            },
                            {
                                step: '2',
                                text: 'Elegí la forma de pago',
                            },
                            {
                                step: '3',
                                text: 'Emití online tu póliza',
                            },
                            {
                                step: '4',
                                text: '¡Listo! Ya estás asegurado',
                            },
                        ].map(({ step, text }, index) => (
                            <div key={index} className="col-lg-3 text-center">
                                <div className="d-inline-flex justify-content-center align-items-center fs-30 fs-lg-50 fw-bold text-white bg-primary rounded-circle w-64p h-64p w-lg-105p h-lg-105p mb-10 mb-lg-20 border border-2 border-white shadow-3">
                                    {step}
                                </div>
                                <div className="fs-20 fs-lg-24 fw-bold lh-12 text-black mb-30">{text}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="col-10 col-md-8 col-xl-6 mx-auto text-center">
                    <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center">
                        Si tenés dudas, contás con nuestro equipo de especialistas para asesorarte en todo momento.
                    </p>
                    <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                        <span className="fs-18 fs-lg-20 text-black fw-bold">Chatéanos</span>
                        <svg className="svg-icon ms-2 w-12em">
                            <use xlinkHref="#whatsapp" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    )
}
