import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import Asesoramiento from '../Components/Asesoramiento'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes';
import FormularioIndividuo from '../Components/FormularioIndividuo';
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const Embarcaciones = () => {

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    const preguntasFrecuentes = [
        {
            titulo: '¿El seguro de embarcación cubre el robo de objetos dentro de la embarcación?',
            subTitulo: 'Algunos seguros de embarcación cubren el robo de objetos específicos dentro de la embarcación, como equipos de navegación, motores fuera de borda, y dispositivos electrónicos. Sin embargo, esta cobertura puede estar sujeta a ciertos límites y exclusiones. Es importante revisar las condiciones de la póliza para saber qué está incluido.'
        },
        {
            titulo: '¿Puedo usar mi embarcación para actividades comerciales bajo el seguro estándar?',
            subTitulo: 'Generalmente, los seguros estándar de embarcación están diseñados para uso recreativo. Si planeás utilizar tu embarcación con fines comerciales (como pesca comercial, alquiler, o transporte de pasajeros), necesitás contratar una póliza específica para actividades comerciales. Es fundamental informar a la aseguradora sobre el uso previsto de la embarcación.'
        },
        {
            titulo: '¿El seguro de embarcación cubre la participación en competiciones o regatas?',
            subTitulo: 'No todas las pólizas estándar cubren la participación en regatas o competiciones náuticas. Si planeás participar en eventos de este tipo, es importante contratar una cobertura adicional específica para regatas, que incluya protección para los riesgos asociados con la competición.'
        },
        {
            titulo: '¿Es posible cancelar el seguro de embarcación en cualquier momento?',
            subTitulo: 'Sí, podés cancelar el seguro de embarcación en cualquier momento, pero es importante revisar las condiciones de cancelación establecidas en tu contrato. Algunas aseguradoras pueden requerir un aviso previo o aplicar una penalización por cancelación anticipada.'
        },
    ];

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Embarcación'
                    subTitulo={{ __html: ' Cotizá y navegá protegido. El placer de preocuparte sólo por disfrutar. Te asesoramos en todo momento para que hagas #match con la cobertura que se adapta a vos.<br/><br/>¿Empezamos?' }}

                    url='#formulario'
                    icon='#icon-seg-embarcaciones'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                            Un seguro de embarcación es una póliza diseñada para proteger a los propietarios de embarcaciones contra pérdidas o daños que puedan ocurrir tanto en el agua como en tierra.
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños físicos a la embarcación</b>: cubre los daños materiales que pueda sufrir la embarcación debido a accidentes, colisiones, hundimiento, incendio, explosión, vandalismo, tormentas, encallamiento, o eventos similares.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil</b>: protege al propietario de la embarcación en caso de daños materiales o lesiones corporales causadas a terceros como resultado de un accidente en el que esté involucrada la embarcación asegurada. Esta cobertura incluye los costos de defensa legal, indemnizaciones, y otros gastos relacionados con reclamaciones de terceros.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Robo y hurto</b>: cubre el robo o hurto de la embarcación completa o de partes específicas de la misma, como motores, equipos de navegación, remos, o cualquier otro equipo que forme parte de la embarcación asegurada. Esta cobertura suele tener ciertas limitaciones y puede requerir que se tomen medidas de seguridad, como sistemas de bloqueo o almacenamiento en lugares seguros.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Pérdida total</b>: indemniza al propietario de la embarcación en caso de pérdida total, ya sea por naufragio, destrucción irreparable, robo, o cualquier otro evento cubierto por la póliza que haga que la embarcación sea irrecuperable. La compensación suele ser equivalente al valor asegurado de la embarcación al momento de la contratación del seguro.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Daños por fenómenos naturales</b>: Cubre los daños causados a la embarcación por fenómenos naturales como tormentas, granizo, rayos, huracanes, inundaciones, y otros desastres naturales. Esta cobertura puede incluir tanto la reparación de la embarcación como el reemplazo de equipos dañados.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FormularioIndividuo
                    rubro='Embarcacion'
                    titulo='Cotizá seguro de Embarcación'
                />
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
                <Asesoramiento />
            </div>
        </>
    )
}
