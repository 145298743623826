import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useEffect, useRef } from 'react';

interface Props {
    testimonios: {
        texto: string;
        nombre: string;
        calificacion: number;
    }[]
}

interface PaginationData {
    items: {
        li: HTMLElement;
        button: HTMLElement;
        page: number;
    }[];
    list: HTMLElement;
}

export const TestimoniosSlider = ({ testimonios }: Props) => {
    // Especificamos el tipo correcto para la referencia
    const splideRef = useRef<Splide>(null);

    useEffect(() => {
        if (splideRef.current) {
            const splide = splideRef.current.splide;

            const handlePagination = (data: PaginationData) => {
                const isMobile = window.innerWidth < 576;
                if (isMobile) {
                    const maxDots = 5;
                    data.items.forEach((item, index: number) => {
                        if (index >= maxDots) {
                            item.li.style.display = 'none';
                        }
                    });
                }
            };

            splide?.on('pagination:mounted', handlePagination);

            const handleResize = () => {
                splide?.refresh();
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);


    return (
        <div className="container slide-comentarios">
            <Splide
                ref={splideRef}
                options={{
                    type: 'loop',
                    focus: 0,
                    drag: true,
                    flickMaxPages: 1,
                    flickPower: 50,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    interval: 4000,
                    arrows: false,
                    mediaQuery: 'min',
                    gap: '0.5rem',
                    padding: '2rem',
                    breakpoints: {
                        576: {
                            perPage: 1,
                            padding: '0',
                            pagination: true,
                            focus: "center",
                            omitEnd: true
                        },
                        768: {
                            perPage: 2,
                            gap: '1.5rem',
                        },
                        992: {
                            perPage: 3,
                        },
                    }
                }}
            >
                {testimonios.map((testimonio, index) => (
                    <SplideSlide key={"testimonio-" + index}>
                        <div className="comms-wrapper">
                            <p className="fs-14 fs-lg-18 lh-sm mb-2"><i>{testimonio.texto}</i></p>
                            <div>
                                <span className="fs-14 fs-lg-14">{testimonio.nombre}</span>
                                <div>
                                    {Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                                        <svg
                                            key={`testimonio_${index}`}
                                            className={`svg-icon icon-star ${item <= testimonio.calificacion && "icon-star-on"}`}
                                        >
                                            <use xlinkHref="#icon-rating-star"></use>
                                        </svg>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
};