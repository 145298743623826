import { create } from 'zustand';
import { createJSONStorage, persist } from "zustand/middleware";
import { SegSelectOption } from '../../Components/SegUI/SegSelect/SegSelect';
import { CotizacionDesc } from '../../Pages/Auto/Precios';
import { CotizacionDescPrepaga } from '../../Pages/Prepaga/Individuo/Precios';

export interface IFechaNacimiento {
    dia: string;
    mes: string;
    anio: string;
}

export interface ITelefono {
    cod: string;
    numero: string;
}

export interface IVencimiento {
    mes: string;
    anio: string;
}

export interface CertificadoNoRodamiento {
    archivo: File;
    extension: string;
    nombre: string;
    dataBase64: string;
}


export interface IEmisionValues {
    id?: number;
    cotizacionDesc?: CotizacionDesc;
    CotizacionDescPrepaga?: CotizacionDescPrepaga;
    cotizacionDescAP?: any;
    tipoPersona?: SegSelectOption;
    condicionIva?: SegSelectOption;
    tipoUso?: SegSelectOption;
    gnc?: boolean;
    nombre?: string;
    apellido?: string;
    email?: string;
    tipoDoc?: string;
    numDoc?: string;
    cuil?: string;
    fechaNac?: IFechaNacimiento;
    celular?: ITelefono;
    telAdicional?: ITelefono;
    localidad?: string;
    calle?: string;
    altura?: string;
    piso?: string;
    departamento?: string;
    patente?: string;
    numMotor?: string;
    numChasis?: string;
    numeroTarjeta?: string;
    titularTarjeta?: string;
    banco?: SegSelectOption;
    tipoTarjeta?: SegSelectOption;
    vencimientoTarjeta?: IVencimiento;
    cvc?: string;
    certificadoNoRodamiento?: CertificadoNoRodamiento | null;
    calleSinNum?: boolean;
    accidentesPersonales?: AccidentesPersonales;
    idPoliza?: number;
}

interface AccidentesPersonales {
    clausulaNoRepeticion?: boolean;
    asegurados?: AseguradoAP[];
    beneficiario?: {
        nombre: string;
        apellido: string;
        tipoDoc: string;
        numDoc: string;
        fechaNac: IFechaNacimiento;
    }
}

export interface AseguradoAP {
    nombre: string;
    apellido: string;
    profesion: SegSelectOption;
    tipoDoc: string;
    numDoc: string;
    genero: SegSelectOption;
    fechaNac: IFechaNacimiento;
    beneficiario?: {
        nombre: string;
        apellido: string;
        tipoDoc: string;
        numDoc: string;
        fechaNac: IFechaNacimiento;
    }
}
interface EmisionStore {
    emisionValues: IEmisionValues;
    setEmisionValues: (values: IEmisionValues) => void;
    resetEmisionValues: () => void;

}

export const useEmisionStore = create<EmisionStore>()(
    persist(
        (set) => ({
            emisionValues: {},
            setEmisionValues: (values: IEmisionValues) => set({ emisionValues: { ...values } }),
            resetEmisionValues: () => set({ emisionValues: {} }),
        })
        , {
            name: "emision",
            storage: createJSONStorage(() => sessionStorage),
        }))