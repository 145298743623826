import React from 'react'
import { SegSvg } from '../../Components/SegUI/SegSvg/SegSvg'
import styled from '@emotion/styled'
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo'
import logoblue from "../../Assets/img/logo_segurarse.svg"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore'
import { telefonos } from '../../Assets/telefonosCompanias';

const StyledDiv = styled.div`
height: 63vh;
@media (max-width: 458px) {
    height: 85vh;
}
@media (max-width: 278px) {

    height: 90vh;
}
`
interface GraciasProps {
    nombre?: string;
    apellido?: string;
    dni?: string;
    telefono?: string;
    email?: string;
    tipoDoc?: string;
}


export const GraciasAccidentesPersonales = ({ nombre, apellido, dni, telefono, email, tipoDoc }: GraciasProps) => {

    const nav = useNavigate();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
    const { compania } = useParams();

    nombre = nombre ? nombre : cotizacionValues.nombre;
    apellido = apellido ? apellido : cotizacionValues.apellido;
    telefono = telefono ? telefono : (
        (cotizacionValues.telefono?.prefijo || cotizacionValues.telefono?.numero)
            ? `${cotizacionValues.telefono?.prefijo ?? ''}${cotizacionValues.telefono?.numero ?? ''}`
            : undefined
    );
    dni = dni ? dni : cotizacionValues.numeroDoc;
    email = email ? email : cotizacionValues.email;
    tipoDoc = tipoDoc ? tipoDoc : cotizacionValues.tipoDoc?.value;
    return (
        <div>
            <SegSvg />
            <div className=''>
                <div className="container d-flex align-items-center justify-content-center" style={{ height: "7vh" }}>
                    <div className="row">
                        <div className=""> {/* Centra el contenido en la columna */}
                            <SegHeaderLogo img={logoblue} alt="Logo Segurarse" width="160px" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary text-center pt-5" style={{ height: window.innerWidth > 778 ? "25vh" : "35vh" }}>
                <svg className="" style={{ width: "90px" }}>
                    <use href="#ico-hombre" fill='white' />
                </svg>
            </div>
            <StyledDiv className='bg-primary text-center d-flex flex-column'>
                <h1 className='fs-2 text-white'>¡Recibimos tu solicitud!</h1>
                <p className='mt-3 text-white fs-5'>
                    {`En breve nos comunicaremos para asesorarte en la mejor cobertura para ${tipoDoc === "CUIT" ? "tu empresa." : "vos."}`}
                    </p>
                <div className='mb-5 mt-4 ps-3 pe-3'>
                    <div className='d-flex flex-column bg-white rounded container'>
                        <div className='d-flex flex-column flex-grow-2 justify-content-center m-3 gap-2'>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4 pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#card-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Nombre y Apellido:</span>
                                        <span className='fw-bold'>{nombre + " " + apellido}</span>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#tel-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Telefono:</span>
                                        <span className='fw-bold'>{telefono}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                                <use xlinkHref='#people-lin' fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Documento:</span>
                                        <span>fff{ tipoDoc}</span>
                                        <span className='fw-bold '>{dni}</span>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#envelope-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Email:</span>
                                        <span className='fw-bold text-truncate'>{email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="https://api.whatsapp.com/send?phone=5491169568002&text=%C2%A1Hola!%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Prepaga%20M%C3%A9dica" target='_blank' rel='noreferrer' className='text-black mt-4' >
                            <div className='d-flex justify-content-center gap-2 align-items-start'>
                                <div>
                                    <p className='fw-bold'>¿Querés continuar por WhatsApp? <span className='fw-normal'>Chatéanos</span></p>
                                </div>
                                <div>
                                    <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                        <use xlinkHref="#whatsapp" />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </StyledDiv>
        </div>
    )
}
