import imageMedios from "../../Assets/imageMedios"

const { ambitoLogo, clarinLogo, noticiasargentinasLogo, forbesLogo, motor1Logo, cronistaLogo, diariocarlospazLogo, bariloche2000Logo, eleconomistaLogo } = imageMedios

export const informacion_contacto = {
    fijo: "0810-444-SEGURO (7348)",
    whatsApp: "+54 9 11 6956 8002",
    horarios: {
        ventas: "lunes a viernes de 08:30 a 20:30hs.",
        atencion_cliente: "lunes a viernes de 09:00 a 18:00hs."
    }
}
export const testimonios_clientes = [
    {
        nombre: "Max G.",
        texto: "Excelente atención por parte de sus asistentes. Empatía- cordialidad- respeto. Lo recomiendo 100%.",
        calificacion: 5
    },
    {
        nombre: "Luis",
        texto: "La atención telefónica es más que excelente. Siempre muy bien dispuestos.",
        calificacion: 5
    },
    {
        nombre: "Christian L.",
        texto: "Atención muy rápida , amable y eficiente.",
        calificacion: 5
    },
    {
        nombre: "Ariana A.",
        texto: "Buena atención y rápida respuesta, super recomendado.",
        calificacion: 5
    },
    {
        nombre: "Dana F.",
        texto: "La atención telefónica ha sido buena y amable solucionando mí asunto.",
        calificacion: 4
    },
    {
        nombre: "Carlos M.",
        texto: "Excelente atención, cada vez que me contacté fueron muy operativos.",
        calificacion: 5
    },
    {
        nombre: "Luciano P.",
        texto: "Muy buena atención. Me resolvió rápido lo solicitado. Gracias!!",
        calificacion: 5
    },
    {
        nombre: "Marcela C.",
        texto: "Excelente atención muy rápido me solucionaron el problema la póliza llego por pdf en 5 minutos.",
        calificacion: 4
    },
    {
        nombre: "Martin A.",
        texto: "Estoy muy conforme, con la atención sobretodo, es rápida y simple. Muy recomendable.",
        calificacion: 5
    },
    {
        nombre: "Gladis M.",
        texto: "El servicio de WhatsApp que ofrecen es práctico y resuelven rápido las inquietudes.",
        calificacion: 4
    }
];

 /*
    {
        nombre: "Valentino R.",
        texto: "Un sólo lugar con muchísimas opciones de compañía aseguradora y tipos de cobertura. Atienden diligentemente y satisfactoriamente.",
        calificacion: 5
    },
    {
        nombre: "Adolfo G.", 
        texto: "Una excelente atención, no solo me solucionaron un tema sino que además me consiguieron gestionar un arreglo en mí póliza muy beneficioso...200% recomendables.",
        calificacion: 5
    },
    {
        nombre: "José L.",
        texto: "Los mejores... Excelente el trato y la solución ante cualquier inconveniente. excelente su disponibilidad.",
        calificacion: 5
    },
    {
        nombre: "Sebastián N.",
        texto: "Un lujo la atención y gracias a todos los que me atendieron a lo largo de todo este tiempo, y como siempre me solucionan o me explican.",
        calificacion: 5
    },
    {
        nombre: "Benhard B.",
        texto: "Hace años que contrato seguros con ellos, nunca un problema, muy buena su gestión.",
        calificacion: 5
    },
    {
        nombre: "Mónica A.",
        texto: "Hace más de 15 años que la uso y siempre solucionaron mis problemas.",
        calificacion: 5
    },
    {
        nombre: "Lara T.",
        texto: "Excelente tanto el seguro, como el rápido accionar en accidentes y los operadores muy amables.",
        calificacion: 5
    },
    {
        nombre: "Sebas A.",
        texto: "Muy buena la atención. Siempre respondiendo las consultas tanto por mail como telefónicamente.",
        calificacion: 5
    },
    {
        nombre: "Carlitos P.",
        texto: "Varios medios de comunicación disponibles para agilizar el intercambio de documentación.",
        calificacion: 5
    },
    {
        nombre: "Hebe B.",
        texto: "Excelente atención, resolvieron lo más rápido que pudieron un problema administrativo.",
        calificacion: 5
    }
 */

//---------------------------------------------


//Landing Pymes -------------------------------

export const pymes_propuesta_valor = [
    {
        titulo: "Respuesta inmediata",
        descripcion: "Soluciones corporativas a medida y con el respaldo de aseguradoras de primer nivel.",
        icono: "#icon-motivo-rapido"
    },
    {
        titulo: "Gestión de siniestros",
        descripcion: "Gestiones legales ante las aseguradoras para agilizar el pago de siniestros propios y de terceros.",
        icono: "#icon-motivo-g-siniestros"
    },
    {
        titulo: "Experiencia y profesionalismo",
        descripcion: "Contamos con un equipo de ejecutivos exclusivos por riesgo y mercado.",
        icono: "#icon-motivo-experiencia"
    },
    {
        titulo: "Gestiones comerciales",
        descripcion: "Asesoramiento y seguimiento de trámites, actualizaciones de sumas aseguradas, renovaciones y cobranzas.",
        icono: "#icon-motivo-g-comercial"
    },
]


export const pymes_coberturas = [
    {
        icon: "#icon-seg-art",
        label: "ART",
        link: "/seguros-corporativos-ART"
    },
    {
        icon: "#icon-seg-comercio",
        label: "Integral de Comercio",
        link: "/seguros-corporativos-integral-comercio"
    },
    {
        icon: "#icon-seg-caucion",
        label: "Caución",
        link: "/seguros-corporativos-caucion"
    },
    {
        icon: "#icon-seg-rc",
        label: "RC",
        link: "/seguros-corporativos-RC"
    },
    {
        icon: "#icon-seg-flota",
        label: "Flota de Vehículos",
        link: "/seguros-corporativos-flota"
    },
    {
        icon: "#icon-seg-incendio-corp",
        label: "Incendio Corporativo",
        link: "/seguros-corporativos-incendio"
    },
    {
        icon: "#icon-seg-mala-praxis",
        label: "Mala Praxis",
        link: "/seguros-corporativos-mala-praxis"
    },
    {
        icon: "#icon-seg-rc-medioambiental",
        label: "RC Medioambiental",
        link: "/seguros-corporativos-RC-medioambiental"
    },
    {
        icon: "#icon-seg-tr-corp",
        label: "Todo Riesgo Corporativo",
        link: "/seguros-corporativos-todo-riesgo"
    },
    {
        icon: "#icon-seg-construccion",
        label: "Construcción",
        link: "/seguros-corporativos-construccion"
    },
    {
        icon: "#icon-seg-transporte",
        label: "Transporte",
        link: "/seguros-corporativos-transporte"
    },
    {
        icon: "#icon-seg-consorcio",
        label: "Consorcio",
        link: "/seguros-corporativos-consorcio"
    },
    {
        icon: "#icon-seg-credito",
        label: "Crédito",
        link: "/seguros-corporativos-credito"
    },
    {
        icon: "#icon-seg-agro",
        label: "Agro",
        link: "/seguros-corporativos-agro"
    },
    {
        icon: "#icon-seg-vida-corp",
        label: "Vida Corporativa",
        link: "/seguros-corporativos-vida"
    }
]
//----------------------------------------------

//Landing Sustentabilidad --------------------------------

export const Sustentabilidad_historias = [
    {
        titulo: "Nuevo uso para equipos de Segurarse",
        descripcion: "¿Ya escucharon hablar de la tecnología circular? ♻️ cada vez más, como sociedad, nos concientizamos y nos involucramos en el impacto ambiental de todas nuestras acciones🌏🌏 ",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7103069100297363457/",
        img: require("../../Assets/img/landing/sustentabilidad/Donacion_Escuela_PCs.jpeg")
    },
    {
        titulo: "¡Vamos a jugar!",
        descripcion: "Compartimos una tarde de juegos con los chic@s que asisten al Virreyes Rugby Club semanalmente para realizar diversas actividades deportivas, clases de baile, cursos de formación educativa, ¡y mucho más!",
        link: "https://www.linkedin.com/posts/segurarse_programa-sustentabilidad-daedadelaniaehez-activity-6967552730512961536-0cjK?utm_source=share&utm_medium=member_desktop",
        img: require("../../Assets/img/landing/sustentabilidad/Club_Virreyes.JPG")
    },
    {
        titulo: "Donaciones en Casa Garraham",
        descripcion: "Agradecemos a Fundación Garrahan que nos abrió las puertas de la Casa Garraham para acercar juguetes y ropa para los niñ@s y sus familias que residen en ella.",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:6834181013044789248/",
        img: require("../../Assets/img/landing/sustentabilidad/Garrahan.jpeg")
    },
    // {
    //     titulo: "Segunda oportunidad para las computadoras de Segurarse",
    //     descripcion: "¿Ya escucharon hablar de la tecnología circular? ♻️ cada vez más, como sociedad, nos concientizamos y nos involucramos en el impacto ambiental de todas nuestras acciones🌏🌏",
    //     link: "https://www.linkedin.com/feed/update/urn:li:activity:7103069100297363457/",
    //     img: require("../../Assets/img/landing/sustentabilidad/Escuela_11_Ricardo_Gutierrez.jpeg")
    // }
]

//----------------------------------------------


//Landing Home --------------------------------

export const home_novedades = [
    {
        titulo: "¿Qué me pueden exigir (y qué no) durante un control de tránsito en la ruta?",
        descripcion: "Si pensás salir de vacaciones en tu auto durante este verano, es importante que cumplas todos los requisitos legales y técnicos para viajar seguro.",
        link: "https://segurarse.com.ar/blog/2023/01/23/que-me-pueden-exigir-y-que-no-durante-un-control-de-transito-en-la-ruta/"
    },
    {
        titulo: "Puntos de incapacidad en la ART: qué son y cómo calcularlos",
        descripcion: "En un seguro de ART, cuantos más puntos de incapacidad tenga el damnificado, mayor será su indemnización a cobrar.",
        link: "https://segurarse.com.ar/blog/2024/03/05/puntos-de-incapacidad-en-la-art-que-son-y-como-calcularlos/"
    },
    {
        titulo: "Documentos para transferir un auto: trámites, gastos y tiempos",
        descripcion: "Existen varios documentos para transferir un auto que hay que tener en cuenta antes de realizar una transacción de compraventa.",
        link: "https://segurarse.com.ar/blog/2023/10/25/documentos-para-transferir-un-auto-tramites-gastos-y-tiempos/"
    },
    {
        titulo: "¿Qué es el certificado de grabado de autopartes?",
        descripcion: "El certificado de grabado de autopartes es un documento muy importante a tener en cuenta para evitar consecuencias legales.",
        link: "https://segurarse.com.ar/blog/2023/10/26/que-es-el-certificado-de-grabado-de-autopartes/"
    },
    {
        titulo: "Seguro para autos viejos: qué opciones hay en Argentina",
        descripcion: "Existen múltiples tipos de seguro para autos viejos para proteger aquellos vehículos que acarrean décadas de historia.",
        link: "https://segurarse.com.ar/blog/2023/12/27/seguro-para-autos-viejos-que-opciones-hay-en-argentina/"
    },
    {
        titulo: "Qué es el seguro de vida obligatorio para trabajadores",
        descripcion: "Los empleadores argentinos están obligados a hacer frente a una serie de cargas sociales para garantizar derechos de sus trabajadores en relación de dependencia. Para qué sirve el seguro de vida obligatorio para trabajadores y qué cobertura tiene.",
        link: "https://segurarse.com.ar/blog/2022/12/21/que-es-el-seguro-de-vida-obligatorio-para-trabajadores/"
    },
    {
        titulo: "Diferencias entre hurto y robo para el seguro",
        descripcion: "Cuáles son los pasos a seguir si roban o hurtan tu vehículo. Qué debes hacer ante la policía, tu compañía aseguradora y el registro automotor.",
        link: "https://segurarse.com.ar/blog/2023/03/31/diferencias-entre-hurto-y-robo-para-el-seguro/"
    },
    {
        titulo: "Me chocaron el auto estacionado y se fueron: ¿qué hago?",
        descripcion: "Como en todo accidente de tránsito, si te chocaron el auto estacionado y se fueron, lo primero que hay que hacer es el reclamo.",
        link: "https://segurarse.com.ar/blog/2024/04/22/me-chocaron-el-auto-estacionado-y-se-fueron-que-hago/"
    },
    {
        titulo: "El seguro contra incendio ¿es obligatorio para el inquilino?",
        descripcion: "Contar o no con un seguro contra incendio es una de las dudas más frecuentes entre los inquilinos por su importancia y costo.",
        link: "https://segurarse.com.ar/blog/2023/11/23/el-seguro-contra-incendio-es-obligatorio-para-el-inquilino/"
    },
    {
        titulo: "Seguro del Mercosur: cómo asegurar mi auto si viajo al exterior",
        descripcion: "Una breve guía para saber qué cobertura ofrece, cómo conseguirlo y en qué países de la región te permitirá circular.",
        link: "https://segurarse.com.ar/blog/2022/06/24/seguro-del-mercosur-como-asegurar-mi-auto-si-viajo-al-exterior/"
    }
];


export const home_coberturas = [
    {
        icon: "#icon-seg-auto",
        label: "Auto",
        link: "/seguros-individuos-auto"
    },
    {
        icon: "#icon-seg-moto",
        label: "Moto",
        link: "/seguros-individuos-moto"
    },
    {
        icon: "#icon-seg-prepaga",
        label: "Prepaga Médica",
        link: "/seguros-individuos-prepaga"
    },
    {
        icon: "#icon-seg-celular",
        label: "Celular",
        link: "/seguros-individuos-celular"
    },
    {
        icon: "#icon-seg-hogar",
        label: "Hogar",
        link: "/seguros-individuos-hogar"
    },
    {
        icon: "#icon-seg-acc-personales",
        label: "Accidentes Personales",
        link: "/seguros-individuos-AP"
    },
    {
        icon: "#icon-seg-bolso",
        label: "Bolso Protegido",
        link: "/seguros-individuos-bolso"
    },
    {
        icon: "#icon-seg-viajero",
        label: "Asistencia al Viajero",
        link: "/seguros-individuos-asistencia"
    },
    {
        icon: "#icon-seg-embarcaciones",
        label: "Embarcación",
        link: "/seguros-individuos-embarcacion"
    }
];

export const home_virtudes = [
    {
        titulo: "Digital",
        descripcion: "Cotizás 100% online y en menos de un minuto",
        icono: "#icon-motivo-digital"
    },
    {
        titulo: "Rápido",
        descripcion: "Comparás en tiempo real coberturas y precios de todas las aseguradoras",
        icono: "#icon-motivo-rapido"
    },
    {
        titulo: "Transparente",
        descripcion: "Sin dar vueltas emitís online el seguro que mejor se adapta a vos",
        icono: "#icon-motivo-transparente"
    },
    {
        titulo: "Personalizado",
        descripcion: "Tenemos un equipo de asesores expertos para responder tus consultas",
        icono: "#icon-motivo-personalizado"
    },
]


//---------------------------------------------

//Landing Quienes somos --------------------------------

// export const quienes_somos_novedades = [
//     {
//         descripcion: "“Seguros que pueden salvar a Pymes de desastres financieros.”",
//         link: "https://mercado.com.ar/finanzas/seguros-que-pueden-salvar-a-pymes-de-desastres-financieros/#:~:text=Desde-,Segurarse%2C,-br%C3%B3ker%20digital%20integral",
//         img: mercadoLogo
//     },
//     {
//         descripcion: "“Bróker digital de seguros en América Latina”.",
//         link: "",
//         img: clarinLogo
//     },
//     {
//         descripcion: "“Es una de las pioneras en el desarrollo de este producto.”",
//         link: "https://noticias.perfil.com/noticias/empresas/acceder-pese-al-cepo.phtml#:~:text=Hay%20aseguradoras%20que,y%20computadoras.",
//         img: noticiasLogo
//     }
// ];


export const quienes_somos_novedades = [
    {
        descripcion: "Intermediación de seguros utilizando tecnología avanzada. Obtener cotizaciones de las aseguradoras más importantes del mercado en tiempo real.",
        link: "https://www.forbesargentina.com/daily-cover/las-startups-tecnologicas-suben-agenda-a-gobierno-ponen-foco-silicon-valley-exportar-valor-mundo-n57087",
        img: forbesLogo
    },
    {
        descripcion: "El bróker pone a disposición su cotizador online para que, en cuestión de minutos, sepas precios y puedas emitir 100% online.",
        link: "https://ar.motor1.com/news/628396/noticias-breves-semana-509/",
        img: motor1Logo
    },
    {
        descripcion: "Bróker de seguros digital pionero en América Latina, con presencia en Argentina, Uruguay y Brasil,",
        link: "https://www.ambito.com/como-ganar-dolares-el-mundo-it-saber-programar-n5731264",
        img: ambitoLogo
    },
    {
        descripcion: "Segurarse, broker digital de seguros que está creando un laboratorio de IA orientado a la industria, pionero en Argentina, ya desarrolló un bot que acompaña en el proceso de compra digital de seguro.",
        link: "https://www.cronista.com/apertura/empresas/los-mil-usos-de-la-ia-en-la-argentina-que-resultados-les-esta-dando-a-las-empresas-nacionales/",
        img: cronistaLogo
    },
    {
        descripcion: "Se afianza el rol de Segurarse como player tecnológico en la distribución digital de seguros",
        link: "https://www.eldiariodecarlospaz.com.ar/sociedad/2023/12/19/se-afianza-el-rol-de-segurarse-como-player-tecnologico-en-la-distribucion-digital-de-seguros-190633.html",
        img: diariocarlospazLogo
    },
    {
        descripcion: "Segurarse lideró el proceso de transformación digital del mercado asegurador en América Latina. Agilidad y eficiencia puesta al servicio del cliente.",
        link: "https://www.bariloche2000.com/noticias/leer/descubre-el-secreto-la-patente-revela-el-precio-de-tu-seguro-de-auto/147845",
        img: bariloche2000Logo
    },
    {
        descripcion: "El bróker digital de seguros elaboró una guía para responder a los interrogantes más frecuentes que tienen los conductores respecto a esta clase de operativos.",
        link: "https://noticiasargentinas.com/sociedad/verano-y-seguridad-vial--que-pueden-pedirte-y--que-no-en-un-control-en-la-ruta-_a674a24ac4671a4fe68763104?srstlid=AfmBOopr1tAu8FcQwMB1cxwuB6e0Zsm5f6Y2F3l6lJJbYjKr3mvEy1v5_",
        img: noticiasargentinasLogo
    },
    {
        descripcion: "Las personas quieren utilizar los datos para mejorar la toma de decisiones.",
        link: "https://eleconomista.com.ar/negocios/sobreabundancia-datos-nuevo-drama-afecta-empresas-2023-n64970",
        img: eleconomistaLogo
    },
    {
        descripcion: "Hoy nos encontramos con perfiles mucho más humanizados, más orientados a resultados de negocio y a pensar en qué valor agregado le damos al cliente.",
        link: "https://www.clarin.com/servicios/ganar-sueldos-dolares-entrar-mundo-tecnologico-saber-programar_0_qwWrhkDDPb.html",
        img: clarinLogo
    }
];
//------------------------------------------------------

//Landing Individuos -----------------------------------

export const individuos_beneficios = [
    {
        titulo: "Respuesta inmediata",
        descripcion: "Tenemos múltiples canales de atención personalizada. La satisfacción de nuestros asegurados es prioridad absoluta.",
        icono: "#icon-motivo-rapido"
    },
    {
        titulo: "Gestiones de siniestros y cobranzas",
        descripcion: "Nos involucramos en todo el proceso, nuestro servicio es integral.",
        icono: "#icon-motivo-g-siniestros"
    },
    {
        titulo: "App Mi Segurarse",
        descripcion: "Autogestioná tu seguro desde tu celular. Es fácil y rápido. Es para vos.",
        icono: "#icon-motivo-misegurarse"
    },
    {
        titulo: "Descuentos exclusivos en otros riesgos",
        descripcion: "Somos tu bróker de confianza, decinos que buscas asegurar y te ayudamos.",
        icono: "#icon-motivo-descuentos"
    },
]


export const individuos_coberturas = [
    {
        icon: "#icon-seg-auto",
        label: "Auto",
        link: "/seguros-individuos-auto"
    },
    {
        icon: "#icon-seg-moto",
        label: "Moto",
        link: "/seguros-individuos-moto"
    },
    {
        icon: "#icon-seg-prepaga",
        label: "Prepaga Médica",
        link: "/seguros-individuos-prepaga"
    },
    {
        icon: "#icon-seg-celular",
        label: "Celular",
        link: "/seguros-individuos-celular"
    },
    {
        icon: "#icon-seg-hogar",
        label: "Hogar",
        link: "seguros-individuos-hogar"
    },
    {
        icon: "#icon-seg-acc-personales",
        label: "Accidentes Personales",
        link: "/seguros-individuos-AP"
    },
    {
        icon: "#icon-seg-bolso",
        label: "Bolso Protegido",
        link: "/seguros-individuos-bolso"
    },
    {
        icon: "#icon-seg-viajero",
        label: "Asistencia al Viajero",
        link: "seguros-individuos-asistencia"
    },
    {
        icon: "#icon-seg-embarcaciones",
        label: "Embarcación",
        link: "/seguros-individuos-embarcacion"
    }
];



//------------------------------------------------------

//Landing One Team -----------------------------------

export const one_team_espiritu = [
    {
        icon: "#icon-espiritu-01",
        titulo: "FLEXIBILIDAD",
        descripcion: "Nos apasionan los nuevos desafíos y sabemos adaptarnos al cambio permanente con agilidad."
    },
    {
        icon: "#icon-espiritu-02",
        titulo: "COMPROMISO",
        descripcion: "Asumimos seriamente nuestro compromiso de negocio con alta vocación de servicio y actitud responsable."
    },
    {
        icon: "#icon-espiritu-03",
        titulo: "APERTURA",
        descripcion: "Tenemos un pensamiento abierto, buscamos soluciones e innovación, respetando la diversidad, aceptando nuestras."
    },
    {
        icon: "#icon-espiritu-04",
        titulo: "INTEGRIDAD",
        descripcion: "Aseguramos que todo lo que hacemos para nuestros clientes y nuestra gente se realice con transparencia."
    }
]


//----------------------------------------------------

//Landing Politicas de privacidad -----------------------------------

export const politicas_privacidad = [
    {
        titulo: "Introducción:",
        content: (<>
            El sitio Segurarse.com.ar pertenece a <b>Segurarse Asesores de Seguros S.A.</b>,
            productores asesores de seguros conforme surge de la Ley 22.400 matrícula 1174,
            expedida por la Superintendencia de Seguros de la Nación
            (<a className="text-decoration-underline" href="https://www.ssn.gob.ar/" target="_blank" rel="noreferrer">www.ssn.gob.ar</a>),
            no revistiendo el carácter de compañía de seguros, con domicilio fiscal en Av. Bartolomé Mitre 1249 P.B. "B",
            Florida Oeste, Buenos Aires, Argentina. El pilar del sitio Segurarse.com.ar es ayudar al usuario a informarse y
            educarse sobre la distinta gama de seguros y también se le permite al usuario interesado solicitar múltiples
            cotizaciones online por intermedio de un formulario web. Para esto el usuario otorga voluntariamente sus datos
            personales de contacto y otros datos necesarios para poder cotizar online como por ejemplo los datos del vehículo
            en el caso de seguros de auto. Los datos personales voluntariamente otorgados pueden ser utilizados por Segurarse.com.ar
            y/o <i>Segurarse Asesores de Seguros S.A.</i> para brindar respuestas y soporte personalizado.
        </>)
    },
    {
        titulo: "Uso de la información recopilada:",
        content: <>Segurarse Asesores de Seguros S.A., con domicilio en Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste,
            Buenos Aires, Argentina, es el responsable por el tratamiento de sus datos personales y el titular de la base de datos
            registrada ante la Dirección Nacional de Protección de datos personales.
            <span> </span><a className="ml-1 text-decoration-underline" href="https://www.argentina.gob.ar/aaip/datospersonales" target="_blank" rel="noreferrer">Aquí</a>.
            <br className="mt-3" />
            Segurarse.com.ar, utiliza los datos personales de contactos recopilados con el solo objetivo de proporcionar información
            acerca de las cotizaciones online que el mismo usuario solicita de forma voluntaria y son destinados a responder y/o dar
            soporte a las consultas que el usuario formula en tiempo y forma. Este es el único uso de la información que el usuario
            otorga voluntariamente.<br /><br />
            El usuario consiente en forma expresa, informada, voluntaria e inequívoca el tratamiento de sus datos personales por
            Segurarse.com.ar, de acuerdo con los términos de esta Política de Privacidad.<br /><br />
            AL ACCEDER, CONECTARSE Y/O DE CUALQUIER OTRA FORMA UTILIZAR EL SITIO, EL USUARIO. CONFIRMA QUE HA LEÍDO,
            ENTENDIDO, CONSIENTE Y ACEPTA LOS TÉRMINOS Y CONDICIONES DE ESTAS POLÍTICAS DE PRIVACIDAD.
            SI EL USUARIO NO ESTÁ DE ACUERDO CON LOS TÉRMINOS DE LAS POLÍTICAS DE PRIVACIDAD, POR FAVOR NO UTILICE ESTE SITIO.
        </>
    },
    {
        titulo: "Menores de edad",
        content: <>
            Segurarse.com.ar se compromete a no discriminar a ningún usuario basándose en su raza, género, orientación sexual,
            religión, discapacidad u origen étnico. Todas las prácticas comerciales serán transparentes, proporcionando
            información clara y comprensible sobre productos, servicios y políticas. Segurarse.com.ar garantiza que todos los
            usuarios tengan acceso equitativo a nuestros productos y servicios, teniendo en cuenta posibles barreras físicas o
            tecnológicas.
        </>
    },
    {
        titulo: "Derechos del usuario titular de los datos personales. Seguridad:",
        content: <>
            Segurarse.com.ar presume y el usuario garantiza, la veracidad de toda la información que sea suministrada por el usuario.
            En consecuencia, Segurarse.com.ar no verifica, ni asume la obligación de verificar la veracidad, vigencia, suficiencia,
            legalidad y autenticidad de los datos que el usuario proporcione. El usuario asume la responsabilidad de mantener dicha
            información actualizada en todo momento.
            <br /><br />
            El usuario podrá acceder en forma gratuita a la información que suministró a Segurarse.com.ar, en intervalos no menores
            a seis meses, salvo que acredite un interés legítimo al efecto, conforme lo previsto en la Ley de Protección de Datos
            Personales Nº 25.326.
            <br /><br />
            Si los datos del usuario son incorrectos, desea actualizarlos y/o suprimirlos, Segurarse.com.ar corregirá, actualizará y/o
            suprimirá esa información al requerimiento del usuario sin costo alguno. Para ejercer los derechos de acceso, rectificación,
            actualización o supresión, conforme lo previsto en la Normativa de Protección de Datos Personales, el usuario deberá enviar
            un correo electrónico a <span> </span>
            <a className="text-decoration-underline" href="mailto:compliance@segurarse.com.ar">
                compliance@segurarse.com.ar</a>.
            indicando qué derecho quiere ejercer y acreditando su identidad enviando
            una foto de frente y dorso de su DNI.
            <br /><br />
            La información suministrada por el usuario será almacenada de forma segura por Segurarse.com.ar y, asimismo, sólo será
            usada para cumplir con los fines legítimos respecto de los cuales Segurarse.com.ar deba conservar sus datos personales.
            <br /><br />
            Los datos recabados se mantendrán en confidencialidad y reserva. Segurarse.com.ar sólo usará los datos a los fines y de
            la manera informada en estas Políticas de Privacidad. Segurarse.com.ar cumple con la normativa de protección de datos
            personales y en particular, con la Ley N° 25.326 y sus normas complementarias. Para garantizar la seguridad de los datos
            personales que el usuario proporcione, Segurarse.com.ar aplicará los mismos criterios y el mismo grado de diligencia que
            aplica para resguardar su propia información. Sin embargo, el usuario reconoce que los medios técnicos existentes que
            brindan seguridad no son inquebrantables y que, aunque se adopten todos los recaudos razonables de seguridad, es posible
            sufrir manipulaciones, destrucción y/o pérdida de información.
        </>
    },
    {
        titulo: "Propiedad de la información:",
        content: <>
            Una vez ingresado un formulario con información personal al sitio, estos datos serán propiedad de Segurarse.com.ar
            (Segurarse Asesores de Seguros S.A.) con domicilio fiscal en Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste, Buenos
            Aires, Argentina, y el usuario estará aceptando las políticas de privacidad de este sitio; por lo que, si el usuario no
            está de acuerdo con las mismas, este no deberá suministrar sus datos personales. Segurarse.com.ar se reserva el derecho
            a modificar sus políticas de privacidad con aviso previo.
        </>
    },
    {
        titulo: "Acerca de las Cookies del sitio:",
        content: <>
            El usuario conoce y acepta que Segurarse.com.ar podrá utilizar un sistema de seguimiento mediante la utilización de
            <span> </span><b>cookies</b>. Las cookies son pequeños archivos, con una duración limitada en el tiempo que permiten personalizar los
            servicios. Se establece que la instalación, permanencia y existencia de las cookies en la computadora y/o dispositivo del
            Usuario depende de su exclusiva voluntad y puede ser eliminada de su computadora y/o dispositivo cuando el usuario así
            lo desee.
        </>
    },
    {
        titulo: "Acerca de las Cookies de Google:",
        content: <>
            Segurarse.com.ar utiliza Google Analytics para analizar el uso de este sitio web. Google Analytics genera información
            estadística sobre el uso del sitio por medio de cookies, que se almacenan en los ordenadores de los usuarios.
            La información generada en relación con nuestro sitio web se utiliza para crear informes sobre el uso de la página web.
            Google va a almacenar y utilizar esta información. Política de privacidad de Google está disponible en:<span> </span>
            <a className="text-decoration-underline"
                href="http://www.google.com/privacypolicy.html"
                target="_blank"
                rel="noreferrer"
            >http://www.google.com/privacypolicy.html</a><br /><br />
            El usuario conoce y acepta que Segurarse.com.ar puede utilizar herramientas de terceros para analizar el uso de este
            sitio web por medio de cookies, que se almacenan en los ordenadores de los usuarios. La información generada en relación
            con nuestro sitio web se utiliza para mejorar la experiencia de navegabilidad.
            Si el usuario lo desea puede eliminar el uso de cookies de almacenamiento de información de manera voluntaria, en
            cualquier momento, a través de la configuración en su navegador. Debe tener en cuenta que esto podría provocar que el
            sitio no funcione en todo su potencial.
        </>
    },
    {
        titulo: "Preguntas y sugerencias:",
        content: <>
            En el caso de que el usuario tenga alguna duda o sugerencia, podrá hacerla vía email a <span> </span>
            <a className="text-decoration-underline" href="mailto:consultas@segurarse.com.ar"> consultas@segurarse.com.ar</a>,
            donde sus opiniones serán evaluadas y analizadas.
        </>
    },
    {
        titulo: "Botón de arrepentimiento:",
        content: <>
            Conforme Resolución 424/2020, de la Secretaria de Comercio Interior, las y los clientes pueden solicitar la revocación de
            la aceptación durante el plazo de <b>DIEZ (10) días corridos</b> contados a partir de la fecha de la celebración del seguro
            contratado únicamente mediante el canal ecommerce de la web
            (<a className="text-decoration-underline" href="https://segurarse.com.ar/">www.segurarse.com.ar</a>)
            <span> </span>
            <a className="text-decoration-underline" href="/formulario-arrepentimiento" target="_blank" rel="noreferrer">Ver más</a>
        </>
    }
]

//-------------------------------------------------------------------