import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useCotizacionStore } from '../../../Services/Providers/CotizacionStore';
import { SegSelect, SegSelectOption } from '../../SegUI/SegSelect/SegSelect';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SegInput } from '../../SegUI/SegInput/SegInput';
import { SegButton } from '../../SegUI/SegButton/SegButton';
import { SegSlider } from '../../SegUI/SegSlider/SegSlider';
import { imagesCompanias } from "../../../Assets/imageCompaniaAP";

interface IDocumento {
    tipoDoc: string;
    numero: string;
}

const tiposDoc = [
    { value: 'dni', label: 'DNI' },
    { value: 'cuit', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' },
];


export const TipoDocumento = () => {
    const nav = useNavigate();
    const { compania } = useParams();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
    const [openSelect, setOpenSelect] = useState<boolean>(true)
    const [errorNumero, setErrorNumero] = useState<boolean>(true)

    const [numero, setNumero] = useState<string>();
    const numeroInputRef = useRef<HTMLInputElement>(null);
    const methods = useForm<IDocumento>();
    const adaptativeMargin = `${10 / (window?.devicePixelRatio || 1)}vh`
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        trigger,
    } = useForm<IDocumento>();

    useEffect(() => {

        setErrorNumero(true)
        setValue("numero", "");
        numero && trigger()
        numeroInputRef.current?.focus();

    }, [tipoDoc])

    useEffect(() => {
        if (cotizacionValues.tipoDoc) {
            setOpenSelect(false);
            setTipoDoc(cotizacionValues.tipoDoc);
        }

        setTimeout(() => {
            if (cotizacionValues.numeroDoc) {
                setValue("numero", cotizacionValues.numeroDoc);
                trigger("numero")
            }
        }, 0)

    }, []);

    const onSubmit = (values: IDocumento) => {
        const { numero } = values;
        setCotizacionValues({
            ...cotizacionValues,
            tipoDoc: tipoDoc,
            numeroDoc: numero,
        });

        if (compania) {
            nav("/ap/cotizacion/" + compania + "/tipo-persona")
        }
        else {
            nav("/ap/cotizacion/tipo-persona")
        }
    }

    return (
        <div className="mt-4 bg-gray-light text-center" >
            <div className="frm01">
                <legend className='mb-4' style={{ fontWeight: "bold" }}>Contratá tu seguro fácil y rápido al mejor precio</legend>
                <legend className='fs-18' style={{ fontSize: "20px" }}>Ingresá el número de documento</legend>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex justify-content-center">
                        <div className="row gx-3 text-start mw-sm-450 m-1">
                            <fieldset className="col-4">
                                <FormProvider {...methods}>
                                    <SegSelect
                                        label="Tipo"
                                        selected={tipoDoc}
                                        setSelected={setTipoDoc}
                                        name="tipoDoc"
                                        options={tiposDoc}
                                        isGrupable
                                        width={"100%"}
                                        borderColor={errors.tipoDoc ? "red" : ""}
                                        openDefault={openSelect}
                                        autoFoco={true}
                                        placeholder=""
                                        handleSubmit={() => {
                                            if (cotizacionValues.razonSocial !== '')
                                                setCotizacionValues({
                                                    ...cotizacionValues,
                                                    nombre: '',
                                                    apellido: '',
                                                })
                                            setNumero('')
                                        }}
                                    />
                                </FormProvider>
                                <p>{errors.tipoDoc?.message}</p>
                            </fieldset>
                            <Controller
                                name="numero"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                setErrorNumero(true)
                                                return "Ingresá el Número"
                                            } else {
                                                setErrorNumero(false)
                                            }
                                        },
                                        pattern: (value) => {
                                            if (tipoDoc?.value === 'dni') {
                                                if (/^\d{7,8}$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return "DNI inválido"
                                                }
                                            } else if (tipoDoc?.value === 'cuit' || tipoDoc?.value === 'cuil') {
                                                if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return `${tipoDoc?.label} inválido`
                                                }
                                            }
                                        }
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        disabled={!tipoDoc}
                                        className="col-8"
                                        label="Sin puntos, guiones ni espacios"
                                        name="setNumero"
                                        type="number"
                                        width={66}
                                        placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                                        value={field.value}
                                        autoFocus={false}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "+" || e.key === "-") {
                                                e.preventDefault()
                                            }
                                        }}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("numero", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("numero");
                                        }}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${errors.numero
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${errors.numero
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                        ref={numeroInputRef}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-buttons">
                        <SegButton
                            label="Continuar"
                            type="submit"
                            className="btn btn-primary"
                            disabled={errorNumero}
                        />
                    </div>
                </form>
            </div>
            <div className="slide-cias" style={{ marginTop: adaptativeMargin }}>
                <SegSlider
                    companias={
                            imagesCompanias
                    }
                />
            </div>
        </div>
    )
}
