import Axios from "axios";
import { getPathName, retryRequest } from "./helper";

interface Telefono {
    CodigoArea: string;
    Numero: string;
    TelefonoEsCelular: boolean;
}

interface ActualizarEmision {
    idItemCotizacion: number | null;
    TipoDePersona: string | null;
    CondicionIVA: string | null;
    TipoUso: string | null;
    TieneGNC: boolean | null;
    Nombre: string | null;
    Apellido: string | null;
    Email: string | null;
    TipoDocumento: string | null;
    NroDocumento: string | null;
    Cuil: string | null;
    FechaNacimiento: string | null;
    Telefono: Telefono | null;
    TelefonoAdicional: Telefono | null;
    Provincia: string | null;
    Localidad: string | null;
    Calle: string | null;
    NumeroCalle: string | null;
    Piso: string | null;
    Departamento: string | null;
    CodigoPostal: string | null;
    Patente: string | null;
    NroMotor: string | null;
    NroChasis: string | null;
    NumeroTarjeta: string | null;
    MedioPago: string | null;
    TipoTarjetaCredito: string | null;
    BancoTarjetaCredito: string | null;
    NombreTitularTarjeta: string | null;
    ApellidoTitularTarjeta: string | null;
    MesVencimientoTarjeta: string | null;
    AnioVencimientoTarjeta: string | null;
}

type TypeStep = 0 | 1 | 2 | 3 | "F"; 

/*const initialActualizarEmision: ActualizarEmision = {
    idItemCotizacion: 0,
    TipoDePersona: null,
    CondicionIVA: null,
    TipoUso: null,
    TieneGNC: null,
    Nombre: null,
    Apellido: null,
    Email: null,
    TipoDocumento: null,
    NroDocumento: null,
    Cuil: null,
    FechaNacimiento: null,
    Telefono: null,
    TelefonoAdicional: null,
    Provincia: null,
    Localidad: null,
    Calle: null,
    NumeroCalle: null,
    Piso: null,
    Departamento: null,
    CodigoPostal: null,
    Patente: null,
    NroMotor: null,
    NroChasis: null,
    NumeroTarjeta: null,
    MedioPago: null,
    TipoTarjetaCredito: null,
    BancoTarjetaCredito: null,
    NombreTitularTarjeta: null,
    ApellidoTitularTarjeta: null,
    MesVencimientoTarjeta: null,
    AnioVencimientoTarjeta: null,
};*/

export const updateEmisionStep = async ( step: TypeStep, data: ActualizarEmision ) => {
   
    const params = {
        hash: process.env.REACT_APP_HASH,
        step,
        actualizarEmision: {
            //...initialActualizarEmision,
            ...data
        }
    }

    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    const axiosPostRequest = () => {
        return Axios.post(getPathName() + "/SetUpdateEmisionStep", params, {
            headers: headers
        });
    };

    try {
        const response = await retryRequest(axiosPostRequest);
        return response;
    } catch (error: any) {
        console.error('Fallo de hacer varios intentos: ' + error.message);
        return error;
    }
}