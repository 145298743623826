import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../Services/Providers/CotizacionStore";
import { TelefonosCompañia, telefonos } from "../../Assets/telefonosCompanias";
import obtenerParametros from "../../Services/API/Parametros";
import { SegButton } from "../../Components/SegUI/SegButton/SegButton";
import { SegStepper } from "../../Components/SegUI/SegStepper/SegStepper";
import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import segurarse from "../../Assets/img/logo_segurarse.svg";
import obtenerDescripciones from "../../Services/API/Descripciones";
import { useDescripcionesStore } from "../../Services/Providers/DescripcionesStore";
import { Email } from "../../Components/Slides/ART/Cotizacion/Email";
import { CodigoPostalManual } from "../../Components/Slides/ART/Cotizacion/CodigoPostalManual";
import { Telefono } from "../../Components/Slides/ART/Cotizacion/Telefono";
import { MasaSalarial } from "../../Components/Slides/ART/Cotizacion/MasaSalarial";
import { NombreApellido } from "../../Components/Slides/ART/Cotizacion/NombreApellido";
import { Empleados } from "../../Components/Slides/ART/Cotizacion/Empleados";
import { CuitRazonSocial } from "../../Components/Slides/ART/Cotizacion/CuitRazonSocial";
import useSEOConfig from "../../hooks/seo/useSEOConfig";

export interface IPasos {
  step: number;
  subStep: number;
}

export interface CotizadorProps {
  step?: number;
  subStep?: number;
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
`;

const Content = styled.div`
  flex-grow: 1;
`;

export const Cotizador = ({ step = 0, subStep = 1 }: CotizadorProps) => {
  const { compania } = useParams();
  const nav = useNavigate();

  const Icon = styled.svg`
    color: #343434 !important;
  `;
  const referer = document.referrer;
  const landingUrl = document.location.href;

  const [steps, setSteps] = useState<IPasos>({ step, subStep });
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const [paramsCargando, setParamsCargando] = useState(false);
  const [phones, setPhones] = useState<TelefonosCompañia[]>(telefonos);
  const [showConsent, setShowConsent] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalAnimationClass, setModalAnimationClass] = useState("fade-in-top");
  const [backdropClass, setBackdropClass] = useState("fade-in");
  const [justifyContent, setJustifyContent] = useState(
    "justify-content-center"
  );
  const {
    descripcionesValues,
    setDescripcionesValues,
  } = useDescripcionesStore();

  useEffect(() => {
    function ajustarJustifyContent() {
      if (window.innerWidth >= 768) {
        setJustifyContent("col-md-3 text-md-end align-self-center");
      } else {
        setJustifyContent(
          "d-flex align-items-center justify-content-center mt-3"
        );
      }
    }

    // Llamar a la función al cargar la página y al cambiar el tamaño de la ventana
    ajustarJustifyContent();
    window.addEventListener("resize", ajustarJustifyContent);

    // Limpieza del listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", ajustarJustifyContent);
    };
  }, []);

  const cerrarModal = () => {
    setBackdropClass("fade-out");
    setModalAnimationClass("fade-out-top");
    setTimeout(() => {
      setShowConsent(false);
    }, 500);
  };

  useEffect(() => {
    const handleScrollLock = (showConsent: boolean) => {
      if (showConsent) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };
    handleScrollLock(showConsent);

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showConsent]);

  const backButtonClick = () => {
    nav(-1);
  };

  useEffect(() => {
    setSteps((prev) => {
      return {
        ...prev,
        step: step,
        subStep: subStep,
      };
    });
  }, [step, subStep]);

  useEffect(() => {
    if (!cotizacionValues.parametrosIniciales) {
      setParamsCargando(true);
      const fetchDataParams = async () => {
        const response: any = await obtenerParametros({ rubro: "ART" });
        if (response) {
          setCotizacionValues({
            ...cotizacionValues,
            parametrosIniciales: response.data,
            referer: referer,
            landingUrl: landingUrl,
          });
        }
      };

      fetchDataParams().then(() => {
        setParamsCargando(false);
      });
    }
  }, []);

  useSEOConfig({
    title: "ART",
  })

  useEffect(() => {
    if (
      cotizacionValues.parametrosIniciales &&
      descripcionesValues?.length === 0
    ) {
      const fetchDataDesc = async (
        rubro: string,
        origen: string,
        tipoCotizador: string
      ) => {
        const response: any = await obtenerDescripciones(
          rubro,
          origen,
          tipoCotizador
        );
        if (response) {
          setDescripcionesValues(response.data.api_data);
        }
      };
      fetchDataDesc(
        cotizacionValues.parametrosIniciales.rubro,
        cotizacionValues.parametrosIniciales.origen,
        cotizacionValues.parametrosIniciales.tipoCotizador
      );
    }
  }, [cotizacionValues.parametrosIniciales]);

  useEffect(() => {
    if (cotizacionValues.contactoId) {
      setCotizacionValues({
        ...cotizacionValues,
        contactoId: undefined,
      });
    }
  }, [
    cotizacionValues.cuit,
    cotizacionValues.masaSalarial,
    cotizacionValues.razonSocial,
    cotizacionValues.empleados,
    cotizacionValues.email,
    cotizacionValues.telefono,
    cotizacionValues.localidad,
    cotizacionValues.provincia,
  ]);

  useEffect(() => {
    const checkCookieConsent = () => {
      const cookieExists = document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("cookies="));
      if (!cookieExists) {
        setShowConsent(true);
      } else {
        const cookieValue = document.cookie.replace(
          /(?:(?:^|.*;\s*)cookies\s*=\s*([^;]*).*$)|^.*$/,
          "$1"
        );
        if (cookieValue !== "yes") {
          setShowConsent(true);
        }
      }
    };

    checkCookieConsent();
    return () => { };
  }, []);

  const handleConsent = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 5);
    document.cookie = `cookies=yes; max-age=${5 * 365 * 24 * 60 * 60}`;
    setShowConsent(false);
  };

  const renderVista = () => {
    switch (steps?.step) {
      case 0:
        switch (steps?.subStep) {
          case 1:
            return (
              <CuitRazonSocial
                setSteps={setSteps}
                cuitValue={cotizacionValues.cuit}
                razonSocialValue={cotizacionValues.razonSocial}
              />
            );
          case 2:
            return (
              <Empleados
                setSteps={setSteps}
                empleadosValue={cotizacionValues.empleados}
              />
            );
          case 3:
            return (
              <MasaSalarial
                setSteps={setSteps}
                masaSalarialValue={cotizacionValues.masaSalarial}
              />
            );
        }
        break;
      case 1:
        switch (steps?.subStep) {
          case 1:
            return (
              <NombreApellido
                setSteps={setSteps}
                nombreValue={cotizacionValues.nombre}
                apellidoValue={cotizacionValues.apellido}
              />
            );
          case 2:
            return (
              <Email setSteps={setSteps} emailValue={cotizacionValues.email} />
            );
          case 3:
            return (
              <Telefono
                setSteps={setSteps}
                telefonoValue={cotizacionValues?.telefono}
              />
            );
          case 4:
            return <CodigoPostalManual setSteps={setSteps} />;
        }
        break;
      default:
        return <h1>loading</h1>;
    }
  };

  const tel = phones.filter((t: TelefonosCompañia) => {
    if (t.clave === compania) {
      return t;
    }
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        cerrarModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showConsent]);

  return (
    <>
      <PageContainer>
        <div className="sticky-top bg-white">
          <div className="container pt-3 pb-3">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                {" "}
                {/* Centra el contenido en la columna */}
                <SegHeaderLogo
                  img={segurarse}
                  alt="Logo Segurarse"
                  width="160px"
                />
              </div>
              {compania && tel.length > 0 && (
                <div className={`${justifyContent}`}>
                  {" "}
                  {/* Alinea el contenido al centro vertical y a la derecha */}
                  <a
                    href={"tel:" + tel[0]?.telefono}
                    className="tel align-items-center gap-2"
                  >
                    <Icon className="icon">
                      <use xlinkHref="#tel" />
                    </Icon>
                    {tel[0]?.telefono}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <SegStepper
            icon="art"
            label="Cotizá seguro ART"
            stepActive={steps.step === 0 ? 1 : 2}
            isStepper={true}
            finalStep="art"
          />
        </div>
        {/* {steps?.subStep >= 1 && steps?.step! === 1 && (
                <div className="cot-bc">
                    <div className="container mt-2">
                        <SegBackButton
                            marca={`${cotizacionValues?.marca?.label ? cotizacionValues?.marca?.label : ""}`}
                            version={`${cotizacionValues?.version?.label ? cotizacionValues?.version?.label : ""}`}
                            modelo={`${cotizacionValues?.modelo?.label ? cotizacionValues?.modelo?.label : ""}`}
                            anio={`${cotizacionValues?.año?.label ? cotizacionValues?.año?.label : ""}`}
                            onClick={backButtonClick}
                        />
                    </div>
                </div>
            )} */}
        {paramsCargando ? (
          <div className="d-flex justify-content-center py-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Content>
            <div className="">{renderVista()}</div>
          </Content>
        )}
        {showConsent && (
          <div className={`modal-backdrop show ${backdropClass}`}></div>
        )}
        {showConsent && (
          <div ref={modalRef}>
            <div
              className={`fixed-bottom cookie-consent ${modalAnimationClass}`}
              id="cookieconsent"
              tabIndex={-1}
              aria-labelledby="modal"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={cerrarModal}
                    ></button>
                  </div>
                  <div className="row align-items-center pb-lg-2 pt-2">
                    <div className="col-lg-9">
                      <div className="ff-mukta fs-16 fs-lg-30 fw-bold lh-sm text-primary">
                        ¡Bienvenido a Segurarse.com.ar!
                      </div>
                      <p className="my-2 fs-13 fs-lg-14 lh-sm">
                        En este sitio utilizamos cookies. Las cookies son
                        necesarias para el correcto funcionamiento y la
                        seguridad de la página, además nos ayudan a
                        proporcionarte una mejor experiencia de navegación. Para
                        más información consulta nuestra{" "}
                        <a
                          href={
                            "https://segurarse.com.ar/politicas-privacidad"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="policy-button"
                        >
                          Políticas de Privacidad
                        </a>
                        . <br /> Segurarse.com.ar nunca te solicitará
                        información confidencial, datos bancarios o de tarjetas
                        de crédito ya sea por mail, teléfono, WhatsApp o redes
                        sociales. Ante cualquier llamado o mensaje dudoso
                        comunicate con tu asesor o a través de los canales
                        indicados en la sección de Contacto.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3 py-lg-0">
                      <SegButton
                        className="fs-14 fs-lg-16 btn-primary btn-modal"
                        label="Acepto"
                        type="submit"
                        onClick={() => {
                          handleConsent();
                        }}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageContainer>
    </>
  );
};
