import Axios from 'axios'
import { getPathName, retryRequest } from './helper';

export interface DataEmisionAP {
    IdCotizacion: string;
    DomicilioCalle: string;
    DomicilioNumero: string;
    DomicilioPiso: string;
    DomicilioDepto: string;
    ClausulaNoRepeticion: boolean;
    MedioPago: string;
    NumeroTarjeta: string;
    Banco: string;
    TipoTarjetaCredito: string;
    NombreTitularTarjeta: string;
    ApellidoTitularTarjeta: string;
    MesVencimientoTarjeta: string;
    AnioVencimientoTarjeta: string;
    TipoCuentaBancaria: string;
    CBU: string;
    FechaNacimiento: string;
    Empresas: any[];
    Asegurados: AseguradoAP[];
}

interface AseguradoAP {
    Nombre: string;
    Apellido: string;
    FechaNacimiento: string;
    IdActividad: string;
    TipoDocumento: string;
    NroDocumento: string;
    EstadoCivil: string;
    Beneficiarios: any[];
}


const emisionAP = async (data: DataEmisionAP) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        emision: {
            ...data
        }
    }

    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    const axiosPostRequest = () => {
        return Axios.post(getPathName() + "/SetEmisionAPJSON", params, {
            headers: headers
        });
    };

    try {
        const response = await retryRequest(axiosPostRequest);
        return response;
    } catch (error: any) {
        console.error('Fallo de hacer varios intentos: ' + error.message);
        return error;
    }
}

export default emisionAP