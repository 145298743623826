import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const Art = () => {

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro ART'
                    url='/cotizador-de-seguros-art'
                    icon='#icon-seg-art'
                    subTitulo={{ __html: "Protegé a tus empleados con la mejor cobertura ante accidentes laborales o enfermedades profesionales.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                La contratación del seguro de ART (Accidentes de Riesgo de Trabajo) es obligatoria para todos los empleadores que tengan personal en relación de dependencia. Está diseñado para proteger a los empleados en caso de sufrir accidentes o enfermedades relacionadas con su trabajo.<br />
                                El seguro de ART proporciona cobertura médica, indemnizaciones y rehabilitación para los empleados afectados, así como protección legal para el empleador.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Cobertura médica:</b> cubre los gastos médicos necesarios para tratar las lesiones o enfermedades causadas por accidentes laborales. Esto puede incluir atención médica, hospitalización, cirugía, medicamentos, terapia física y rehabilitación.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Indemnización por incapacidad:</b> en caso de que un empleado sufra una incapacidad temporal o permanente debido a un accidente laboral, el seguro de ART puede proporcionar indemnizaciones para compensar la pérdida de ingresos o la disminución de la capacidad de trabajo.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Prestaciones por muerte o invalidez:</b> en situaciones trágicas en las que un empleado fallece o sufre una invalidez total y permanente debido a un accidente laboral, el seguro de ART puede brindar prestaciones a los beneficiarios del empleado, como una indemnización por muerte o una pensión por invalidez.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Rehabilitación y readaptación laboral:</b> cobertura de gastos de programas de rehabilitación y readaptación laboral para ayudar a los empleados a recuperarse de sus lesiones y reintegrarse al entorno laboral de manera segura.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Asistencia legal y defensa:</b> en el caso de que se produzcan disputas legales relacionadas con accidentes de trabajo, el seguro de ART puede proporcionar asistencia legal y defensa al empleador, protegiéndolo de demandas y costos legales adicionales.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
