import styled from '@emotion/styled';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { SegSinCotis } from '../../../SegUI/SegSinCotis/SegSinCotis';
import { formatearMoneda } from '../../../../helpers/formatearMoneda';
import descripcionesAP from "../../../../Services/Data/DescripcionesAP.json";
interface GrillaProps {
    cotizaciones: any[];
    setDescripciones: (descripciones: any) => void;
    cotizando: boolean
}

const StyledDiv = styled.div`
margin: 8px 12px 8px 0px;
padding: 5px 15px 12px;
width: 100%;
text-align: center;
font-weight: 500;
font-size: 12px;
background: #FFFEFC;
box-shadow: 0px 0px 8px rgba(164, 164, 164, 0.3);
border-radius: 5px;
color: #363636;
display: none;
@media (min-width: 992px) {
  display:flex;
}
`

export const Grilla = ({ cotizando, cotizaciones, setDescripciones }: GrillaProps) => {

    const [opcionFiltrado, setOpcionFiltrado] = useState<string>("");
    const [cotizacionesOriginales, setCotizacionesOriginales] = useState<any[]>([]);
    const [opcionAseguradora, setOpcionAseguradora] = useState<string>("0");
    const [ordenPrecio, setOrdenPrecio] = useState<string>("0");
    const [grillaKey, setGrillaKey] = useState<number>(0);
    const grillaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Actualizar el estado de cotizacionesOriginales al inicio y cuando cambia la prop cotizaciones
        setCotizacionesOriginales(cotizaciones);
    }, [cotizaciones]);

    const ordenarCotizaciones = (cotizaciones: any[], ordenPrecio: string): any[] => {
        if (ordenPrecio === "0") {
            // Si la opción seleccionada es "Sin orden", no realizar ningún ordenamiento
            return cotizaciones;
        }

        // Ordenar por precio
        const sortedCotizaciones = cotizaciones.slice().sort((a, b) => {
            const precioA = parseFloat(a.premio)//parseFloat(a.premio.replace("$", "").replace(",", ""));
            const precioB = parseFloat(b.premio)//parseFloat(b.premio.replace("$", "").replace(",", ""));
            return ordenPrecio === "1" ? precioA - precioB : precioB - precioA;
        });

        return sortedCotizaciones;
    };

    const filtrarCotizaciones = (ordenPrecio: string): any[] => {
        let cotizacionesFiltradas = cotizacionesOriginales;
        cotizacionesFiltradas = ordenarCotizaciones(cotizacionesFiltradas, ordenPrecio);

        return cotizacionesFiltradas;
    };


    const handleFiltrosChange = (ordenPrecio: string) => {
        filtrarCotizaciones(ordenPrecio);
    };


    const handleSelectChangePrecio = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setOrdenPrecio(selectedValue);
        //handleFiltrosChange(selectedValue);
    };

    const renderIconDescResumen = (descripciones: any[], item: string): ReactElement => {
        let value = "";
        descripciones.forEach(desc => {
            if (desc.descripcion.toLowerCase().includes(item)) {
                
                value = formatearMoneda(desc.monto + "");
            }
        })

        return <span className='fs-20'>{value}</span>
    }

    const handleDescripcion = (event: React.MouseEvent, coti: any) => {
        if (coti) {
            const nuevaDesc: any = {
                id: coti.id,
                amountMonth: coti.premio,
                companyLogo: coti.LogoCompania,
                descList: coti.descripciones,
                nombreCompania: coti.NombreCompania,
                CodigoCompania: coti.CodigoCompania,
                EmisionHabilitada: coti.EmisionHabilitada,
                planName: coti.nombrePlan,
                premio: coti.premio,
                //fiscalInfoImg: coti.Afip
            }
            setDescripciones(nuevaDesc);
        }
    }

    const cotizacionesAjustadas = useMemo(() => {
        return filtrarCotizaciones(ordenPrecio);
    }, [cotizacionesOriginales, opcionFiltrado, opcionAseguradora, ordenPrecio]);

    return (
        <>
            <div className="filter-table d-none d-lg-block" style={{paddingBottom: "12px"}}>
                <div className="sticky-lg-top">
                    <div className="ft-header">
                        <div className="card3 card3-layout">
                            <div className="header">
                                <div className="ft-filters">
                                    <form className="container-fluid">
                                        <div className="row gx-5">
                                            <div className="col">
                                                <div className="d-flex align-items-center gap-4">
                                                    <label htmlFor="cbo3" className="fs-18">Precio</label>
                                                    <div className="">
                                                        <select
                                                            className="form-select form-select-sm"
                                                            style={{ width: "190px" }}
                                                            id="cbo3"
                                                            onChange={handleSelectChangePrecio}
                                                            value={ordenPrecio}
                                                        >
                                                            <option value="0">Por default</option>
                                                            <option value="1">Menor a mayor</option>
                                                            <option value="2">Mayor a menor</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="items" style={{ gap: "6px" }}>
                               
                                {
                                    descripcionesAP.map( desc => (
                                        <div key={desc.titulo} className="item fs-16">{desc.titulo_breve}</div>
                                    ))
                                }
                            </div>
                            <div className="actions"></div>
                        </div>
                    </div>
                </div>

                <div key={grillaKey} className="ft-rows" ref={grillaRef}>
                    {cotizacionesAjustadas &&
                        cotizacionesAjustadas.map(
                            (coti, index) =>
                                <div
                                    className="card3 card3-layout"
                                    key={index}
                                >
                                    <div className="header p-4">
                                        <div className="col-6">
                                            <div className="empresa">
                                                <img
                                                    src={coti.LogoCompania}
                                                    height="42"
                                                    style={{ maxWidth: '160px' }}
                                                    // height="22"
                                                    alt={coti.NombreCompania}
                                                    loading="lazy"
                                                />
                                                {/*<p className='fs-14'>Accidentes Personales</p>*/}
                                            </div>
                                            {/* <div className="cobertura">{coti.nombrePlan}</div>*/}
                                        </div>
                                        <div className="col-6">
                                            <div className="cont">
                                                <div className="precio">
                                                    <span className="monto">{"$" + coti.premio}</span>
                                                    <span className="periodo"> /mes</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items">
                                        <div className="item">
                                            {renderIconDescResumen(coti.descripciones, "muerte")}
                                        </div>
                                        <div className="item">
                                            {renderIconDescResumen(coti.descripciones, "invalidez")}
                                        </div>
                                        <div className="item">
                                            {renderIconDescResumen(coti.descripciones, "reembolso")}
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <Link
                                            className="btn1 btn1-primary"
                                            to={coti.EmisionHabilitada ? "/ap/emision/" : "/ap/cotizacion/gracias"}
                                            role="button"
                                            onClick={(e: any) => handleDescripcion(e, coti)}
                                        >
                                            Contratar{" "}
                                            <svg className="icon">
                                                <use xlinkHref="#arrowright2" />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                        )
                    }
                    {
                        (cotizando) &&
                        <StyledDiv key={"skeloton-grilla-"}>
                            <Skeleton
                                containerClassName="col-2"
                                count={1}
                                circle={true}
                                style={{ borderRadius: "100%", width: "54px", height: "54px" }} />
                            <div className="col-10 d-flex gap-1">
                                {[1, 2, 3, 4].map(ske => (
                                    <Skeleton
                                        key={"skeleton-" + ske}
                                        containerClassName="col-2 skeleton-container mx-4"
                                        count={1}
                                        circle={false}
                                        style={{ height: "12px", margin: "10px 0px" }}
                                    />
                                ))}
                            </div>
                        </StyledDiv>
                    }
                    {
                        (!cotizando && opcionFiltrado === "" && cotizacionesAjustadas.length === 0) && <SegSinCotis />
                    }
                </div>
            </div>
        </>
    )
}
