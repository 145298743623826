import premedic from "../Assets/img/logos/cias/logo_premedic_b.png"
import omint from "../Assets/img/logos/cias/logo_omint_b.png"
import swissMedical from "../Assets/img/logos/cias/logo_swiss_medical_medicina_b.png"
import galeno from "../Assets/img/logos/cias/logo_galeno_b.svg"
import prevencionSalud from "../Assets/img/logos/cias/logo_prevencionsalud_b.png"


export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "premedic",
        imgRuta: premedic,
        mostrar: true
    },
    {
        nombreCompania: "omint",
        imgRuta: omint,
        mostrar: true
    },
    {
        nombreCompania: "prevencion salud",
        imgRuta: prevencionSalud,
        mostrar: true
    },
    {
        nombreCompania: "galeno",
        imgRuta: galeno,
        mostrar: true
    },
    {
        nombreCompania: "swiss medical",
        imgRuta: swissMedical,
        mostrar: false
    },
]