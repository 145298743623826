import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IPasos } from '../../../Pages/AP/Cotizador';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SegSelect, SegSelectOption } from '../../SegUI/SegSelect/SegSelect';
import { SegButton } from '../../SegUI/SegButton/SegButton';
import { obtenerProfesiones } from '../../../Services/API/Profesiones';
import { SegInput } from '../../SegUI/SegInput/SegInput';
import { SegTooltip } from '../../SegUI/SegTooltip/SegTooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { useCotizacionStore } from '../../../Services/Providers/CotizacionStore';


interface IDatosAsegurado {
    profesion: string;
    cantidadAsegurados: string;
    actividadDedicada: string;
}

interface DatosAseguradoProps {
    setSteps: Dispatch<SetStateAction<IPasos>>
}

export const DatosAsegurado = ({ setSteps, ...rest }: DatosAseguradoProps) => {

    const nav = useNavigate();
    const { compania } = useParams();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
    const [profesionesIsLoading, setProfesionesIsLoading] = useState<boolean>(true);
    const [profesiones, setProfesiones] = useState<SegSelectOption[]>([]);
    const [profesion, setProfesion] = useState<SegSelectOption>();
    const [profesionIsOpen, setProfesionIsOpen] = useState<boolean>(false);

    const methods = useForm<IDatosAsegurado>();
    const {
        handleSubmit,
        setValue,
        trigger,
        control,
        formState: { errors, isValid },
    } = useForm<IDatosAsegurado>();


    useEffect(() => {
        if (cotizacionValues.cantidadDeVidas) {
            setValue("cantidadAsegurados", cotizacionValues.cantidadDeVidas.toString());
            trigger()
        }
        getProfesiones()
    }, []);


    const getProfesiones = async () => {
        const resp: SegSelectOption[] = await obtenerProfesiones()
        setProfesiones([
            {
                label: "No es ninguna de estas",
                value: "OtraActividad"
            },
            ...resp
        ])
        setProfesionesIsLoading(false)
        setCotizacionValues({
            ...cotizacionValues,
            profesiones: resp
        })

        if (cotizacionValues.profesion) {
            setProfesion(resp.find(i => i.value === cotizacionValues.profesion?.value))
        } else {
            setProfesionIsOpen(true)
        }
        if (cotizacionValues.actividadDedicada) {
            setProfesion({
                label: "No es ninguna de estas",
                value: "OtraActividad"
            })
            setValue("actividadDedicada", cotizacionValues.actividadDedicada);
        }

    }

    const handleChangeProfesion = (e: any) => {
        setProfesion(e)
    }

    const onSubmit: SubmitHandler<IDatosAsegurado> = (values: IDatosAsegurado) => {
        const { cantidadAsegurados, actividadDedicada } = values;
        setCotizacionValues({
            ...cotizacionValues,
            cantidadDeVidas: parseInt(cantidadAsegurados),
            profesion,
            actividadDedicada: profesion?.value === "OtraActividad" ? actividadDedicada : null
        });
        compania ? nav("/ap/cotizacion/" + compania + "/datos-cobertura") : nav("/ap/cotizacion/datos-cobertura");
    }

    return (
        <div className="mt-4 bg-gray-light" {...rest}>
            <div className="frm01">
                <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
                    <div className="cod-postal-column justify-content-center gap-4 mb-4">
                        <p className='text-primary fw-bold fs-4 mb-0 d-flex justify-center align-items-center'>
                            {"Datos del asegurado"}
                            <SegTooltip text="Persona que estará protegida por el seguro." />
                        </p>
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">¿Cúal es la profesión?</legend>
                                <FormProvider {...methods}>
                                    <SegSelect

                                        selected={profesion}
                                        setSelected={handleChangeProfesion}
                                        name="profesion"
                                        options={profesiones}
                                        width={"100%"}
                                        loading={profesionesIsLoading}
                                        borderColor={errors.profesion ? "red" : ""}
                                        openDefault={profesionIsOpen}
                                    />
                                </FormProvider>
                            </fieldset>
                        </div>
                    </div>

                    {profesion?.value === "OtraActividad" &&
                        < div className="cod-postal-column justify-content-center gap-4 mb-4">
                            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                                <fieldset className="">
                                    <legend className="mb-1">Escribinos la actividad a la que te dedicas</legend>
                                    <Controller
                                        name="actividadDedicada"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) => !!value || "Ingresá la actividad",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                min={1}
                                                className="w-full mt-3"
                                                label=""
                                                name="actividadDedicada"
                                                type="text"
                                                width={100}
                                                placeHolder=""
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("actividadDedicada", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("actividadDedicada");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${errors.actividadDedicada
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${errors.actividadDedicada
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                </fieldset>
                            </div>
                        </div>
                    }
                    <div className="cod-postal-column justify-content-center gap-4">
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">¿Cúantas personas asegurás?</legend>
                                <Controller
                                    name="cantidadAsegurados"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá la cantidad de asegurados",
                                            maxValue: (value) => parseInt(value) <= 10 || "El valor máximo permitido es 10",
                                            minValue: (value) => parseInt(value) > 0 || "Debe haber al menos un asegurado",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            className="w-full mt-3"
                                            label=""
                                            name="cantidadAsegurados"
                                            type="number"
                                            width={100}
                                            placeHolder="Ej: 1"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("cantidadAsegurados", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("cantidadAsegurados");
                                            }}
                                            value={field.value}
                                            autoFocus={false}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${errors.cantidadAsegurados
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${errors.cantidadAsegurados
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </fieldset>
                        </div>

                    </div>
                    <div className="form-buttons text-center">
                        <SegButton
                            label="Continuar"
                            type="submit"
                            disabled={!isValid || !profesion}
                            className="mb-4"
                        />
                    </div>
                </form>
            </div >
        </div >
    )
}
