import nowAssistance from "../Assets/img/logos/cias/logo_nowassistance.svg"


export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "Now",
        imgRuta: nowAssistance,
        mostrar: true
    },
]