import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Companias } from '../../../Assets/imageCompania';
import '@splidejs/splide/dist/css/splide.min.css';

interface Props {
    companias: Companias[];
}

export const CompaniasSlider = ({ companias }: Props) => {
    const companiasHabilitadas = companias.filter((compania) => compania.mostrar === true)
    if (((companiasHabilitadas.length < 5) && (window.innerWidth > 778)) || ((companiasHabilitadas.length < 3) && (window.innerWidth < 778))) {
        return (
            <div className="container">
                <div className='w-100 d-flex justify-content-center align-items-center' style={{ gap: "10%" }}>
                    {
                        companias.filter(compania => compania.mostrar).map((comp, index) => (
                            <img className="img-fluid" style={{ height: "auto", maxWidth: "200px", maxHeight: "50px" }} src={comp.imgRuta} alt={comp.nombreCompania} key={index}></img>
                        ))
                    }
                </div>
            </div>
        )
    }
    return (
        <div className="container">
            <Splide
                options={{
                    type: 'loop',
                    drag: 'free',
                    snap: true,
                    perMove: 1,
                    autoplay: true,
                    interval: 2000,
                    arrows: false,
                    pagination: false,
                    mediaQuery: 'min',
                    perPage: 2,
                    autoWidth: true,
                    gap: 60,
                    breakpoints: {
                        576: {
                            perPage: 2,
                        },
                        768: {
                            perPage: 4,
                        },
                        992: {
                            perPage: 5,
                        },
                        1200: {
                            perPage: 6,
                        },
                    }
                }}
            >
                {companias.filter(compania => compania.mostrar)
                    .map((compania, index) => (
                        <SplideSlide key={index}>
                            <img className="img-fluid logo-aseguradora" src={compania.imgRuta} alt={compania.nombreCompania}></img>
                        </SplideSlide>
                    ))}

            </Splide>
        </div>
    )
}
