import React, { useEffect, useState } from 'react'
import { SegButton } from '../../SegUI/SegButton/SegButton'
import { useNavigate, useParams } from 'react-router-dom';
import { useCotizacionStore } from '../../../Services/Providers/CotizacionStore';
import { SegSelect, SegSelectOption } from '../../SegUI/SegSelect/SegSelect';
import { FormProvider, useForm } from 'react-hook-form';
import styled from '@emotion/styled';


const tiposPersonas = [
    { value: 'Fisica', label: 'Física' },
    { value: 'PersonaJuridica', label: 'Jurídica' }
]

const StyledDiv = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;

export const TipoPersona = () => {
    const nav = useNavigate();
    const { compania } = useParams();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    const methods = useForm<any>();
    const [tipoPersona, setTipoPersona] = useState<SegSelectOption>();
    const [tipoPersonaIsOPen, setTipoPersonaIsOpen] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false);

    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (cotizacionValues.tipoPersona) {
            setTipoPersonaIsOpen(false);
            setTipoPersona(tiposPersonas.find(t => t.value === cotizacionValues.tipoPersona))
        }
    }, [])

    const onSubmit = () => {
        if (!tipoPersona)
            return setError(true);

        setError(false);
        setCotizacionValues({
            ...cotizacionValues,
            tipoPersona: tipoPersona!.value
        });

        //TODO: Definir flujo para Corporativo
        if (compania) {
            nav("/ap/individuo/cotizacion/" + compania + "/nombre-y-apellido")
        }
        else {
            nav("/ap/individuo/cotizacion/nombre-y-apellido")
        }
    }

    return (
        <div className="mt-4 bg-gray-light text-center" >
            <div className="frm01 ps-3 pe-3">
                <legend>¿Tipo de persona?</legend>
                <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
                    <div className="cod-postal-column justify-content-center gap-4">
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <FormProvider {...methods}>
                                    <SegSelect
                                        selected={tipoPersona}
                                        setSelected={setTipoPersona}
                                        name="tipoPersona"
                                        options={tiposPersonas}
                                        isGrupable
                                        width={"100%"}
                                        borderColor={errors.tipoPersona ? "red" : ""}
                                        autoFoco={true}
                                        placeholder=""
                                        className="text-start"
                                        openDefault={tipoPersonaIsOPen}
                                    />
                                </FormProvider>
                                {
                                    error &&
                                    <StyledDiv>
                                        Seleccioná un tipo de persona
                                    </StyledDiv>
                                }
                            </fieldset>
                        </div>
                    </div>
                    <div className="form-buttons">
                        <SegButton
                            label="Continuar"
                            type="submit"
                            className="btn btn-primary"
                            disabled={error}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
