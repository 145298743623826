import hero from "../../../Assets/img/bg-hero-pymes-desktop.png";
import figura10 from "../../../Assets/img/figura10.svg";
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg';
import Asesoramiento from '../Components/Asesoramiento';
import { TestimoniosSlider } from '../Components/TestimoniosSlider';
import { Header as NavBar } from '../Components/Header';
import { pymes_coberturas, testimonios_clientes } from "../diccionario";
import PropuestaValor from "../Components/PropuestaValor";
import { useRef } from "react";
import useSEOConfig from "../../../hooks/seo/useSEOConfig";

export const Pymes = () => {
    const targetRef = useRef<HTMLDivElement>(null);

    const handleScrollToElement = () => {
        const isMobile = window.innerWidth <= 768;

        targetRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: isMobile ? 'start' : 'center',
        });
    };

    useSEOConfig(
        {
            title: "Seguros para Empresas & Pymes - Protegé tu inversión ante todo riesgo",
            description: "Conocé la amplia variedad de seguros que tenemos para proteger tu negocio · ART · Seguro Integral de Comercio · Seguro de Vida Colectivo · Caución · Todo Riesgo Operativo · Agro · Industrias ",
            keywords: "Seguros de Vida Colectivos, Garantía de Caución, Seguro Integral de Comercio, Seguros para empresas, Seguros para comercios, Contratar ART online, Cotizar Seguro de caución, Empresas de asistencia al viajero, Seguros Integral de Consorcio, Seguros para locales comerciales, Seguros para Pymes",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros Corporativos a medida de tu empresa. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
            twitterDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros Corporativos a medida de tu empresa. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!"
        }
    )

    return (
        <>
            <NavBar rubro='auto' />
            <main className="bg-white">
                <SegSvg />
                <div className="overflow-hidden pb-lg-20">
                    <div className="bg-primary">
                        <div className="container-lg pt-40 pb-280 px-20 px-lg-12 position-relative bg-hero-pymes z-1">
                            <div className="d-flex flex-column flex-lg-row">

                                <div className="col-10 col-sm-8 col-lg-9 col-xl-7">
                                    <h2 className="ff-mukta fw-bold fs-30 fs-lg-50 lh-11 text-white mb-25 mb-lg-32">Buscamos tu tranquilidad y la seguridad de tu negocio.</h2>

                                    <p className="fs-16 fs-lg-20 lh-12 text-white mb-22 mb-lg-32">Te acompañamos en el crecimiento de tu PyME.<br />
                                        <br className="d-lg-none" />Contratá seguros a medida y protegé tu inversión de todo riesgo.</p>
                                    <button onClick={handleScrollToElement} className="transition-base d-inline-block w-180p w-lg-235p py-10 py-lg-3 rounded-2 fs-15 fs-lg-22 fw-bold lh-1 text-center bg-white text-primary" style={{ border: "none" }}>
                                        Asegurá tu PyME
                                    </button>
                                </div>

                                <div className="d-none d-lg-block fig-position-individuos">
                                    <img className="img-fluid" src={hero} alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-40 py-lg-70">
                    <div className="container px-25 px-lg-12">

                        <div className="row">
                            <div className="d-none d-lg-block col-lg-5">
                                <img className="img-fluid" src={figura10} alt="" />
                            </div>
                            <div className="col-lg-6 ms-auto">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center text-lg-start mb-35 mb-lg-50">¿Por qué elegirnos?</h2>
                                <ul className="list-unstyled fs-16 fs-lg-20 lh-14">
                                    <li className="bullet-circle-primary ps-4 mb-25"><b>Brindamos asesoramiento integral de coberturas</b> y soluciones ajustadas a las necesidades de protección de la actividad económica de comercios, PyMES e industrias.</li>
                                    <li className="bullet-circle-primary ps-4 mb-25"><b>Ofrecemos servicios</b> durante y post contratación, solicitud, emisión y control de pólizas, cobranzas y atención, asesoramiento y seguimiento de siniestros y análisis de PML.</li>
                                    <li className="bullet-circle-primary ps-4 mb-0"><b>Nuestra trayectoria y experiencia en el mercado</b> asegurador nos posiciona como un socio estratégico, preparado para brindar una solución integral.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <PropuestaValor/>

                <div className="pt-50 pb-30 pt-lg-40 pb-lg-0" ref={targetRef} id="coberturas" >
                    <div className="container" >
                        <div className="col-10 mx-auto" >
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-30 mb-lg-35">Coberturas para todos los riesgos</h2>
                        </div>
                        <div className="d-flex flex-wrap column-gap-md-3 row-gap-30p justify-content-center mx-auto text-center" >
                            {
                                pymes_coberturas.map(item => (
                                    <a key={item.label} className="item-seguro-alt" href={item.link}>
                                        <span className="icon-seguros-alt-wrapper">
                                            <svg className="svg-icon icon-seguros-alt"><use xlinkHref={item.icon}></use></svg>
                                        </span>
                                        <span className="d-block fs-13 lh-1 fw-bold text-black">{item.label}</span>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <Asesoramiento background="white" />

                <div className="bg-f3 py-45" style={{ paddingBottom: "68px" }}>
                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-30 mb-lg-40 text-center">Nuestros <br className="d-md-none" /> clientes dicen...</h2>

                        <div className="row mb-50 align-items-center">
                            <div className="col-md-4">
                                <p className="d-flex flex-column flex-xxl-row align-items-center mb-22 text-center text-xxl-start">
                                    <span className="fs-52 fw-bold text-primary lh-1 d-inline-flex align-items-center me-xl-3">85.4%</span>
                                    <span className="fs-18 text-black lh-11">Están <b>muy satisfechos</b><br /> con el servicio recibido</span>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="d-flex flex-column flex-xxl-row align-items-center mb-22 text-center text-xxl-start">
                                    <span className="fs-52 fw-bold text-primary lh-1 d-inline-flex align-items-center me-xl-3">8 <span className="w-40p ms-2 me-1 fs-15 fw-bold text-start">de cada</span> 10</span>
                                    <span className="fs-18 text-black lh-11"><b>Recomendarían</b><br /> Segurarse</span>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="d-flex flex-column flex-xxl-row align-items-center mb-22 text-center text-xxl-start">
                                    <span className="fs-52 fw-bold text-primary lh-1 d-inline-flex align-items-center me-xl-3">9 <span className="w-40p ms-2 me-1 fs-15 fw-bold text-start">de cada</span> 10</span>
                                    <span className="fs-18 text-black lh-11"><span>Reconocen el <b>profesionalismo</b><br /> del ejecutivo a cargo</span>
                                    </span></p>
                            </div>
                        </div>

                        <TestimoniosSlider testimonios={testimonios_clientes} />
                    </div>
                </div>
            </main>
        </>
    )
}