import React, { useEffect, useState } from 'react'
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer'
import { SegButton } from '../../../SegUI/SegButton/SegButton'
import { SegInput } from '../../../SegUI/SegInput/SegInput'
import { useNavigate } from 'react-router-dom'
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore'
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { updateEmisionStep } from '../../../../Services/API/updateEmisionStep'

const StyledLink = styled(Link)`
    padding: 10px;
    padding-left:25px;
    padding-right:25px;
    width: 100%;
    color: #2C7DFD;
    background-color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    border: 0;
    border-radius: 5px;
`

interface IRadicacionVehiculo {
    localidad: string;
    calle: string;
    altura: string;
    piso: string;
    departamento: string
    calleSinNum: boolean;
}

export const RadicacionVehiculo = () => {
    const nav = useNavigate();
    const { emisionValues, setEmisionValues } = useEmisionStore();
    const { cotizacionValues } = useCotizacionStore();

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [initialValidationDone, setInitialValidationDone] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const { handleSubmit, control, setValue, formState, trigger } = useForm<IRadicacionVehiculo>();
    // const methods = useForm<IRadicacionVehiculo>();

    const onSubmit: SubmitHandler<IRadicacionVehiculo> = async (values: IRadicacionVehiculo) => {
        const { localidad, calle, altura, departamento, piso, calleSinNum } = values;
        setEmisionValues({
            ...emisionValues,
            localidad: localidad,
            calle: calle,
            altura: altura,
            departamento: departamento,
            piso: piso,
            calleSinNum: calleSinNum,
        });

        setIsLoading(true)
        await updateEmisionStep(2, {
            idItemCotizacion: emisionValues.cotizacionDesc!.id,
            Nombre: emisionValues.nombre,
            Apellido: emisionValues.apellido,
            Email: emisionValues.email,
            NroDocumento: emisionValues.numDoc,
            Telefono: {
                CodigoArea: emisionValues.celular?.cod,
                Numero: emisionValues.celular?.numero,
                TelefonoEsCelular: true
            },
            Cuil: emisionValues.cuil,
            FechaNacimiento: `${emisionValues.fechaNac?.dia}/${emisionValues.fechaNac?.mes}/${emisionValues.fechaNac?.anio}`,
            TelefonoAdicional: {
                CodigoArea: emisionValues.telAdicional?.cod,
                Numero: emisionValues.telAdicional?.numero,
                TelefonoEsCelular: true
            },
            Localidad: localidad,
            Calle: calle,
            NumeroCalle: altura,
            Piso: piso,
            Departamento: departamento,
        } as any)

        setIsLoading(false);
        nav("/auto/emision/datosauto");
    }

    useEffect(() => {
        const calleInput = document.getElementById("calle");
        if (calleInput && calleInput.tagName.toLowerCase() === "input") {
            calleInput.focus();
        }
        setInitialValidationDone(true)
    }, []);

    useEffect(() => {
        emisionValues.localidad ? setValue("localidad", emisionValues.localidad) : cotizacionValues.localidad?.value ? setValue("localidad", cotizacionValues.localidad.value) : setValue("localidad", "")
        emisionValues.calle ? setValue("calle", emisionValues.calle) : setValue("calle", "")
        emisionValues.calle && trigger("calle")
        emisionValues.altura ? setValue("altura", emisionValues.altura) : setValue("altura", "")
        emisionValues.altura && trigger("altura")
        emisionValues.piso ? setValue("piso", emisionValues.piso) : setValue("piso", "")
        emisionValues.piso && trigger("piso")
        emisionValues.departamento ? setValue("departamento", emisionValues.departamento) : setValue("departamento", "")
        emisionValues.departamento && trigger("departamento")
        setIsChecked(emisionValues.calleSinNum ? true : false)
    }, [])

    useEffect(() => {
        setValue("calleSinNum", isChecked);
        if (initialValidationDone)
            trigger("altura");
    }, [isChecked]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    return (
        <div>
            <div className="px-20 pb-40 pb-lg-60 maxw-1170p mx-auto">
                <div className="d-lg-none">
                    <StyledLink to="" data-bs-target="#offcanvas" data-bs-toggle="collapse" className='btn1-md d-block fs-15 maxw-md-475p mx-md-auto' >Ver detalle de cobertura</StyledLink>
                </div>
                <div className="d-none d-lg-block">
                    <SegCoberturaInfo emisionValues={emisionValues} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SegFormEmisionContainer title='Dirección donde duerme el auto'>
                        <div className="col-md-4">
                            <SegInput label='Localidad' name='localidad' type='text' width={100} value={cotizacionValues.localidad?.value} disabled />
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="calle"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá la calle",
                                        pattern: (value) =>
                                            /^[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Calle inválida",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        id='calle'
                                        label="Calle"
                                        name="calle"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: Avenida Libertador"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("calle", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("calle");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.calle
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.calle
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                            {/* <SegInput label='Calle' name='calle' type='text' width={100} /> */}
                        </div>
                        <div className="col-md-4">
                            <div className='d-flex align-items-start gap-1 justify-content-between'>
                                <div className='col-md-3'>
                                    <Controller
                                        name="altura"
                                        control={control}
                                        rules={{
                                            validate: {
                                                isNumeric: (value) => value === '' || (!isNaN(Number(value)) && parseInt(value) > 0) || "El Nº de Calle debe ser un número mayor a 0",
                                                required: (value) => {
                                                    if (!isChecked) {
                                                        return !!value || "El Nº es obligatorio";
                                                    } else {
                                                        return true
                                                    }
                                                }
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="N°"
                                                name="altura"
                                                type="number"
                                                width={100}
                                                placeHolder="Ej: 125"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("altura", e.target.value);
                                                    trigger("altura");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.altura ? "var(--bs-form-invalid-border-color)" : ""}`}
                                                focusBorderColor={`${formState.errors.altura ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}`}
                                                min={"1"}
                                            />
                                        )}
                                    />
                                    <div style={{ display: "flex", alignItems: "baseline", position: "absolute" }}>
                                        <input
                                            className='checkbox-container'
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="label-color">Sin número</label>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <Controller
                                        name="piso"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="Piso"
                                                name="piso"
                                                type="text"
                                                width={100}
                                                placeHolder="Ej: PB, 1, ..."
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("piso", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("piso");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.piso
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.piso
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-5'>
                                    <Controller
                                        name="departamento"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="Depto."
                                                name="departamento"
                                                type="text"
                                                width={100}
                                                placeHolder="Ej: D"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("departamento", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("departamento");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.departamento
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.departamento
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                    {/* <SegInput label='Piso' name='piso' type='number' width={100} /> */}
                                </div>
                            </div>
                        </div>
                        <div className={`d-flex align-items-end ${window.innerWidth < 768 ? 'mt-5 justify-content-center' : 'mt-3 justify-content-end'}`}>
                            <div style={window.innerWidth < 768 ? { width: "100%" } : {}}>
                                <SegButton
                                    label='Continuar'
                                    type='submit'
                                    disabled={!formState.isValid}
                                    style={window.innerWidth < 768 ? { width: "100%" } : {}} className={window.innerWidth < 768 ? '' : 'fs-15'}
                                    loading={isLoading}
                                />
                                <div className="text-center" style={{ paddingTop: "3px" }}>
                                    <a href={encodeURI("https://wa.me/5491169568002?text=Hola, necesito ayuda con la emisión de mi seguro " + (emisionValues?.cotizacionDesc?.planName! || null) + " de la aseguradora " + (emisionValues?.cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer' className="fs-14 fw-medium fs-md-17 a-dark">
                                        <span className="hover-border-underline">Continuar por WhatsApp</span>
                                        <svg className="icon" style={{ width: 20, height: 20, marginLeft: 5, verticalAlign: "middle" }}><use xlinkHref="#whatsapp"></use></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </SegFormEmisionContainer>
                </form>
            </div>
        </div >
    )
}
