import { useEffect, useState } from 'react'
import { SegHeaderLogo } from '../../../SegUI/SegHeaderLogo/SegHeaderLogo'
import segurarse from "../../../../Assets/img/logo_segurarse.svg";
import { SegStepper } from '../../../SegUI/SegStepper/SegStepper';
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate, useParams } from 'react-router-dom';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import descargarPoliza from '../../../../Services/API/DescargarPoliza';

export const SolicitudEnviada = () => {

    const { emisionValues, resetEmisionValues } = useEmisionStore();
    const { resetCotizacionValues } = useCotizacionStore();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [esperando, setEsperando] = useState<boolean>(false);
    const nav = useNavigate()
    const { compania } = useParams();

    useEffect(() => {
        const handleBackNavigation = () => {
            resetCotizacionValues()
            resetEmisionValues()
            nav('/cotizador-de-seguros-ap', { replace: true })
            window.removeEventListener('popstate', handleBackNavigation)
        }
        window.addEventListener('popstate', handleBackNavigation)
    }, [nav]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDownload = async () => {
        try {
            setEsperando(true)
            const response = await descargarPoliza(emisionValues.idPoliza!);
            if (response.api_status) {
                if (response.api_status === 200) {
                    if (response.api_data.poliza) {
                        const link = document.createElement('a');
                        link.href = `data:application/pdf;base64,${response.api_data.poliza}`;
                        link.download = 'Certificado de Circulación Provisorio';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        setEsperando(false);
                    }
                } else {
                    setEsperando(false);
                    console.log(response.api_status)
                }
            } else {
                setEsperando(false);
                console.log("Error al comunicar con API")
            }
        } catch (error) {
            setEsperando(false);
            console.error("Error occurred while uploading the file:", error);
        }

    };
    return (
        <>
            <div className="container">
                <div className="row mt-3 mb-3">
                    <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                        <SegHeaderLogo img={segurarse} alt="Logo Segurarse" width="160px" />
                    </div>
                </div>
            </div>
            <div>
                <SegStepper
                    label={"¡Estamos muy felices de que utilices nuestros servicios!"}
                    isStepper={false}
                    className="fs-20 fw-bold bg-primary text-center px-3 pt-1 pb-1 mb-m13 minh-90p pt-lg-35 pb-lg-30 mb-lg-m30 minh-lg-125p"
                />
            </div>
            <div className='container bg-white rounded pt-5 fs-lg-20 p-5 container-constancia' style={{ width: "90%", justifyContent: "flex-end" }}>
                <div>
                    <p className='text-left fs-18'>
                        Ya emitimos tu cobertura de <b>Accidentes Personales</b> de la Aseguradora
                        <b>{` ${emisionValues.cotizacionDescAP.nombreCompania}`}</b>.
                    </p>
                    <div className='fs-18' style={{ marginBottom: "20px" }}>
                        <p>Descargá aquí la documentación de tu póliza. </p>
                        <div style={{ display: "flex" }}>
                            {/*<a style={{ paddingRight: "5px" }} href={`data:application/pdf;base64,${linkCertificado}`} download={"Certificado de Circulación Provisorio"}><li>Póliza electrónica</li></a>
                            <div>
                                <SimCardDownloadIcon style={{ color: "#2C7DFD" }} />
                            </div>*/}
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    //backgroundColor: 'transparent',
                                    border: "none",
                                    padding: "4px 12px",
                                    borderRadius: '12px',
                                    cursor: 'pointer',
                                    color: "#2C7DFD"
                                }}
                                onClick={handleDownload}
                                disabled={esperando}
                            >
                                <span style={{ paddingRight: '5px' }}>Póliza electrónica</span>
                                {esperando ?
                                    <div className="spinner-border spinner-border-sm text-primary ml-4" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    :
                                    <SimCardDownloadIcon style={{ color: '#2C7DFD' }} />
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <p className='text-left fs-18'>
                    Cualquier duda podés contactarnos a empresas@segurarse.com.ar / WhatsApp: +54 9 11 6956-8002 en nuestros horarios de atención: lunes a viernes de 9hs a 18hs.
                </p>
                <p className='text-left fs-18' style={{ fontStyle: "italic" }}>
                    La protección de tus datos es nuestra prioridad, nunca te solicitaremos el código de seguridad de las tarjetas de crédito
                    por ningún motivo.
                </p>
            </div>
        </>
    )
}
