import { IEmisionValues } from '../../Services/Providers/EmisionStore'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom';


interface SegCoberturaInfoProps {
    emisionValues: IEmisionValues
    tipo?: "AUTO" | "ACCIDENTES PERSONALES"
}

export const SegCoberturaInfo = ({ emisionValues, tipo = "AUTO" }: SegCoberturaInfoProps) => {
    const isEmisionEmpty = JSON.stringify(emisionValues) == "{}"
    const nav = useNavigate();

    const location = useLocation();

    const isRevisionRoute = location.pathname === "/auto/emision/revision";

    const StyledLink = styled.a`
    padding: 10px;
    padding-left:25px;
    padding-right:25px;
    width: 100%;
    color: #2C7DFD;
    background-color: #f8f8f8;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    border: 0;
    border-radius: 5px;
`

    if (isEmisionEmpty) {
        nav("/error")
    }

    return (
        // <div className="bg-white py-lg-13 mb-25 mb-lg-20 rounded-5p">
        <div className={isRevisionRoute ? "bg-gray py-lg-13 mb-25 mb-lg-20 rounded-5p" : "bg-white mb-2 rounded-5p"}>

            <div className="d-flex p-0 flex-column flex-lg-row align-items-stretch align-items-lg-center justify-content-center text-center gap-2 gap-lg-0 py-2">
                {/* <span className="me-lg-3 fs-16 fw-bold text-primary border-bottom border-2 border-e0e0e0 border-lg-0">Cobertura <span className="d-none d-lg-inline-block fs-20 fw-bold text-gray-dark">&gt;</span></span> */}
                <span className="me-lg-3 fs-16 fw-bold text-primary d-lg-inline-block d-none">Cobertura <span className="d-none d-lg-inline-block fs-20 fw-bold text-gray-dark">&gt;</span></span>
                {tipo === "AUTO" &&
                    <>
                        <span className="me-lg-4 fs-16 fw-bold text-gray-aux">{emisionValues.cotizacionDesc?.planName}</span>
                        <div>
                            <img className="mb-1 mb-lg-0 me-lg-3 height-30" src={emisionValues.cotizacionDesc?.companyLogo} alt={emisionValues.cotizacionDesc?.companyName} loading='lazy' />
                        </div>
                        <div className="mb-2 mb-lg-0 me-lg-4 fs-32 fw-bold lh-1">{"$" + emisionValues.cotizacionDesc?.amountMonth}<span className="fs-14 fw-bold text-gray-dark d-inline-block pt-0 align-top">/mes</span></div>
                    </>
                }
                {tipo === "ACCIDENTES PERSONALES" &&
                    <>
                        <span className="d-none d-lg-block me-lg-4 fs-16 fw-bold text-gray-aux">{emisionValues.cotizacionDescAP?.planName}</span>
                        <div className='d-none d-lg-block'>
                            <img className="mb-1 mb-lg-0 me-lg-3"
                                style={{ maxWidth: "160px" }}
                                src={emisionValues.cotizacionDescAP?.companyLogo} alt={emisionValues.cotizacionDescAP?.companyName} loading='lazy' />
                        </div>
                        <div className='d-none d-lg-block'>
                            <div className=" mb-2 mb-lg-0 me-lg-4 fs-32 fw-bold lh-1">{"$" + emisionValues.cotizacionDescAP?.amountMonth}<span className=" fs-14 fw-bold text-gray-dark d-inline-block pt-0 align-top">/mes</span></div>
                        </div>
                    </>
                }
                <div className='px-2'>
                    <StyledLink data-bs-toggle="offcanvas" href={"#offcanvas"} role="button" className='btn1-md d-block maxw-md-475p mx-md-auto fw-bold fs-16' >Ver detalle de cobertura</StyledLink>
                </div>
            </div>
        </div>
    )
}
