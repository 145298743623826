import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imagenesCompaniasHogar'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { CompaniasSlider } from '../Components/CompaniasSlider'
import FormularioIndividuo from '../Components/FormularioIndividuo'
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const Hogar = () => {

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    const preguntasFrecuentes = [
        {
            titulo: '¿Qué coberturas incluye un seguro de hogar?',
            subTitulo: 'Generalmente, un seguro de hogar incluye cobertura por daños por incendio, robo y vandalismo, daños por agua y filtraciones, fenómenos naturales (como tormentas, granizo, o terremotos), rotura de cristales, responsabilidad civil frente a terceros, y asistencia en el hogar para emergencias.'
        },
        {
            titulo: '¿Es obligatorio contratar un seguro de hogar?',
            subTitulo: 'No, no es obligatorio tener un seguro de hogar en la mayoría de los casos, aunque algunos bancos pueden exigirlo como condición para otorgar una hipoteca. Sin embargo, tener un seguro es altamente recomendable para proteger tu vivienda y pertenencias de posibles pérdidas.'
        },
        {
            titulo: '¿Puedo asegurar una vivienda alquilada?',
            subTitulo: 'Sí, tanto inquilinos como propietarios pueden contratar un seguro de hogar. Si sos inquilino, el seguro puede cubrir tus pertenencias personales y la responsabilidad civil frente a terceros. El propietario puede contratar una póliza para proteger la estructura de la vivienda.'
        },
        {
            titulo: '¿Qué es la responsabilidad civil en un seguro de hogar?',
            subTitulo: 'La responsabilidad civil cubre los daños personales o materiales que puedan sufrir terceros dentro de tu propiedad o por situaciones relacionadas con ella (por ejemplo, una caída de un visitante). Cubre gastos legales, indemnizaciones y otros costos asociados.'
        }
    ];

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Hogar'
                    subTitulo={{ __html: `Conocemos la importancia de tu hogar para vos y tu familia, entendemos los riesgos a los que se expone tu vivienda, por eso, te acercamos los mejores planes para proteger tu inversión. <br /><br />¿Empezamos?` }}
                    url='#formulario'
                    icon="#icon-seg-hogar"
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Un seguro de hogar ofrece una amplia gama de coberturas diseñadas para proteger tu
                                vivienda y tus pertenencias frente a diversos riesgos. Las coberturas que suelen incluir los
                                planes son:
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños por incendio:</b> cobertura de los daños materiales ocasionados por incendios
                                            dentro del hogar, ya sea que afecten la estructura de la vivienda (techos, paredes,
                                            pisos) o su contenido (muebles, electrodomésticos, ropa, etc.). Esta cobertura
                                            puede incluir los costos de reparación o reconstrucción y la reposición de los
                                            bienes dañados.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños por eventos climáticos y fenómenos naturales:</b> protege contra daños
                                            causados por fenómenos como tormentas, inundaciones, granizo, terremotos, o
                                            deslizamientos de tierra.

                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Robo y vandalismo:</b> cubre el robo de bienes dentro del hogar, así como los daños
                                            causados por actos de vandalismo o intento de robo. Dependiendo de la póliza,
                                            puede cubrir el costo de reemplazo de objetos robados, desde electrodomésticos y
                                            dispositivos electrónicos hasta joyas y dinero en efectivo.

                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños por agua y filtraciones:</b> protección contra daños causados por rotura de
                                            cañerías, filtraciones de agua, fugas, o fallas en el sistema de plomería.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil:</b> cubre los daños que puedan sufrir terceros dentro de tu
                                            hogar o causados por situaciones relacionadas con tu propiedad (como una caída o
                                            un accidente provocado por un objeto de tu vivienda). </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Rotura de cristales y cerraduras:</b> incluye la cobertura de gastos por roturas de
                                            vidrios, espejos, ventanas, y cerraduras, ya sea por accidente, intento de robo, o
                                            vandalismo.

                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Asistencia en el hogar: </b>algunas pólizas incluyen servicios de asistencia para
                                            reparaciones de urgencia, como servicios de cerrajería, plomería, electricidad, y
                                            vidriería, entre otros. Estos servicios suelen estar disponibles las 24 horas del día.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                            <b>¿Por qué es importante contratar un seguro de hogar? </b>
                            <br /><br />

                            Este seguro te va a proteger financieramente ante cosos de reparación o reemplazo de pertenencias ante daños o pérdidas. Te va a ofrecer tranquilidad ante imprevistos como incendios, robos, accidentes y vas a contar con servicios de asistencia ante emergencia como cañerías rotas o cerraduras dañadas.
                        </p>
                        <div className="col-10 col-md-8 col-xl-6 mx-auto text-center mt-3">
                            <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center text-white">
                                Si tenés dudas, contás con nuestro equipo de especialistas para asesorarte en todo momento.
                            </p>
                            <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                                <span className="fs-18 fs-lg-20 text-black fw-bold text-white">Chatéanos</span>
                                <svg className="svg-icon ms-2 w-12em">
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <FormularioIndividuo
                    rubro='Hogar'
                    titulo='Cotizá seguro de hogar'
                />
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
