import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SegSelect, SegSelectOption } from '../../../SegUI/SegSelect/SegSelect'
import { FormProvider, useForm } from 'react-hook-form';
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo';
import { SegCotizadoInfo } from '../../../SegCotizadoInfo/SegCotizadoInfo';
import { updateEmisionStep } from '../../../../Services/API/updateEmisionStep';

interface IRevisionDatos {
    tipoPersona: string;
    condicionIva: string;
    tipoUso: string;
    gnc: boolean
}



export const RevisionDatos = () => {
    const { emisionValues, setEmisionValues } = useEmisionStore();
    const { cotizacionValues } = useCotizacionStore();

    // const { compania } = useParams();
    const nav = useNavigate();

    // Estados del formulario
    const [tipoPersona, setTipoPersona] = useState<SegSelectOption>()
    const [condicionIva, setCondicionIva] = useState<SegSelectOption>()
    const [tipoUso, setTipoUso] = useState<SegSelectOption>()
    const [gnc, setGnc] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Estados de los desplegables abiertos o cerrados
    const [condicionIvaOpen, setCondicionIvaOpen] = useState<boolean>(false)
    const [tipoUsoOpen, setTipoUsoOpen] = useState<boolean>(false)

    const opcionesTipoPersona = [
        { value: "fisica", label: "Física" },
        { value: "juridica", label: "Jurídica" },
    ];

    const opcionesCondicionIva = [
        { value: "1", label: "Consumidor Final" },
        { value: "6", label: "Responsable Inscripto" },
        { value: "4", label: "Monotributista" },
        { value: "3", label: "Exento Jurídica" },
    ];

    const opcionesTipoUso = [
        { value: "1", label: "Particular" },
        { value: "2", label: "Comercial" },
    ];

    const {
        handleSubmit,
        formState: { errors },
    } = useForm<IRevisionDatos>();

    const methods = useForm<IRevisionDatos>();

    const onSubmit = async () => {

        setEmisionValues({
            ...emisionValues,
            tipoPersona: tipoPersona,
            condicionIva: condicionIva,
            tipoUso: tipoUso,
            gnc: gnc
        });

        await actualizarDatosEmision();

        // compania ? nav("/auto/emision/" + compania + "/datospersonales") :
        nav("/auto/emision/datospersonales");
    };

    const actualizarDatosEmision = async () => {
        setIsLoading(true);
        await updateEmisionStep(1, {
            idItemCotizacion: emisionValues.cotizacionDesc!.id,
            TipoDePersona: tipoPersona,
            CondicionIVA: condicionIva,
            TipoUso: tipoUso,
            TieneGNC: gnc
        } as any)

        setIsLoading(false);
    }

    useEffect(() => {
        if (emisionValues.tipoPersona) {
            setTipoPersona(emisionValues.tipoPersona)
        }
        if (emisionValues.condicionIva) {
            setCondicionIva(emisionValues.condicionIva)
        }
        if (emisionValues.tipoUso) {
            setTipoUso(emisionValues.tipoUso)
        }
        if (emisionValues.gnc) {
            setGnc(emisionValues.gnc)
        }
    }, [])


    const handleTipoPersonaChange = (e: any) => {
        setTipoPersona(e)
        setCondicionIvaOpen(true)
        setTipoUsoOpen(false)
    }
    const handleCondicionIvaChange = (e: any) => {
        setCondicionIva(e)
        setCondicionIvaOpen(false)
        setTipoUsoOpen(true)
    }

    const handleGncChange = (e: any) => {
        setGnc(e.target.checked)

    }
    return (
        <div className="px-20 pb-40 pb-lg-60 maxw-1170p mx-auto">
            <div className="bg-white py-lg-1 mb-2 mb-lg-0 rounded-5p">
                <div className="d-lg-none">
                    <div className="text-center fs-14 py-2"><SegCotizadoInfo emisionValues={emisionValues} /></div>
                </div>
                <div className="d-none d-lg-flex align-items-center justify-content-center">
                    <span className="me-3 fs-16 fw-bold text-primary">Vehículo cotizado <span className="fs-20 fw-bold text-gray-dark">&gt;</span></span>
                    <div className="fs-16 fw-bold text-gray-aux">{cotizacionValues.marca?.label + " " + cotizacionValues.modelo?.label + " " + cotizacionValues.año?.label} | {cotizacionValues.version?.label} | <span className="fw-normal">Zona de guardado:</span> {cotizacionValues.localidad?.label}</div>
                </div>
            </div>
            <SegCoberturaInfo emisionValues={emisionValues} />
            <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
                <div className="mx-md-auto maxw-md-475p">
                    <div className="container-fluid g-0">
                        <div className="row align-items-center row-gap-md-3 d-flex">
                            <div className="col-md-4 mb-1 mb-md-0">
                                <div className="fs-16 fw-bold text-primary">Tipo de persona</div>
                            </div>
                            <div className="col-md-8 mb-16 mb-md-0 d-flex align-items-center">
                                <FormProvider {...methods}>
                                    <SegSelect
                                        name='tipoPersona'
                                        options={opcionesTipoPersona}
                                        selected={tipoPersona}
                                        setSelected={handleTipoPersonaChange}
                                        width={"100%"}
                                        borderColor={errors.tipoPersona ? "red" : ""}
                                        className="w-100" />
                                </FormProvider>
                            </div>

                            <div className="col-md-4 mb-1 mb-md-0">
                                <div className="fs-16 fw-bold text-primary">Condición de IVA</div>
                            </div>
                            <div className="col-md-8 mb-16 mb-md-0 d-flex align-items-center">
                                <FormProvider {...methods}>
                                    <SegSelect
                                        name='condicionIva'
                                        options={opcionesCondicionIva}
                                        selected={condicionIva}
                                        setSelected={handleCondicionIvaChange}
                                        width={"100%"}
                                        borderColor={errors.condicionIva ? "red" : ""}
                                        openDefault={condicionIvaOpen}
                                        className="w-100" />
                                </FormProvider>
                            </div>
                            <div className="col-md-4 mb-1 mb-md-0">
                                <div className="fs-16 fw-bold text-primary">Tipo de uso</div>
                            </div>
                            <div className="col-md-8 mb-16 mb-md-0 d-flex align-items-center">
                                <FormProvider {...methods}>
                                    <SegSelect
                                        name='tipoUso'
                                        options={opcionesTipoUso}
                                        selected={tipoUso}
                                        setSelected={setTipoUso}
                                        width={"100%"}
                                        borderColor={errors.tipoUso ? "red" : ""}
                                        openDefault={tipoUsoOpen}
                                        className="w-100" />
                                </FormProvider>
                            </div>
                            <div className="col-auto col-md-4 mb-16">
                                <div className="fs-16 fw-bold text-primary">¿GNC?</div>
                            </div>
                            <div className="col col-md-8 d-flex justify-content-between align-items-center mb-16">
                                <div className='d-flex gap-1'>
                                    <span><b>No</b></span>
                                    <div className="form-check form-switch" style={{ paddingLeft: "1.5em" }}>

                                        <input className="form-check-input" type="checkbox" role="switch"
                                            id="flexSwitchCheckDefault" onChange={handleGncChange} checked={gnc}
                                            style={{ marginLeft: "-1.5em" }} />

                                    </div>
                                    <span><b>Si</b></span>
                                </div>
                                {tipoPersona?.value === "fisica" && condicionIva?.value === "1" && tipoUso?.value === "1" && gnc === false ?
                                    <SegButton
                                        label="Continuar"
                                        type="submit"
                                        disabled={tipoPersona?.value && condicionIva?.value && tipoUso?.value ? false : true}
                                        loading={isLoading}
                                        className='fs-15'
                                    /> :
                                    <button type="button" className="btn btn-primary fs-15" style={{ minWidth: "172px" }}
                                        onClick={actualizarDatosEmision} data-bs-toggle="modal" data-bs-target="#modal3" disabled={tipoPersona?.value && condicionIva?.
                                            value && tipoUso?.value ? false : true}>Continuar</button>
                                }
                            </div>

                        </div>
                        <div className="fs-12 fw-medium text-center mb-16 text-7a7a7a">Con la modificación de los datos ingresados previamente el precio de la cobertura puede modificarse.</div>

                        <div className="text-center">
                            <a href={encodeURI("https://wa.me/5491169568002?text=Hola, necesito ayuda con la emisión de mi seguro " + (emisionValues?.cotizacionDesc?.planName! || null) + " de la aseguradora " + (emisionValues?.cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'>
                                <span className="hover-border-underline">Continuar por WhatsApp</span>
                                <svg className="icon" style={{ width: 20, height: 20, marginLeft: 5 }}><use xlinkHref="#whatsapp"></use></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}