import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imageCompaniaCelular'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { PasosCotizar } from '../Components/PasosCotizar'
import { CompaniasSlider } from "../Components/CompaniasSlider"
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const Celular = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Qué cubre un seguro de celular?',
            subTitulo: 'Generalmente, un seguro de celular cubre robo, hurto, daños accidentales (como pantallas rotas o daños por líquidos), y fallas técnicas que no están cubiertas por la garantía del fabricante. Algunas pólizas también ofrecen cobertura para pérdida y asistencia técnica.'
        },
        {
            titulo: '¿Qué no cubre un seguro de celular?',
            subTitulo: 'Normalmente, el seguro de celular no cubre daños intencionales, pérdida por negligencia, desgaste natural del dispositivo, problemas preexistentes, ni accesorios como cargadores, auriculares o fundas, a menos que se especifique lo contrario en la póliza.'
        },
        {
            titulo: '¿Puedo contratar un seguro de celular en cualquier momento?',
            subTitulo: 'Sí, podés contratar un seguro de celular en cualquier momento, aunque algunas aseguradoras pueden requerir que el dispositivo sea nuevo o tenga una antigüedad limitada (generalmente entre uno y dos años). Algunas compañías también pueden requerir una inspección o comprobación del estado del dispositivo antes de emitir la póliza.'
        },
        {
            titulo: '¿Puedo asegurar un celular usado?',
            subTitulo: 'Sí, siempre y cuando cumplan con ciertos requisitos, como una antigüedad máxima (generalmente entre uno y dos años) y que se encuentren en buen estado. Es posible que la aseguradora solicite una inspección previa para verificar el estado del dispositivo.'
        }
    ];

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Celular'
                    subTitulo={{ __html: 'Protegemos tu dispositivo móvil para que nada te impida seguir conectado. Cotizá y acercate al mejor plan para vos.<br /><br />¿Empezamos?' }}
                    url='/celular/cotizacion'
                    icon='#icon-seg-celular'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Un seguro de celular ofrece diferentes coberturas diseñadas para proteger tu dispositivo ante una variedad de situaciones que te pueden tomar por sorpresa. Algunas de ellas son:
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Robo y hurto</b>: la mayoría de los seguros de celular cubren el robo/hurto del dispositivo. Dependiendo de la póliza, el seguro puede cubrir el valor total del celular o una parte de este.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños accidentales</b>: esta cobertura protege tu celular ante daños causados por accidentes, como caídas que resulten en pantallas rotas, golpes, o incluso daños causados por líquidos (como derrames o inmersión en agua).
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Cobertura internacional</b>: algunos seguros de celular ofrecen cobertura para daños o robos que ocurran fuera del país, brindando protección global si viajás con tu dispositivo. Esta cobertura puede tener restricciones, como límites en la cantidad de reclamos o el tipo de siniestro cubierto en el extranjero.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Asistencia técnica y soporte</b>: algunas aseguradoras ofrecen asistencia técnica adicional, como ayuda para configurar el celular, transferir datos, o resolver problemas técnicos menores. También pueden incluir servicios de recuperación de datos en caso de fallas del dispositivo.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                            <b>¿Por qué es importante tener un seguro de celular?</b>
                            <br /><br />
                            Tener un seguro de celular es importante porque protege tu inversión ante eventos inesperados que pueden resultar en un costo significativo para vos. Los celulares, especialmente los de alta gama, representan un valor económico considerable y su reparación o reemplazo puede ser muy costoso.
                        </p>
                    </div>
                    <div className="col-10 col-md-8 col-xl-6 mx-auto text-center mt-3">
                            <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center text-white">
                            Si tenés dudas, contás con nuestro equipo de especialistas para asesorarte en todo momento.
                            </p>
                        <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                                <span className="fs-18 fs-lg-20 text-black fw-bold text-white">Chatéanos</span>
                                <svg className="svg-icon ms-2 w-12em">
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                            </a>
                        </div>
                </div>
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
