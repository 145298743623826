import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imageCompaniasMoto'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { CompaniasSlider } from '../Components/CompaniasSlider'
import FormularioIndividuo from '../Components/FormularioIndividuo'
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const Moto = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Es obligatorio tener un seguro de moto?',
            subTitulo: 'Sí, es obligatorio contar con al menos un seguro de responsabilidad civil para poder circular legalmente.'
        },
        {
            titulo: '¿Qué diferencia hay entre un seguro de responsabilidad civil y una cobertura completa? ',
            subTitulo: 'El seguro de responsabilidad civil solo cubre los daños que puedas ocasionar a otras personas o a sus bienes en un accidente. En cambio, una cobertura completa, también conocida como “todo riesgo”, protege no solo contra daños a terceros, sino también los daños a tu propia moto, ya sea por accidente, robo, incendio, o fenómenos naturales.'
        },
        {
            titulo: '¿Cómo elegir el mejor seguro de moto para mí?',
            subTitulo: 'Para elegir el mejor seguro de moto, evaluá tus necesidades específicas, el uso que le das a la moto (por ejemplo, si la usás diariamente o solo ocasionalmente), el valor del vehículo y tu presupuesto. Es recomendable comparar diferentes opciones de aseguradoras y consultar con un asesor para encontrar la cobertura que mejor se adapte a vos.'
        },
        {
            titulo: '¿Cómo denuncio un siniestro si soy cliente de Segurarse? ',
            subTitulo: 'Si tuviste un siniestro, ingresá a nuestra App MiSegurarse y seguí los pasos para informar debidamente el hecho. Si aún no la tenés, descargala haciendo clic en el botón “Mi Segurarse” del menú y luego en el botón de Google Play/App Store según tu sistema operativo.'
        }
    ];

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Moto'
                    subTitulo={{ __html: 'Tu moto, tu compañera más fiel. <br/>Protegela ante cualquier imprevisto como se merece, cotizá 100% online y encontrá el seguro que te cubre cuando más lo necesitás.<br /><br />¿Empezamos?' }}
                    url='#formulario'
                    icon='#icon-seg-moto'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Un seguro de moto ofrece diferentes tipos de coberturas diseñadas para proteger tanto al conductor como al vehículo en distintas situaciones. La cobertura más básica, conocida como seguro de responsabilidad civil, es obligatoria y se encarga de cubrir los daños materiales y personales que puedas ocasionar a terceros en caso de un accidente.
                                <br /><br />
                                Además de la responsabilidad civil, un seguro de moto puede incluir otras coberturas que amplían tu protección. Por ejemplo, la cobertura contra robo e incendio protege tu moto en caso de ser robada o dañada por fuego, ofreciendo una compensación económica. Algunas pólizas también cubren el robo parcial, es decir, el hurto de piezas o accesorios específicos del vehículo.
                                <br /><br />
                                Tenemos pólizas que cubren desde los daños a terceros hasta la protección contra robo, incendios, daños propios y asistencia en viaje, ofreciendo una amplia gama de opciones para que elijas la que mejor se adapte a tus necesidades y estilo de conducción.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                ¡Elegí la cobertura para vos!
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            Responsabilidad Civil
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            Terceros Completo
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            Terceros Completo Premium
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            Todo Riesgo
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-10 col-md-8 col-xl-6 mx-auto text-center mt-3">
                            <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center text-white">
                                Si tenés dudas, contás con nuestro equipo de especialistas para asesorarte en todo momento.
                            </p>
                            <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                                <span className="fs-18 fs-lg-20 text-black fw-bold text-white">Chatéanos</span>
                                <svg className="svg-icon ms-2 w-12em">
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <FormularioIndividuo
                    rubro='Motos'
                    titulo='Cotizá seguro de moto'
                />
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
