import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IPasos } from '../../../Pages/AP/Cotizador';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SegInput } from '../../SegUI/SegInput/SegInput';
import { SegButton } from '../../SegUI/SegButton/SegButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useCotizacionStore } from '../../../Services/Providers/CotizacionStore';
import { SegTooltip } from '../../SegUI/SegTooltip/SegTooltip';
import { guardarContactoAccidentesPersonales, SolicitudCotizacionAP } from '../../../Services/API/GuardarContactoAccidentesPersonales';
import { ITelefono } from '../../../Services/Providers/EmisionStore';


interface IDatosCobertura {
    fechaInicio: string;
    fechaFin: string;
    sumaMuerte: string;
}

interface DatosCoberturaProps {
    setSteps: Dispatch<SetStateAction<IPasos>>
}


export const DatosCobertura = ({ setSteps, ...rest }: DatosCoberturaProps) => {

    const nav = useNavigate();
    const { compania } = useParams();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorCaptcha, setErrorCaptcha] = useState("");


    const {
        handleSubmit,
        setValue,
        trigger,
        control,
        formState: { errors, isValid },
        getValues
    } = useForm<IDatosCobertura>();


    useEffect(() => {
        if (cotizacionValues.fechaInicio) {
            setValue("fechaInicio", cotizacionValues.fechaInicio)
            trigger("fechaInicio")
        }
        if (cotizacionValues.fechaFin) {
            setValue("fechaFin", cotizacionValues.fechaFin)
            trigger("fechaFin")
        }
        if (cotizacionValues.sumaMuerte) {
            setValue("sumaMuerte", formatNumber(cotizacionValues.sumaMuerte.toString()))
        }
    }, []);

    const formatNumber = (value: string) => {
        const parts = value.split(',');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Añade puntos cada 3 dígitos
        return parts.join(',');
    };

    const unformatNumber = (value: string) => {
        return value.replace(/\./g, '');
    };

    const onSubmit: SubmitHandler<IDatosCobertura> = async (values: IDatosCobertura) => {
        const { fechaFin, fechaInicio, sumaMuerte } = values;
        const key = process.env.REACT_APP_RECAPTCHA_KEY
        grecaptcha.ready(function () {
            grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
                handleGuardarContacto(fechaFin, fechaInicio, Number.parseFloat(unformatNumber(sumaMuerte)), token)
            })
        });
    }


    const handleGuardarContacto = async (fechaFin: string, fechaInicio: string, sumaMuerte: number, captchaToken: string) => {
        try {

            setCotizacionValues({
                ...cotizacionValues,
                sumaMuerte: sumaMuerte,
                fechaInicio,
                fechaFin,
                captchaToken,
            });

            const params: SolicitudCotizacionAP = {
                nombre: cotizacionValues.nombre || "",
                apellido: cotizacionValues.apellido || "",
                telefono: {
                    CodigoArea: cotizacionValues.telefono?.prefijo || "",
                    Numero: cotizacionValues.telefono?.numero || ""
                },
                email: cotizacionValues.email || "",
                localidad: cotizacionValues.localidad?.value || "",
                provincia: cotizacionValues.provincia?.value || "",
                departamento: cotizacionValues.provincia?.value || "",
                codigoPostal: cotizacionValues.codigoPostal || "8282",

                //Datos Asegurado
                profesion: cotizacionValues.profesion?.value || "",
                profesionDescripcion: cotizacionValues.profesion?.label || "",
                condicionIVA: cotizacionValues.condicionIVA + "" || "",
                condicionIB: cotizacionValues.condicionIIBB || "",
                condicionIIBB: cotizacionValues.condicionIIBB || "",
                tipoPersona: cotizacionValues.tipoPersona || "",
                tipoDocumento: cotizacionValues.tipoDoc?.value || "",
                numeroDocumento: cotizacionValues.numeroDoc || "",

                //Datos cobertura
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                cantidad: cotizacionValues.cantidadDeVidas || 0,
                sumaMuerte: sumaMuerte,
                sumaGastos: (10 / 100) * sumaMuerte,
                sumaInvalidez: sumaMuerte //TODO: Ver limite  ,
            }

            if (cotizacionValues.actividadDedicada) {
                params.profesionDedicada = cotizacionValues.actividadDedicada
            }

            setIsLoading(true);
            const response = await guardarContactoAccidentesPersonales(params, captchaToken);
            setIsLoading(false);

            if (response.data) {
                if (response.data.api_status === 200 && response.data.api_data !== null) {
                    setCotizacionValues({
                        ...cotizacionValues,
                        contactoId: response.data.api_data.idContacto,
                        token: response.data.api_data.token,
                        sumaMuerte: sumaMuerte,
                        fechaInicio,
                        fechaFin,
                        captchaToken,
                        fechaConsultaCotizaciones: undefined
                    })
                } else {
                    return compania
                        ? nav("/cotizador-de-seguros-ap" + compania)
                        : nav("/cotizador-de-seguros-ap");
                }
            } else {
                return compania
                    ? nav("/cotizador-de-seguros-ap" + compania)
                    : nav("/cotizador-de-seguros-ap");
            }

            if (cotizacionValues.actividadDedicada) {
                return nav(compania ? `/ap/cotizacion/${compania}/gracias` : "/ap/cotizacion/gracias")
            }

            return compania
                ? nav("/resultado-cotizador-ap" + compania)
                : nav("/resultado-cotizador-ap"); //VER ACA
            setErrorCaptcha("");

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="mt-4 bg-gray-light" {...rest}>
            <div className="frm01">
                <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
                    <div className="cod-postal-column justify-content-center gap-4">
                        <p className='text-primary fw-bold fs-4 mb-0 d-flex justify-center align-items-center'>
                            {"Datos de la cobertura"}
                        </p>
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">¿Fecha de inicio?</legend>
                                <Controller
                                    name="fechaInicio"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá la fecha de inicio",
                                            futureDate: (value) => {
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                const selectedDate = new Date(value);
                                                selectedDate.setDate(selectedDate.getDate() + 1)
                                                return selectedDate > today || "La fecha debe ser mayor o igual al día actual";
                                            },
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            className="w-full mt-3"
                                            label=""
                                            name="fechaInicio"
                                            type="date"
                                            min={new Date().toISOString().split("T")[0]}
                                            width={100}
                                            placeHolder="dd/mm/aaaa"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("fechaInicio", e.target.value);
                                                trigger("fechaInicio");
                                                trigger("fechaFin");
                                            }}
                                            value={field.value}
                                            autoFocus={true}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${errors.fechaInicio
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${errors.fechaInicio
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <div className="cod-postal-column justify-content-center gap-4 mt-4">
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">¿Fecha de fin?</legend>
                                <Controller
                                    name="fechaFin"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá la fecha de fin",
                                            futureDate: (value) => {
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                const selectedDate = new Date(value);
                                                selectedDate.setDate(selectedDate.getDate() + 1)
                                                return selectedDate > today || "La fecha debe ser mayor o igual al día actual";
                                            },
                                            startBeforeEnd: (value) => {
                                                const startDate = new Date(getValues("fechaInicio"));
                                                const endDate = new Date(value);
                                                if (!startDate || !endDate) return true; // Si falta algún campo, no validar aún
                                                return startDate <= endDate || "La fecha de inicio debe ser menor o igual a la fecha de fin";
                                            },
                                        },

                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            className="w-full mt-3"
                                            label=""
                                            name="fechaFin"
                                            type="date"
                                            width={100}
                                            min={new Date().toISOString().split("T")[0]}
                                            placeHolder="dd/mm/aaaa"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("fechaFin", e.target.value);
                                                trigger("fechaFin");
                                                trigger("fechaInicio");
                                            }}
                                            value={field.value}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${errors.fechaFin
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${errors.fechaFin
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </fieldset>
                        </div>
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <legend>
                                {"Suma asegurada por muerte "}
                                <SegTooltip text="Monto que la aseguradora pagará a los beneficiarios en caso de que el asegurado muera en un accidente o a causa de uno." />
                            </legend>
                            <div className="m-auto d-flex justify-content-center">
                                <Controller
                                    name="sumaMuerte"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => (!!value || "Ingresá la suma asegurada"),
                                            pattern: (value) => /^[0-9,.]+$/.test(value) || "Suma asegurada inválida",
                                            minSalario: (value) => {
                                                const numericValue = parseFloat(unformatNumber(value).replace(/,/g, ''));
                                                return !(numericValue <= 0) || "Suma asegurada inválida";
                                            },
                                            noLeadingZero: (value) => {
                                                const numericValue = unformatNumber(value);
                                                return numericValue[0] !== '0' || "El primer dígito no puede ser 0";
                                            },
                                            maxDecimals: (value) => {
                                                const parts = value.split(',');
                                                return parts.length <= 2 || "Solo se permite una coma"; // Asegura que solo haya una coma
                                            },
                                            maxValue: (value) => parseFloat(unformatNumber(value).replace(/,/g, '')) >= 5000000 || "El valor minimo permitido es 5.000.000",

                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            labelGroup={"$"}
                                            className=""
                                            label=""
                                            name="sumaAsegurada"
                                            type="text"
                                            inputMode="numeric"
                                            width={100}
                                            maxLenghtDigits={11}
                                            placeHolder="0"
                                            //ref={inputRef}
                                            onChange={(e) => {
                                                let value = e.target.value.replace(/[^0-9,]/g, ''); // Filtra letras y caracteres no deseados

                                                // Permite solo una coma
                                                const parts = value.split(',');
                                                if (parts.length > 2) {
                                                    value = parts[0] + ',' + parts.slice(1).join('').replace(/,/g, '');
                                                }

                                                // Limita los decimales a 2
                                                if (parts.length > 1) {
                                                    parts[1] = parts[1].substring(0, 2);
                                                    value = parts[0] + ',' + parts[1];
                                                }

                                                // Añade separadores de miles
                                                value = formatNumber(value);
                                                field.onChange(value);
                                                setValue("sumaMuerte", value);
                                                trigger("sumaMuerte");
                                            }}
                                            value={field.value}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${errors.sumaMuerte
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${errors.sumaMuerte
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-buttons text-center">
                        <SegButton
                            label="Continuar"
                            type="submit"
                            disabled={!isValid || isLoading}
                            className="mb-4"
                            spinnerSize='small'
                            loading={isLoading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
