import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imageCompaniaAP'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { PasosCotizar } from '../Components/PasosCotizar'
import { CompaniasSlider } from '../Components/CompaniasSlider'
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const AP = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Cuál es la diferencia entre un seguro de Accidentes Personales y un seguro ART?',
            subTitulo: 'El seguro de Accidentes Personales es una protección destinada principalmente a aquellos trabajadores y trabajadores que no tienen una relación laboral formal. Mientras que el seguro de ART es una protección obligatoria que cubre exclusivamente los accidentes laborales o enfermedades profesionales de los empleados de una empresa.'
        },
        {
            titulo: '¿Qué cubre un seguro de accidentes personales?',
            subTitulo: 'Generalmente, un seguro de accidentes personales cubre fallecimiento accidental, incapacidad permanente total o parcial, incapacidad temporal, gastos médicos por accidentes, gastos de rehabilitación, asistencia en caso de emergencias, y, en algunos casos, gastos funerarios. La cobertura específica puede variar según la póliza y la aseguradora.'
        },
        {
            titulo: '¿Puedo contratar un seguro de accidentes personales si ya tengo otros seguros?',
            subTitulo: 'Sí, podés contratar un seguro de accidentes personales incluso si ya tenés otros seguros, como seguros de salud o de vida. Este seguro ofrece beneficios específicos para accidentes, complementando la cobertura de otras pólizas y proporcionando una mayor protección financiera.'
        },
        {
            titulo: '¿Qué es la cobertura por incapacidad en un seguro de accidentes personales?',
            subTitulo: 'La cobertura por incapacidad en un seguro de accidentes personales ofrece una compensación económica si el asegurado sufre una incapacidad permanente total o parcial, o una incapacidad temporal como resultado de un accidente. Esta compensación puede ser en forma de un pago único o de pagos periódicos, según la póliza.'
        },
        {
            titulo: '¿Puedo asegurar a otras personas en mi seguro de accidentes personales?',
            subTitulo: 'Sí, en algunos casos, podés incluir a tus familiares directos (como tu cónyuge e hijos) en tu póliza de accidentes personales, ampliando así la cobertura. Sin embargo, esto puede aumentar el costo de la prima. Es importante consultar con la aseguradora sobre las opciones disponibles.'
        }
    ];

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Accidentes Personales'
                    subTitulo={{ __html: 'Si sos trabajador independiente, te recomendamos que contrates un seguro de accidentes personales para estar cubierto durante tu jornada laboral ante cualquier posible eventualidad.<br /><br />¿Empezamos?' }}
                    url='/cotizador-de-seguros-ap'
                    icon='#icon-seg-acc-personales'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Este tipo de seguro ofrece una compensación financiera para ayudarte a enfrentar los gastos médicos, la pérdida de ingresos, y otros costos asociados con un accidente inesperado. Los planes pueden incluir:
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Indemnización por fallecimiento accidental</b>: proporciona una suma de dinero a los beneficiarios designados en caso de que el titular del seguro fallezca como resultado de un accidente cubierto.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Incapacidad permanente total o parcial</b>: cubre una indemnización en caso de que el asegurado sufra una discapacidad permanente, ya sea total o parcial, debido a un accidente.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Incapacidad temporal</b>: ofrece un beneficio económico si el asegurado queda temporalmente incapacitado para trabajar debido a un accidente. Esta cobertura puede proporcionar pagos periódicos para ayudar a reemplazar la pérdida de ingresos durante el período de recuperación.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Gastos médicos por accidente</b>: cubre los gastos médicos necesarios derivados de un accidente, como honorarios médicos, hospitalización, cirugía, medicamentos, terapias de rehabilitación, y traslados en ambulancia. Esta cobertura puede complementar otros seguros de salud para evitar que tengas que pagar estos costos de tu bolsillo.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PasosCotizar />
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
