import { Header } from '../Components/Header';
import shape02 from "../../../Assets/img/shape02.svg";
import shape05 from "../../../Assets/img/shape05.svg";
import shape06 from "../../../Assets/img/shape06.svg";
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SegInput } from '../../../Components/SegUI/SegInput/SegInputLanding';
import CodigosDeArea from '../../../Assets/CodigosDeArea.json';
import { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import sendArrepentimiento from '../../../Services/API/Arrepentimiento';
import { SegButton } from '../../../Components/SegUI/SegButton/SegButton';
import useSEOConfig from '../../../hooks/seo/useSEOConfig';


type FormValues = {
    nombre: string;
    apellido: string;
    email: string;
    prefijo: string;
    telefono: string;
    tipo_doc: string;
    documento: string;
    dia: string;
    mes: string;
    anio: string;
    motivo_arrepentimiento: string;
    terminos: boolean;
};

const StyledDiv = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;

export const FormularioArrepentimiento = () => {
    const { handleSubmit, register, control, setValue, getValues, formState: { errors }, trigger, setError, clearErrors, watch, formState } =
        useForm<FormValues>();
    const [isTelefonoValido, setIsTelefonoValido] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    const validarTelefono = (prefijo: string, numero: string) => {
        const numeroCompleto = prefijo + numero;

        if (numeroCompleto.length === 10) {
            return true;
        } else {
            return false;
        }
    };

    const validarYActualizarTelefono = () => {
        const { prefijo, telefono } = getValues();
        const resultadoValidarTelefono = validarTelefono(prefijo, telefono);
        setIsTelefonoValido(resultadoValidarTelefono);
    };
    const obtenerTokenRecaptcha = (key: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
                    resolve(token)
                });
            });
        });
    };
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        setIsLoading(true)
        const createFormattedString = (data: FormValues) => {
            const {
                nombre,
                apellido,
                email,
                prefijo,
                telefono,
                tipo_doc,
                documento,
                dia,
                mes,
                anio,
                motivo_arrepentimiento,
                terminos
            } = data;

            return `
            <!DOCTYPE html>
            <html>
            <head>
                <meta charset="UTF-8">
                <title>Formulario de Arrepentimiento</title>
            </head>
            <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
                <div style="max-width: 600px; margin: 0 auto; padding: 20px;">
                    <div style="margin-bottom: 20px;">
                        <h2 style="color: #2c3e50; border-bottom: 2px solid #3498db; padding-bottom: 10px;">DATOS PERSONALES</h2>
                        <p><strong>Nombre:</strong> ${nombre}</p>
                        <p><strong>Apellido:</strong> ${apellido}</p>
                        <p><strong>Email:</strong> ${email}</p>
                        <p><strong>Teléfono:</strong> (${prefijo}) ${telefono}</p>
                    </div>
            
                    <div style="margin-bottom: 20px;">
                        <h2 style="color: #2c3e50; border-bottom: 2px solid #3498db; padding-bottom: 10px;">DOCUMENTACIÓN</h2>
                        <p><strong>Tipo de documento:</strong> ${tipo_doc}</p>
                        <p><strong>Número:</strong> ${documento}</p>
                    </div>
            
                    <div style="margin-bottom: 20px;">
                        <h2 style="color: #2c3e50; border-bottom: 2px solid #3498db; padding-bottom: 10px;">INFORMACIÓN DE CONTRATACIÓN</h2>
                        <p><strong>Fecha de contratación:</strong> ${dia}/${mes}/${anio}</p>
                        <p><strong>Motivo:</strong> ${motivo_arrepentimiento}</p>
                    </div>
            
                    <div style="margin-bottom: 20px;">
                        <h2 style="color: #2c3e50; border-bottom: 2px solid #3498db; padding-bottom: 10px;">CONFIRMACIÓN</h2>
                        <p>${terminos ? 'Acepta' : ''} Políticas de privacidad</p>
                    </div>
                </div>
            </body>
            </html>`;
        }
        const dataString = createFormattedString(data) || "";

        const key = process.env.REACT_APP_RECAPTCHA_KEY
        obtenerTokenRecaptcha(key).then((token: string) => {
            if (dataString !== "") {
                const fetchData = async () => {
                    const response: any = await sendArrepentimiento(dataString, token);
                    if (response?.data) {
                        if (response.data.api_status === 200 && response.data.api_data !== null) {
                            navigate("/formulario-arrepentimiento-enviado")
                        } else if (response.data.api_data === "El Captcha no es válido") {
                            setIsLoading(false)
                            console.log('Error en el captcha')
                        } else {
                            console.error("Error al guardar contacto")
                            setIsLoading(false)
                        }
                    } else {
                        setIsLoading(false)
                    }
                }
                fetchData();
            }
        })
    }

    useSEOConfig(
        {
            title: "Formulario de Arrepentimiento - Segurarse",
            description: "Formulario de revocación de aceptación del seguro contratado según Resolución 424/2020 de la Secretaría de Comercio Interior. Complete el formulario para ejercer su derecho de arrepentimiento en Segurarse.",
            keywords: "formulario de arrepentimiento, revocación de seguro, Segurarse, Resolución 424/2020, derecho de arrepentimiento, ecommerce Segurarse",
            ogTitle: "Formulario de Arrepentimiento - Segurarse",
            ogDescription: "Complete el formulario de arrepentimiento para revocar su aceptación del seguro contratado en Segurarse conforme a la Resolución 424/2020 de la Secretaría de Comercio Interior.",
            twitterTitle: "Formulario de Arrepentimiento - Segurarse",
            twitterDescription: "Complete el formulario de arrepentimiento para revocar su aceptación del seguro contratado en Segurarse conforme a la Resolución 424/2020 de la Secretaría de Comercio Interior."
        }
    )

    return (
        <>
            <Header rubro='auto' />
            <main className='bg-white'>
                <div className="position-relative pt-45 pb-25 pt-lg-80 pb-lg-35">
                    <img className="d-none d-lg-block pos02" src={shape02} alt='' />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start mb-10">Formulario de arrepentimiento</h2>
                        <div className="col-md-10 col-lg-8 mx-auto mx-lg-0">
                            <p className="fs-14 fs-lg-16 text-black text-center text-lg-start mb-0">Conforme Resolución 424/2020, de la secretaria de Comercio Interior, las y los clientes pueden solicitar la revocación de la aceptación durante el plazo de DIEZ (10) días corridos contados a partir de la fecha de la celebración del seguro contratado únicamente mediante el canal ecommerce de la web (www.segurarse.com.ar).</p>
                        </div>
                    </div>
                </div>

                <div className="pt-25 pb-80">
                    <div className="container">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="bg-f8 rounded-3 px-3 px-sm-5 py-25">
                            <div className="row gx-lg-5">

                                <div className="col-lg-6">

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">¿Cuál es tu nombre?*</div>

                                        <Controller
                                            name="nombre"
                                            control={control} 
                                            rules={{
                                                validate: {
                                                    required: (value) => !!value || "Ingresá el nombre",
                                                    pattern: (value) =>
                                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                        "Nombre inválido",
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <SegInput
                                                    id="nombre"
                                                    className="fs-16"
                                                    label=""
                                                    name="nombre"
                                                    type="text"
                                                    width={100}
                                                    placeHolder="Ej: Juan"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue("nombre", e.target.value); // Actualiza el valor en React Hook Form
                                                        trigger("nombre");
                                                    }}
                                                    value={field.value}
                                                    autoFocus={true}
                                                    leyendaError={fieldState?.error?.message}
                                                    borderColor={`${formState.errors.nombre
                                                        ? "var(--bs-form-invalid-border-color)"
                                                        : ""
                                                        }`}
                                                    focusBorderColor={`${formState.errors.nombre
                                                        ? "var(--bs-form-invalid-border-color)"
                                                        : "#2c7dfd"
                                                        }`}
                                                />
                                            )}

                                        />
                                    </div>

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">¿Tu apellido?*</div>
                                        <Controller
                                            name="apellido"
                                            control={control}
                                            rules={{
                                                validate: {
                                                    required: (value) => !!value || "Ingresá el apellido",
                                                    pattern: (value) =>
                                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                        "Apellido inválido",
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <SegInput
                                                    id="apellido"
                                                    className="fs-16"
                                                    label=''
                                                    width={100}
                                                    name="apellido"
                                                    type="text"
                                                    placeHolder="Ej: Perez"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue("apellido", e.target.value); // Actualiza el valor en React Hook Form
                                                        trigger("apellido");
                                                    }}
                                                    value={field.value}
                                                    autoFocus={false}
                                                    leyendaError={fieldState?.error?.message}
                                                    borderColor={`${formState.errors.apellido
                                                        ? "var(--bs-form-invalid-border-color)"
                                                        : ""
                                                        }`}
                                                    focusBorderColor={`${formState.errors.apellido
                                                        ? "var(--bs-form-invalid-border-color)"
                                                        : "#2c7dfd"
                                                        }`}
                                                />
                                            )}

                                        />
                                    </div>

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">¿Tu email?*</div>

                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                validate: {
                                                    required: (value) => (!!value || "Ingresá el email"),
                                                    pattern: (value) =>
                                                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                                            value
                                                        ) || "Email inválido",
                                                },
                                            }}
                                            render={({ field, fieldState }) =>
                                                <SegInput
                                                    id="email"
                                                    className="fs-16"
                                                    label=''
                                                    width={100}
                                                    name="email"
                                                    type="text"
                                                    placeHolder="Ej: juanperez@gmail.com"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue("email", e.target.value); // Actualiza el valor en React Hook Form
                                                        trigger("email");
                                                    }}
                                                    value={field.value}
                                                    autoFocus={false}
                                                    leyendaError={fieldState?.error?.message}
                                                    borderColor={`${formState.errors.email
                                                        ? "var(--bs-form-invalid-border-color)"
                                                        : ""
                                                        }`}
                                                    focusBorderColor={`${formState.errors.email
                                                        ? "var(--bs-form-invalid-border-color)"
                                                        : "#2c7dfd"
                                                        }`}
                                                />
                                            }
                                        />


                                    </div>

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">¿Tu teléfono celular?*</div>
                                        <div className={(window.innerWidth > 768) ? "row g-2" : ""}>
                                            <div className={(window.innerWidth > 768) ? "col-3" : "col-12"}>
                                                <div className="input-group">
                                                    <div className="fs-14 text-6e mb-1">Cod. Area</div>
                                                    <Controller
                                                        name="prefijo"
                                                        control={control}
                                                        rules={{
                                                            validate: {
                                                                required: (value) => !!value || "Ingresá el Cód. Área",
                                                                isInValidCodeArea: (value) =>
                                                                    CodigosDeArea.includes(value) ||
                                                                    "Cód. Área inválido",
                                                            },
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <SegInput

                                                                isGroup={true}
                                                                labelGroup="0"
                                                                className="fs-16"
                                                                label=""
                                                                name="setPrefijo"
                                                                type="number"
                                                                width={100}
                                                                placeHolder="11"
                                                                value={field.value}
                                                                onKeyDown={(event) => {
                                                                    if (event.key === "e" || event.key === "E") {
                                                                    event.preventDefault();
                                                                    }
                                                                }}
                                                                autoFocus={false}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue("prefijo", e.target.value); // Actualiza el valor en React Hook Form
                                                                    trigger("prefijo");
                                                                    if (getValues("telefono") !== "" && !validarTelefono(getValues("prefijo"), getValues("telefono"))) {
                                                                        setError("telefono", { type: "validate", message: "Número inválido" })
                                                                    } else {
                                                                        clearErrors("telefono")
                                                                    }
                                                                    validarYActualizarTelefono();

                                                                }}
                                                                leyendaError={fieldState?.error?.message}
                                                                borderColor={`${formState.errors.prefijo
                                                                    ? "var(--bs-form-invalid-border-color)"
                                                                    : ""
                                                                    }`}
                                                                focusBorderColor={`${formState.errors.prefijo
                                                                    ? "var(--bs-form-invalid-border-color)"
                                                                    : "#2c7dfd"
                                                                    }`}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className={(window.innerWidth > 768) ? "col-9" : "col-12 mt-2"}>
                                                <div className="fs-14 text-6e mb-1">Número de teléfono</div>
                                                <Controller
                                                    name="telefono"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => !!value || "Ingresá Número de Celular",
                                                            pattern: (value) =>
                                                                /^[0-9]{6,9}$/.test(value) || "Número inválido",
                                                            validateCombination: (value) => {
                                                                const prefijo = watch("prefijo");
                                                                if ((prefijo + value).length !== 10)
                                                                    return "Número inválido"
                                                            }
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            isGroup={true}
                                                            labelGroup="15"
                                                            className="fs-16"
                                                            label=""
                                                            name="telefono"
                                                            type="number"
                                                            width={100}
                                                            placeHolder="33669955"
                                                            value={field.value}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "e" || event.key === "E") {
                                                                event.preventDefault();
                                                                }
                                                            }}
                                                            autoFocus={false}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("telefono", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("telefono");
                                                                validarYActualizarTelefono();
                                                            }}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.telefono
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.telefono
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                        />
                                                    )}
                                                />


                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="col-lg-6">

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">Ingresá tu documento*</div>
                                        <div className={(window.innerWidth > 768) ? "row g-2" : ""}>
                                            <div className={(window.innerWidth > 768) ? "col-3" : "col-12"}>
                                                <div className="fs-13 text-6e mb-1">Tipo</div>

                                                <select className="form-select fs-14"
                                                    {...register("tipo_doc",
                                                        { required: "Selecciná un tipo" })
                                                    }
                                                >
                                                    <option value={"DNI"}>DNI</option>
                                                    <option value={"CUIT"}>CUIT</option>
                                                </select>
                                                {
                                                    errors.tipo_doc &&
                                                    <StyledDiv className="error">{errors.tipo_doc.message}</StyledDiv>
                                                }
                                            </div>
                                            <div className={(window.innerWidth > 768) ? "col-9" : "col-12 mt-2"}>
                                                <div className="fs-13 text-6e mb-1">Nº sin puntos, guiones ni espacios</div>
                                                <Controller
                                                    name="documento"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => {
                                                                if (!value) {
                                                                    return "Ingresá el Número"
                                                                } else {
                                                                }
                                                            },
                                                            pattern: (value) => {
                                                                const tipoDoc = getValues("tipo_doc");
                                                                if (tipoDoc === 'DNI') {
                                                                    if (/^\d{7,8}$/.test(value)) {
                                                                    } else {
                                                                        return "DNI inválido"
                                                                    }
                                                                } else if (tipoDoc === 'CUIT' || tipoDoc === 'CUIL') {
                                                                    if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                                    } else {
                                                                        return `Documento inválido`
                                                                    }
                                                                }
                                                            }
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            id="documento"
                                                            className="fs-14"
                                                            placeHolder='42340099'
                                                            label=""
                                                            name="documento"
                                                            type="number"
                                                            width={100}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "e" || event.key === "E") {
                                                                event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("documento", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("documento");
                                                            }}
                                                            value={field.value}
                                                            autoFocus={false}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.documento
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.documento
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                        />
                                                    )}

                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">Fecha de contratación*</div>
                                        <div className="fs-13 text-black mb-1">Indicar la fecha de emisión, la que figura en tu póliza</div>
                                        <div className="row g-2">
                                            <div className="col-4">
                                                <div className="fs-13 text-6e mb-1">Día</div>
                                                <Controller
                                                    name="dia"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            customValidation: (value) => {
                                                                if (!value) {
                                                                    return "Día debe incluir 2 dígitos";
                                                                }
                                                                const parsedValue = parseInt(value, 10);
                                                                if (isNaN(parsedValue) || parsedValue > 31) {
                                                                    return "Día no puede ser mayor a 31";
                                                                }
                                                                if (isNaN(parsedValue) || parsedValue <= 0) {
                                                                    return "Día no puede ser 0";
                                                                }
                                                                const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                                if (isSingleDigitWithoutZero) {
                                                                    return "Día debe incluir 2 dígitos";
                                                                }
                                                                return true; // La validación es exitosa
                                                            },
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput

                                                            label=""
                                                            name="dia"
                                                            type="number"
                                                            width={100}
                                                            placeHolder="DD"
                                                            value={field.value}
                                                            autoFocus={false}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "e" || event.key === "E") {
                                                                event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                                field.onChange(trimmedValue);
                                                                setValue("dia", trimmedValue);
                                                                trigger("dia");
                                                                if (trimmedValue.length === 2) {
                                                                    const nextInput = document.querySelector('input[name="mes"]') as HTMLInputElement;
                                                                    nextInput?.focus();
                                                                }
                                                            }}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.dia
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.dia
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                        />
                                                    )}
                                                />
                                            </div>


                                            <div className="col-4">
                                                <div className="fs-13 text-6e mb-1">Mes</div>

                                                <Controller
                                                    name="mes"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            customValidation: (value) => {
                                                                if (!value) {
                                                                    return "Mes debe incluir 2 dígitos";
                                                                }
                                                                const parsedValue = parseInt(value, 10);
                                                                if (isNaN(parsedValue) || parsedValue > 12) {
                                                                    return "Mes no puede ser mayor a 12";
                                                                }
                                                                if (isNaN(parsedValue) || parsedValue <= 0) {
                                                                    return "Día no puede ser 0";
                                                                }
                                                                const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                                if (isSingleDigitWithoutZero) {
                                                                    return "Mes debe incluir 2 dígitos";
                                                                }
                                                                return true; // La validación es exitosa
                                                            },
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            label=""
                                                            name="mes"
                                                            type="number"
                                                            width={100}
                                                            placeHolder="MM"
                                                            value={field.value}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "e" || event.key === "E") {
                                                                event.preventDefault();
                                                                }
                                                            }}
                                                            autoFocus={false}
                                                            onChange={(e) => {
                                                                const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                                field.onChange(trimmedValue);
                                                                setValue("mes", trimmedValue);
                                                                trigger("mes");
                                                                if (trimmedValue.length === 2) {
                                                                    const nextInput = document.querySelector('input[name="anio"]') as HTMLInputElement;
                                                                    nextInput?.focus();
                                                                }
                                                            }}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.mes
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.mes
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-4">
                                                <div className="fs-13 text-6e mb-1">Año</div>

                                                <Controller
                                                    name="anio"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) =>
                                                                !!value || "Ingresá el año de nacimiento",
                                                            pattern: (value) =>
                                                                /^(19\d{2}|20\d{2})$/.test(value) || "Año debe incluir 4 dígitos",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            label=""
                                                            name="anio"
                                                            type="number"
                                                            placeHolder="AAAA"
                                                            value={field.value}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "e" || event.key === "E") {
                                                                event.preventDefault();
                                                                }
                                                            }}
                                                            autoFocus={false}
                                                            onChange={(e) => {
                                                                const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                                                                field.onChange(trimmedValue);
                                                                setValue("anio", trimmedValue); // Actualiza el valor en React Hook Form
                                                                trigger("anio");
                                                            }}
                                                            width={100}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.anio
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.anio
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                        />
                                                    )}
                                                />
                                            </div>


                                        </div>
                                    </div>

                                    <div className="mb-20">
                                        <div className="fs-14 fw-bold text-black mb-1">Seleccioná el motivo por el cual te arrepentiste de tu seguro*</div>
                                        <select className="form-select fs-14"
                                            {...register("motivo_arrepentimiento",
                                                { required: "El motivo es requerido", })
                                            }>
                                            <option value="">Seleccioná una opción</option>
                                            <option value="Seguro no contratado">Seguro no contratado</option>
                                            <option value="Costo elevado">Costo elevado</option>
                                            <option value="Disconformidad con el servicio">Disconformidad con el servicio</option>
                                            <option value="Contraté otro seguro">Contraté otro seguro</option>
                                            <option value="Otros">Otros</option>
                                        </select>
                                        {
                                            errors.motivo_arrepentimiento &&
                                            <StyledDiv className="error">{errors.motivo_arrepentimiento.message}</StyledDiv>
                                        }
                                    </div>

                                    <div className="mb-20">
                                        <div className="form-check">
                                            <input
                                                {...register("terminos",
                                                    { required: "Este campo es requerido", })
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="flexCheckDefault" />
                                            <label className="form-check-label text-6e fs-12" htmlFor="flexCheckDefault">
                                                He leído y acepto las <a className="text-decoration-underline" href="/politicas-privacidad" target="_blank">políticas de privacidad</a>
                                            </label>
                                        </div>
                                        {
                                            errors.terminos &&
                                            <StyledDiv className="error">{errors.terminos.message}</StyledDiv>
                                        }
                                    </div>

                                </div>


                                <div>
                                    <div className="text-center pt-3">
                                        <SegButton
                                            label="Enviar"
                                            type="submit"
                                            className="btn btn-primary fs-17 fw-bold px-45"
                                            disabled={
                                                !formState.isValid // Verifica si el formulario es válido
                                            }
                                            loading={isLoading}
                                        />
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

                <div className="bg-f4 py-30 py-lg-50 position-relative">
                    <img className="d-none d-lg-block pos07 bottom-0" src={shape05} alt='' />
                    <img className="d-none d-lg-block pos06" src={shape06} alt='' />
                    <div className="container px-lg-5">
                        <div className="row justify-content-evenly">
                            <div className="col-lg-auto text-center text-lg-start">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-lg-1">¿No resolvimos<br className="d-sm-none" /> tu consulta?</h2>
                                <p className="fw-bold fs-25 fs-lg-25 text-primary mb-18 mb-lg-0">¡Estamos para vos!</p>
                            </div>
                            <div className="col-lg-auto text-center text-lg-left">
                                <a href="/contacto" className="transition-base d-inline-block w-250p py-12 rounded-3 fs-22 fs-lg-24 fw-bold lh-1 text-center bg-primary bg-lg-gray-dark hover-bg-primary text-white">CONTACTO</a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
