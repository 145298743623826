import { useEffect, useRef, useState } from 'react'
import { AseguradoAP, IEmisionValues, IFechaNacimiento } from '../../../../Services/Providers/EmisionStore';
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SegSelect, SegSelectOption } from '../../../SegUI/SegSelect/SegSelect';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SegInput } from '../../../SegUI/SegInput/SegInput';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import styled from '@emotion/styled';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo';
import { IDatosDelBeneficiario, ModalBeneficiarios } from './ModalBeneficiarios';
import { esAnioBisiesto } from '../../../../helpers/anioBisiesto';


const DivError = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;
const CoberturaTitle = styled.p`
    text-align:center;
    font-size: 18px;
    font-weight: 800;
    color: #2C7DFD;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
    margin: auto;
    width: 80%;
    margin-bottom: 5px;
`;

interface Props {
    emisionValues: IEmisionValues;
    setEmisionValues: (values: IEmisionValues) => void
}

interface IDatosDelAsegurado {
    nombre: string;
    apellido: string;
    profesion: string;
    fechaNac: IFechaNacimiento;
    genero: string;
    numDoc: string;
}

interface IDocumento {
    tipoDoc: string;
    numero: string;
    profesion: string;
}
const tiposDoc = [
    { value: 'dni', label: 'DNI' },
    { value: 'cuit', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' },
];

const tiposGeneros = [
    { value: "1", label: 'Masculino' },
    { value: "2", label: 'Femenino' },
];


export const DatosAsegurado = ({ emisionValues, setEmisionValues }: Props) => {
    const { handleSubmit, control, setValue, formState, trigger, reset, getValues } = useForm<IDatosDelAsegurado>();
    const { cotizacionValues } = useCotizacionStore();
    const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
    const [errorNumero, setErrorNumero] = useState<boolean>(true)
    const [profesiones, setProfesiones] = useState<SegSelectOption[]>([]);
    const [profesion, setProfesion] = useState<SegSelectOption>();
    const [genero, setGenero] = useState<SegSelectOption>();
    const nav = useNavigate();
    const { compania } = useParams();
    const methods = useForm<IDocumento>();
    const [aseguradoActual, setAseguradoActual] = useState<number>(0);
    const [dataAsegurados, setDataAsegurados] = useState<AseguradoAP[]>([]);
    const CANTIDAD_ASEGURADOS = cotizacionValues.cantidadDeVidas || 1;
    const [agregarBeneficiario, setAgregarBeneficiario] = useState(false);
    const modalBeneficiarioRef = useRef<any>(null);
    const [modalAbierto, setModalAbierto] = useState<boolean>(true);


    useEffect(() => {
        if (emisionValues.accidentesPersonales?.asegurados) {
            setDataAsegurados(emisionValues.accidentesPersonales?.asegurados);
            setModalAbierto(false);
        }
        if (cotizacionValues.profesiones) {
            setProfesiones(cotizacionValues.profesiones)
        }
    }, []);

    useEffect(() => {
        if (emisionValues.accidentesPersonales?.asegurados) {
            const { asegurados } = emisionValues.accidentesPersonales

            setValue("nombre", asegurados[0].nombre);
            setValue("apellido", asegurados[0].apellido);
            setTipoDoc(tiposDoc.find(t => t.value === asegurados[0].tipoDoc));
            setValue("numDoc", asegurados[0].numDoc);
            setValue("fechaNac.dia", asegurados[0].fechaNac.dia);
            setValue("fechaNac.mes", asegurados[0].fechaNac.mes);
            setValue("fechaNac.anio", asegurados[0].fechaNac.anio);
            setGenero(asegurados[0].genero);
            setProfesion(asegurados[0].profesion)
            setAgregarBeneficiario(asegurados[0].beneficiario ? true : false)

            trigger()
        } else {
            setProfesion(cotizacionValues.profesion)
            setTipoDoc({ value: 'dni', label: 'DNI' })
        }
    }, [])

    useEffect(() => {
        if (!modalAbierto) {
            if (!dataAsegurados[aseguradoActual] || !dataAsegurados[aseguradoActual].beneficiario) {
                setAgregarBeneficiario(false)
            }
        }
    }, [modalAbierto])

    const handlePrevAsegurado = () => {
        llenarDatosAseguradoActual(aseguradoActual - 1);
        setAseguradoActual(prev => prev - 1);
    }

    const llenarDatosAseguradoActual = (actual: number) => {
        if (dataAsegurados[actual]) {
            const asegurado = dataAsegurados[actual];
            asegurado.nombre && setValue("nombre", asegurado.nombre);
            asegurado.apellido && setValue("apellido", asegurado.apellido);
            asegurado.tipoDoc && setTipoDoc(tiposDoc.find(t => t.value === asegurado.tipoDoc));
            asegurado.numDoc && setValue("numDoc", asegurado.numDoc);
            asegurado.fechaNac && setValue("fechaNac.dia", asegurado.fechaNac.dia);
            asegurado.fechaNac && setValue("fechaNac.mes", asegurado.fechaNac.mes);
            asegurado.fechaNac && setValue("fechaNac.anio", asegurado.fechaNac.anio);
            asegurado.genero && setGenero(asegurado.genero);
            asegurado.profesion && setProfesion(asegurado.profesion)
            setAgregarBeneficiario(asegurado.beneficiario ? true : false)

            trigger()
        }
    }


    const onSubmit: SubmitHandler<IDatosDelAsegurado> = (values: IDatosDelAsegurado) => {
        const { nombre, apellido, fechaNac, numDoc } = values;
        if (aseguradoActual <= (CANTIDAD_ASEGURADOS - 1)) {
            const arrAsegurados = dataAsegurados;
            arrAsegurados[aseguradoActual] = {
                ...arrAsegurados[aseguradoActual],
                nombre,
                apellido,
                fechaNac,
                genero: genero as any,
                tipoDoc: tipoDoc!.value,
                numDoc,
                profesion: profesion as any,
            }

            if (!agregarBeneficiario) {
                arrAsegurados[aseguradoActual].beneficiario = undefined
            }
            setDataAsegurados(arrAsegurados);

            if (dataAsegurados[aseguradoActual + 1]) {
                llenarDatosAseguradoActual(aseguradoActual + 1);
            } else {
                reset();
                setAgregarBeneficiario(false)
            }

            if ((aseguradoActual) < (CANTIDAD_ASEGURADOS - 1)) {
                setAseguradoActual(prev => prev + 1)
                return
            }
        }

        setEmisionValues({
            ...emisionValues,
            accidentesPersonales: {
                clausulaNoRepeticion: true,
                asegurados: dataAsegurados
            }
        })
        compania
            ? nav("/ap/emision/" + compania + "/datos-tarjeta") : nav("/ap/emision/datos-tarjeta");
    }


    const handleSwitchChange = () => {
        setAgregarBeneficiario(!agregarBeneficiario);
        if (!agregarBeneficiario) {
            setModalAbierto(true)
            modalBeneficiarioRef.current.click();
        }
    };

    const handleAgregarBeneficiario = (data: IDatosDelBeneficiario) => {
        const asegurados = dataAsegurados;

        if (!asegurados[aseguradoActual]) {
            asegurados[aseguradoActual] = {
                beneficiario: {
                    nombre: data.nombre,
                    apellido: data.apellido,
                    fechaNac: data.fechaNac,
                    tipoDoc: data.tipoDoc,
                    numDoc: data.numDoc
                },
            } as any
        } else {
            asegurados[aseguradoActual].beneficiario = {
                nombre: data.nombre,
                apellido: data.apellido,
                fechaNac: data.fechaNac,
                tipoDoc: data.tipoDoc,
                numDoc: data.numDoc
            };
        }

        setDataAsegurados(asegurados);

    }

    return (
        <div>
            <div className='px-20 maxw-1170p mx-auto'>
                <SegCoberturaInfo emisionValues={emisionValues} tipo='ACCIDENTES PERSONALES' />
            </div>
            <ModalBeneficiarios
                beneficiario={
                    dataAsegurados[aseguradoActual] ? dataAsegurados[aseguradoActual].beneficiario
                        : undefined
                }
                agregarBeneficiario={handleAgregarBeneficiario}
                setModalAbierto={setModalAbierto}
            />
            <div className='mt-2 px-20 pb-40 pb-lg-60 maxw-1170p mx-auto'>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <SegFormEmisionContainer title='Datos del asegurado'
                        children2={(window.innerWidth > 778) ? <div>
                            <span className='fs-20 fw-semibold text-primary'>
                                {`${aseguradoActual + 1} de ${cotizacionValues.cantidadDeVidas} asegurados`}
                            </span>
                        </div> :
                            <span className='fs-16 fw-semibold text-primary'>{`${aseguradoActual + 1} de ${cotizacionValues.cantidadDeVidas}`}</span>}
                    >
                        <div className="col-md-3">
                            <Controller
                                name="nombre"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el nombre",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Nombre inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Nombre"
                                        name="nombre"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: Juan"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("nombre", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("nombre");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-3">
                            <Controller
                                name="apellido"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el apellido",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Apellido inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Apellido"
                                        name="apellido"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: López"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("apellido", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("apellido");
                                        }}
                                        value={field.value}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className='form-label'>¿Cúal es tu profesion?</label>
                            <FormProvider {...methods}>
                                <SegSelect
                                    label=""
                                    selected={profesion}
                                    setSelected={setProfesion}
                                    name="tipoDoc"
                                    options={profesiones}
                                    isGrupable
                                    width={"100%"}
                                    //borderColor={errors.tipoDoc ? "red" : ""}
                                    openDefault={false}
                                    autoFoco={true}
                                    placeholder=""
                                    disabled
                                />
                            </FormProvider>
                        </div>
                        <div className="col-md-3">
                            <label className='form-label'>Tipo documento</label>
                            <FormProvider {...methods}>
                                <SegSelect
                                    label=""
                                    selected={tipoDoc}
                                    setSelected={setTipoDoc}
                                    name="tipoDoc"
                                    options={tiposDoc}
                                    isGrupable
                                    width={"100%"}
                                    //borderColor={errors.tipoDoc ? "red" : ""}
                                    openDefault={false}
                                    autoFoco={false}
                                    placeholder=""
                                />
                            </FormProvider>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Número de documento</label>
                            <Controller
                                name="numDoc"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                setErrorNumero(true)
                                                return "Ingresá el Número"
                                            } else {
                                                setErrorNumero(false)
                                            }
                                        },
                                        pattern: (value) => {
                                            if (tipoDoc?.value === 'dni') {
                                                if (/^\d{7,8}$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return "DNI inválido"
                                                }
                                            } else if (tipoDoc?.value === 'cuit' || tipoDoc?.value === 'cuil') {
                                                if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return `${tipoDoc?.label} inválido`
                                                }
                                            }
                                        }
                                    },
                                }}
                                render={({ field, fieldState }) => (

                                    <SegInput
                                        disabled={!tipoDoc}
                                        className="col-8"
                                        label=""
                                        name="setNumero"
                                        type="number"
                                        width={100}
                                        placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("numDoc", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("numDoc");
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "+" || e.key === "-") {
                                                e.preventDefault()
                                            }
                                        }}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors?.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-5">
                            <label className='form-label'>Género</label>
                            <FormProvider {...methods}>
                                <SegSelect
                                    label=""
                                    selected={genero}
                                    setSelected={setGenero}
                                    name="genero"
                                    options={tiposGeneros}
                                    isGrupable
                                    width={"100%"}
                                    //borderColor={errors.tipoDoc ? "red" : ""}
                                    openDefault={false}
                                    autoFoco={false}
                                    placeholder=""
                                />
                            </FormProvider>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Fecha de nacimiento</label>
                            <div className='d-flex align-items-end gap-1 justify-content-between'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.dia"
                                        control={control}
                                        rules={{
                                            validate: {
                                                customValidation: (value) => {
                                                    const day = parseInt(value, 10);
                                                    const month = parseInt(getValues("fechaNac.mes"), 10);
                                                    const year = parseInt(getValues("fechaNac.anio"), 10);

                                                    if (!value) return "Día debe incluir 2 dígitos";
                                                    if (isNaN(day) || day <= 0) return "Día no puede ser menor o igual a 0";
                                                    if (day > 31) return "Día no puede ser mayor a 31";
                                                    if (month === 2 && day > 29) return "Febrero no puede tener más de 29 días";
                                                    if (month === 2 && day === 29 && !esAnioBisiesto(year)) {
                                                        return "El año no es bisiesto, febrero tiene máximo 28 días";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Dia"
                                                type="number"
                                                width={100}
                                                placeHolder="Día"
                                                value={field.value}
                                                autoFocus={false}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.dia", trimmedValue);
                                                    trigger("fechaNac.dia");
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.mes"
                                        control={control}
                                        rules={{
                                            validate: {
                                                customValidation: (value) => {
                                                    if (!value) {
                                                        return "Mes debe incluir 2 dígitos";
                                                    }
                                                    const parsedValue = parseInt(value, 10);
                                                    if (isNaN(parsedValue) || parsedValue > 12) {
                                                        return "Mes no puede ser mayor a 12";
                                                    }
                                                    if (isNaN(parsedValue) || parsedValue <= 0) {
                                                        return "Día no puede ser 0";
                                                    }
                                                    const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                    if (isSingleDigitWithoutZero) {
                                                        return "Mes debe incluir 2 dígitos";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Mes"
                                                type="number"
                                                width={100}
                                                placeHolder="Mes"
                                                value={field.value}
                                                autoFocus={false}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.mes", trimmedValue);
                                                    trigger("fechaNac.mes");
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.anio"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) =>
                                                    !!value || "Ingresá el año de nacimiento",
                                                pattern: (value) =>
                                                    /^(19\d{2}|20\d{2})$/.test(value) || "Año debe incluir 4 dígitos",
                                                ageValidation: (value) => {
                                                    const year = parseInt(value, 10);
                                                    const currentYear = new Date().getFullYear();
                                                    const age = currentYear - year;
                                                    if (age < 18) return "Edad mínima es de 18 años";
                                                    if (age > 65) return "Edad máxima es de 65 años";
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Anio"
                                                type="number"
                                                placeHolder="Año"
                                                value={field.value}
                                                autoFocus={false}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.anio", trimmedValue);
                                                    trigger("fechaNac.anio");
                                                }}
                                                width={100}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {formState.errors.fechaNac &&
                                <DivError>
                                    {[formState.errors.fechaNac.dia?.message, formState.errors.fechaNac.mes?.message, formState.errors.fechaNac.anio?.message]
                                        .filter(Boolean) // Filtramos valores nulos o indefinidos
                                        .join(', ')}
                                </DivError>
                            }

                        </div>
                        <div className={`${window.innerWidth < 778 ? "col-12 d-flex gap-5" : "col-4"}`}>
                            <label className='form-label'>¿Agregar beneficiario?</label>
                            <div className="col col-md-8 d-flex justify-content-between align-items-center mb-16">
                                <div className='d-flex gap-1'>
                                    <span><b>No</b></span>
                                    <div className="form-check form-switch" style={{ paddingLeft: "1.5em" }}>

                                        <input className="form-check-input" type="checkbox" role="switch"
                                            id="flexSwitchCheckDefault"
                                            onChange={handleSwitchChange}
                                            checked={agregarBeneficiario}
                                            style={{ marginLeft: "-1.5em" }} />
                                    </div>
                                    <span><b>Si</b></span>
                                </div>
                                <button
                                    ref={modalBeneficiarioRef}
                                    className=""
                                    style={{ display: "none" }}
                                    type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBeneficiario" aria-controls="offcanvasBeneficiario"></button>
                            </div>
                            {/*<p>{dataAsegurados[aseguradoActual].beneficiario?.nombre}</p>*/}
                        </div>
                        <div className='col-12'>
                            <div className={`${window.innerWidth < 768 ? "col-12" : "col-6"} gap-4`}>
                                {
                                    (CANTIDAD_ASEGURADOS > 1 && aseguradoActual > 0) &&
                                    <SegButton label='Anterior'
                                        type='button'
                                        onClick={handlePrevAsegurado}
                                        disabled={false}
                                        className={`bg-secondary ${window.innerWidth < 768 ? 'col-12 my-2' : 'fs-15 mx-2 col-4'}`} />
                                }
                                <SegButton label='Siguiente'
                                    type='submit'
                                    disabled={!formState.isValid}
                                    className={`${window.innerWidth < 768 ? 'col-12' : 'fs-15 col-4'}`} />
                            </div>
                        </div>
                        <>
                            {
                                (window.innerWidth < 778) &&
                                <div className='col-12 text-center'>
                                    <p className='fs-16 fw-semibold p-0 m-0'>
                                        {`${aseguradoActual + 1} de ${cotizacionValues.cantidadDeVidas} asegurados`}
                                    </p>
                                </div>
                            }
                        </>
                    </SegFormEmisionContainer>
                </form>
            </div>
        </div>
    )
}