import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Descripcion } from "../../../Services/Providers/DescripcionesStore";

interface Companias {
  nombreCompania: string;
  imgRuta: string;
  mostrar: boolean;
}

interface SegSliderProps {
  bgColor?: string;
  companias: Companias[];
  anchoImagen?: string;
  className?: string;
}

export const SegSlider = ({
  companias = [],
  anchoImagen = "100",
  className,
}: SegSliderProps) => {
  const companiasHabilitadas = companias.filter((compania) => compania.mostrar === true)
  if (((companiasHabilitadas.length < 5) && (window.innerWidth > 778)) || companiasHabilitadas.length < 3) {
    return (
      <div className="container" style={{ marginTop: "5%" }}>
        <div className='w-100 d-flex justify-content-center align-items-center'>
          {
            companias.filter(compania => compania.mostrar).map((comp, index) => (
              <img className="img-fluid" style={{ height: "auto", maxWidth: "150px" }} src={comp.imgRuta} alt={comp.nombreCompania} key={index}></img>
            ))
          }
        </div>
      </div>
    )
  }

  return (
    <Splide
      extensions={{ AutoScroll }}
      aria-label="Aseguradoras"
      hasTrack={false}
      options={{
        autoplay: true,
        type: "loop",
        drag: "free",
        // focus: "center",
        pagination: false,
        arrows: false,
        perPage: 6,
        perMove: 1,
        autoScroll: {
          speed: 1,
        },
        breakpoints: {
          576: {
            perPage: 2,
          },
          768: {
            perPage: 4,
          },
          992: {
            perPage: 5,
          },
        },
        interval: 100000,
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      }}
    >
      <SplideTrack className={className}>
        {companias.map(
          (compania, index) =>
            compania.mostrar && (
              <SplideSlide
                style={{ display: "flex", alignItems: "center" }}
                key={index}
              >
                <img
                  width={anchoImagen}
                  alt={compania.nombreCompania}
                  style={{ maxHeight: "50px" }}
                  className="img-fluid"
                  src={compania.imgRuta}
                />
              </SplideSlide>
            )
        )}
      </SplideTrack>
    </Splide>
  );
};
