import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegPatente } from "../../../SegUI/SegPatente/SegPatente";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { persist } from 'zustand/middleware';
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import obtenerAnios from "../../../../Services/API/Anios";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import obtenerAniosPorVersion from "../../../../Services/API/AniosByVersion";

interface ResultadoPatenteProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const ResultadoPatente = ({ config, setSteps, ...rest }: ResultadoPatenteProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [anioSelected, setAnioSelected] = useState<SegSelectOption>();
  const [anios, setAnios] = useState<SegSelectOption[]>();
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const methods = useForm();
  const { handleSubmit } = useForm<any>();

  useEffect(() => {
    getAniosModelo()
  }, []);

  const getAniosModelo = async () => {
    const response: any = await obtenerAniosPorVersion(cotizacionValues?.marca?.value || "", cotizacionValues?.version?.value || "");
    const formattedData: SegSelectOption[] = response.map((item: any, index: any) => ({
      value: item,
      label: item,
    }));
    setAnios(formattedData);
    if (cotizacionValues?.año && cotizacionValues.año.value != null) {
      if (response.includes(cotizacionValues.año.value.toString())) {
        setAnioSelected(cotizacionValues.año)
      }else{
        setOpenSelect(true);
      }
    }else{
      setOpenSelect(true);
    }
  }

  const vehiculoAntiguo = cotizacionValues.esAntiguo
  const añoMaximo = new Date().getFullYear() - parseInt(process.env.REACT_APP_ANIO_GRACIAS!) + 1

  const patenteIncorrecta = (e: any) => {
    e.preventDefault();
    setCotizacionValues({
      ...cotizacionValues,
      marca: undefined,
      modelo: undefined,
      version: undefined,
      año: undefined,
      patente: undefined
    })
    compania ? nav("/auto/cotizacion/" + compania + "/marca") : nav("/auto/cotizacion/marca");
  };

  const IngresarNuevaPatente = (e: any) => {
    e.preventDefault();
    setCotizacionValues({
      ...cotizacionValues,
      marca: undefined,
      modelo: undefined,
      version: undefined,
      año: undefined,
      patente: undefined
    })
    compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente");
  };

  useEffect(() => {
    !cotizacionValues.patente && (compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente"))
  }, [])

  const onSubmit: SubmitHandler<any> = (values: any) => {
    setCotizacionValues({
      ...cotizacionValues,
      año: anioSelected
    })
    compania ? nav("/auto/cotizacion/" + compania + "/nombre") : nav("/auto/cotizacion/nombre");
    // nav("/auto/cotizacion/nombre")
  };

  return (
    <div {...rest}>
      <div className="cot-heading02 text-center">
        <div className="container">
          <div className="title mt-3">¿Es este el auto?</div>
          <SegPatente text={cotizacionValues?.patente ?? ""} />
        </div>
      </div>
      <div className=" bg-gray-light text-center">
        <div className="container">
          <div className="frm01">
            <fieldset>
              <div className="mw-sm-450 m-auto">
                <div className="fs-24 fw-medium">{cotizacionValues?.marca?.label}</div>
                <div className="fs-20">{cotizacionValues?.version?.label}</div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-center">
                  <div className="col-10 col-sm-8 col-md-5 col-lg-3">
                    <FormProvider {...methods}>
                      {anios ? (
                        <SegSelect
                          options={anios}
                          width={"100%"}
                          height={(window.innerHeight <= 700) ? "sm" : (window.innerHeight <= 910) ? "md" : "lg"}
                          selected={anioSelected}
                          openDefault={openSelect}
                          setSelected={setAnioSelected}
                          name="anio"
                          placeholder="Escribí aquí el año"
                          hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                          focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                          empresaColor={config.origen !== "SEGURARSE" ? {
                            selected: config.colors.accent,
                            hover: config.colors.tertiary
                          } : undefined}
                        />
                      ) : (<div className='d-flex justify-content-center'>
                        <div className="spinner-border text-center" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>)}
                    </FormProvider>
                  </div>
                </div>
                <div className="form-buttons">
                  <SegButton
                    label="Continuar"
                    type="submit"
                    className="btn btn-primary"
                    disabled={vehiculoAntiguo || !anios || !anioSelected}
                    bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                    hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                    hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    hoverOpacity={config.origen !== "SEGURARSE"}
                  />
                </div>

              </form>
              <div className="form-alt">
                <a href="/#" onClick={patenteIncorrecta}>¿Nos equivocamos? </a>
              </div>
              <br />
              {vehiculoAntiguo ? <>
                <div style={{ margin: "20px auto" }} className="alert alert-danger" role="alert">
                  <div className="mw-sm-450 m-auto">
                    <div className="fs-20 fw-medium"> Modelo anterior a {añoMaximo} </div>
                    <div className="fs-16">Lo sentimos, pero en este momento no tenemos coberturas disponibles para ofrecerte. ¡Gracias por tu comprensión!
                    </div>
                  </div>
                </div>
                <div className="form-alt">
                  <p>¿Querés cotizar otro auto? Haz click <span><a href="/auto/cotizacion/patente" onClick={IngresarNuevaPatente}>Aquí</a></span></p>

                </div>
              </>
                : <></>}
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};
