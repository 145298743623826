import React, { forwardRef } from "react";
import styled from "@emotion/styled";

interface SegInputProps {
    width: number;
    label: string;
    isGroup?: boolean;
    labelGroup?: string;
    type: string;
    name: string;
    id?: string;
    placeHolder?: string;
    labelColor?: string;
    labelWidth?: string;
    labelGroupColor?: string;
    inputTextColor?: string;
    borderColor?: string;
    focusBorderColor?: string;
    className?: string;
    onChange?: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
    onFocus?: (e: React.FocusEvent<any>) => void;
    onKeyDown?: (e: React.KeyboardEvent<any>) => void;
    value?: string;
    autoFocus?: boolean;
    leyendaError?: string;
    disabled?: boolean;
    maxLenghtDigits?: number;
    minLenghtDigits?: number;
    min?: string | number | undefined;
    inputMode?: "search" | "text" | "none" | "email" | "tel" | "url" | "numeric" | "decimal" | undefined,
    absolut?: boolean;
    isLandingForm?: boolean;
}

const StyledDiv = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;
const Container = styled.div<{ width: number }>`
  width: ${({ width }) => width}% !important;
`;

const Label = styled.label<{ labelColor: string, labelWidth: string }>`
  color: ${({ labelColor }) => labelColor} !important;
  width:${({ labelWidth }) => labelWidth} !important;
`;

const Span = styled.span<{ borderColor: string }>`
  border: none;
  background: #f8f9fa;
  color: #000;
  padding: 0 10px;
  border: 1px solid #dee2e6;
  //margin-right: 1px;
  &:focus {
    box-shadow: none;
    border: 1px solid #dee2e6;
  }
`;

const Input = styled.input<{ leyendaError: string; inputTextColor: string; borderColor: string; }>`
  color: ${({ inputTextColor }) => inputTextColor} !important;
  border-color: ${({ borderColor }) => borderColor};
  &:focus-within {
    box-shadow: none;
    border-color: ${({ borderColor }) => borderColor};
  }
`;

const InputGroup = styled.div<{
    borderColor: string;
    focusBorderColor: string;
}>`
  border-radius: 4px;
  border-color: ${({ borderColor }) => borderColor} !important;
  &:focus-within {
    border-color: ${({ focusBorderColor }) => focusBorderColor} !important;
  }
`;

export const SegInput = forwardRef<HTMLInputElement, SegInputProps>(
    (
        {
            width = 100,
            label,
            isGroup = false,
            labelGroup = "0",
            type = "text",
            name,
            id,
            placeHolder,
            labelColor = "#000",
            labelWidth = 'auto',
            labelGroupColor = "#2c7dfd",
            inputTextColor = "#000",
            borderColor = "#000",
            focusBorderColor = "#2c7dfd",
            className,
            onChange,
            onBlur,
            onKeyDown,
            value = "",
            autoFocus = false,
            leyendaError = "",
            disabled = false,
            maxLenghtDigits,
            minLenghtDigits,
            min,
            inputMode,
            absolut = false,
            isLandingForm = false
        }, ref) => {
        return (
            <Container width={width} className={className}>
                {label !== "" ? (
                    <Label className="form-label" labelColor={labelColor} labelWidth={labelWidth}>
                        {label}
                    </Label>
                ) : (
                    ""
                )}
                <InputGroup
                    className="input-group"
                    borderColor={borderColor}
                    focusBorderColor={focusBorderColor}
                >
                    {isGroup ? (
                        <Span className="input-group-text fs-14" borderColor={borderColor}>
                            {labelGroup}
                        </Span>
                    ) : (
                        ""
                    )}
                    <Input
                        type={type}
                        className="form-control fs-14"
                        style={{padding: ".375rem .75rem"}}
                        name={name}
                        id={id}
                        placeholder={placeHolder}
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={value}
                        autoFocus={autoFocus}
                        leyendaError={leyendaError}
                        inputTextColor={inputTextColor}
                        ref={ref}
                        disabled={disabled}
                        maxLength={maxLenghtDigits}
                        minLength={minLenghtDigits}
                        min={min}
                        inputMode={inputMode}
                        borderColor={borderColor}
                    />
                </InputGroup>
                {leyendaError !== "" ? (
                    <StyledDiv className="error"
                        style={(absolut && window.innerWidth >= 768) ? { position: "absolute" } : {}}
                    >{leyendaError}</StyledDiv>
                ) : null}
            </Container>
        );
    }
)
