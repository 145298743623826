import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Cotizacion } from "../../../../Pages/Auto/Precios";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { SegSinCotis } from "../../../SegUI/SegSinCotis/SegSinCotis";
import Skeleton from "react-loading-skeleton";
import { useConfiguracionEmpresa } from "../../../../Services/Providers/useConfiguracionEmpresa";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface GrillaProps {
  cotizaciones: Cotizacion[];
  setDescripciones: (descripciones: any) => void;
  cotizando: boolean
}

const ordenColumnas: string[] = ["C", "B", "D", "A"];

export const Grilla: React.FC<GrillaProps> = ({ cotizaciones, setDescripciones, cotizando, ...rest }: GrillaProps) => {

  const StyledDiv = styled.div`
  margin: 8px 12px 8px 0px;
  padding: 5px 15px 12px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  background: #FFFEFC;
  box-shadow: 0px 0px 8px rgba(164, 164, 164, 0.3);
  border-radius: 5px;
  color: #363636;
  display: none;
  @media (min-width: 992px) {
    display:flex;
  }
`

  const [opcionFiltrado, setOpcionFiltrado] = useState<string>("");
  const [cotizacionesOriginales, setCotizacionesOriginales] = useState<Cotizacion[]>([]);
  const [cotizacionesFiltradasCobertura, setCotizacionesFiltradasCobertura] = useState<Cotizacion[]>([]);
  const [aseguradoras, setAseguradoras] = useState<string[]>([]);
  const [opcionAseguradora, setOpcionAseguradora] = useState<string>("0");
  //const [cotizacionesFiltradasAseguradora, setCotizacionesFiltradasAseguradora] = useState<Cotizacion[]>([]);
  const [ordenPrecio, setOrdenPrecio] = useState<string>("0");
  //const [ordenColumnas, setOrdenColumnas] = useState<string[]>(["C", "B", "D", "A"]);
  const [grillaKey, setGrillaKey] = useState<number>(0);
  const grillaRef = useRef<HTMLDivElement>(null);
  const [primeraCarga, setPrimeraCarga] = useState(true);
  const { config, origen } = useConfiguracionEmpresa();


  const filtrarCotizaciones = (cobertura: string, aseguradora: string, ordenPrecio: string): Cotizacion[] => {
    let cotizacionesFiltradas = cotizacionesOriginales;
    cotizacionesFiltradas = filtrarCoberturas(cotizacionesFiltradas, cobertura);
    cotizacionesFiltradas = filtrarPorAseguradora(cotizacionesFiltradas, aseguradora);
    cotizacionesFiltradas = ordenarCotizaciones(cotizacionesFiltradas, ordenPrecio);

    return cotizacionesFiltradas;
  };

  const handleFiltrosChange = (cobertura: string, aseguradora: string, ordenPrecio: string) => {
    filtrarCotizaciones(cobertura, aseguradora, ordenPrecio);
  };

  useEffect(() => {
    // Actualizar el estado de cotizacionesOriginales al inicio y cuando cambia la prop cotizaciones
    setCotizacionesOriginales(cotizaciones);
  }, [cotizaciones]);

  useEffect(() => {
    // Filtrar cotizaciones por cobertura seleccionada
    const cotizacionesFiltradasPorCobertura = filtrarCoberturas(cotizacionesOriginales, opcionFiltrado);
    setCotizacionesFiltradasCobertura(cotizacionesFiltradasPorCobertura);

    // Obtener aseguradoras únicas después del filtrado por cobertura
    const aseguradorasUnicas = obtenerAseguradorasUnicas(cotizacionesFiltradasPorCobertura);
    setAseguradoras(aseguradorasUnicas);
  }, [cotizacionesOriginales, opcionFiltrado]);


  /*useEffect(() => {
    // Filtrar cotizaciones por aseguradora seleccionada
    const cotizacionesFiltradasPorAseguradora = filtrarPorAseguradora(cotizacionesFiltradasCobertura, opcionAseguradora);
    setCotizacionesFiltradasAseguradora(cotizacionesFiltradasPorAseguradora);
  }, [cotizacionesFiltradasCobertura, opcionAseguradora]);*/

  const handleSelectChangeAseg = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setOpcionAseguradora(selectedValue);
    handleFiltrosChange(opcionFiltrado, selectedValue, ordenPrecio);
  };

  const obtenerAseguradorasUnicas = (cotizaciones: Cotizacion[]): string[] => {
    const aseguradorasSet = new Set<string>();
    cotizaciones.forEach(coti => aseguradorasSet.add(coti.NombreCompania));
    return Array.from(aseguradorasSet);
  };

  const filtrarPorAseguradora = (cotizaciones: Cotizacion[], aseguradora: string): Cotizacion[] => {
    if (aseguradora === "0") {
      // Si la opción seleccionada es "Todas", mostrar todas las cotizaciones
      return cotizaciones;
    }

    return cotizaciones.filter(objeto => objeto.NombreCompania === aseguradora);
  };

  //TODO: VER ESTO
  /*useEffect(() => {
    // Utilizar la información filtrada por aseguradora para mostrar
    const cotizacionesAjustadas = opcionAseguradora === "0" ? cotizacionesFiltradasCobertura : cotizacionesFiltradasAseguradora;
  }, [cotizacionesFiltradasCobertura, cotizacionesFiltradasAseguradora, opcionAseguradora]);*/


  const handleSelectChangeCob = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setOpcionFiltrado(selectedValue);
    handleFiltrosChange(selectedValue, opcionAseguradora, ordenPrecio);
  };

  const filtrarCoberturas = (cotizaciones: Cotizacion[], opcionFiltrado: string) => {
    if (opcionFiltrado === "") {
      // Si la opción seleccionada es "Todas las coberturas", mostrar todas las cotizaciones
      return cotizaciones.map(coti => ({ ...coti, Mostrar: true }));
    }

    if (cotizaciones) {
      return cotizaciones.map(objeto => ({
        ...objeto,
        Mostrar: objeto.columna === opcionFiltrado
      })).sort((a, b) => {
        return ordenColumnas.indexOf(a.columna) - ordenColumnas.indexOf(b.columna);
      });
    } else {
      return [];
    }
  }

  const ordenarCotizaciones = (cotizaciones: Cotizacion[], ordenPrecio: string): Cotizacion[] => {
    if (ordenPrecio === "0") {
      // Si la opción seleccionada es "Sin orden", no realizar ningún ordenamiento
      return cotizaciones;
    }

    // Ordenar por precio
    const sortedCotizaciones = cotizaciones.slice().sort((a, b) => {
      const precioA = parseFloat(a.premio.replace("$", "").replace(",", ""));
      const precioB = parseFloat(b.premio.replace("$", "").replace(",", ""));
      return ordenPrecio === "1" ? precioA - precioB : precioB - precioA;
    });

    return sortedCotizaciones;
  };

  const cotizacionesAjustadas = useMemo(() => {
    return filtrarCotizaciones(opcionFiltrado, opcionAseguradora, ordenPrecio);
  }, [cotizacionesOriginales, opcionFiltrado, opcionAseguradora, ordenPrecio]);

  useEffect(() => {
    // Ordenar las cotizaciones según la opción de precio seleccionada
    const cotizacionesOrdenadas = ordenarCotizaciones(cotizacionesAjustadas, ordenPrecio);
  }, [cotizacionesAjustadas, ordenPrecio]);

  const handleSelectChangePrecio = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setOrdenPrecio(selectedValue);
    handleFiltrosChange(opcionFiltrado, opcionAseguradora, selectedValue);
  };

  useEffect(() => {
    setPrimeraCarga(false);
    if (!primeraCarga) {
      setGrillaKey((prevKey) => prevKey + 1);
      if (grillaRef.current) {
        // Obtenemos la posición del contenedor de la grilla
        const grillaOffsetTop = grillaRef.current.offsetTop - 180;

        // Hacemos scroll al principio del contenedor
        window.scrollTo({ top: grillaOffsetTop, behavior: 'smooth' });
      }
    }
  }, [opcionFiltrado, opcionAseguradora, ordenPrecio]);

  const handleDescripcion = (event: React.MouseEvent, coti: Cotizacion) => {
    if (coti) {
      const nuevaDesc: any = {
        id: coti.id,
        amountMonth: coti.premio,
        companyLogo: coti.LogoCompania,
        companyName: coti.NombreCompania,
        codCompany: coti.CodigoCompania,
        descList: coti.descripciones,
        discountAmount: coti.PrecioSinDescuento,
        discountPerc: coti.DescuentoPorcentaje,
        fiscalInfoImg: coti.Afip,
        nombreCompania: coti.NombreCompania,
        planName: coti.nombreCobertura,
        sumaAsegurada: coti.SumaAsegurada
      };
      setDescripciones(nuevaDesc);
    }
  };

  const renderIconDescResumen = (value: string): ReactElement => {
    if (value === "No" || value == "No aplica")
      //return <use xlinkHref="#x-circle" />
      return <HighlightOffIcon style={{ height: "40px", width: "40px", color: "#a5a5a5" }} />

    //return <use xlinkHref="#check-circle" />
    return <CheckCircleOutlineIcon style={{ height: "40px", width: "40px", color: config.origen !== "SEGURARSE" ? config.colors.secondary : "#2c7dfd" }} />
  }

  return (
    <>
      <div className="filter-table d-none d-lg-block">
        <div className="sticky-lg-top">
          <div className="ft-filters" style={{ background: config.colors.primary }}>
            <form className="container-fluid" >
              <div className="row gx-5" >
                <div className="col">
                  <div className="row g-3 align-items-center">
                    <label htmlFor="cbo1" className="col-auto col-form-label">Cobertura</label>
                    <div className="col">
                      <select className="form-select form-select-sm" id="cbo1" onChange={handleSelectChangeCob} value={opcionFiltrado}>
                        <option value="">Todas las coberturas</option>
                        <option value="C">Terceros Completo Full</option>
                        <option value="B">Terceros Completo</option>
                        <option value="D">Todo Riesgo</option>
                        <option value="A">Responsabilidad civil</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row g-3 align-items-center">
                    <label htmlFor="cbo2" className="col-auto col-form-label">Aseguradora</label>
                    <div className="col">
                      <select
                        className="form-select form-select-sm"
                        id="cbo2"
                        onChange={handleSelectChangeAseg}
                        value={opcionAseguradora}
                      >
                        <option value="0">Todas</option>
                        {aseguradoras.map((aseguradora, index) => (
                          <option key={index} value={aseguradora}>
                            {aseguradora}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row g-3 align-items-center">
                    <label htmlFor="cbo3" className="col-auto col-form-label">Precio</label>
                    <div className="col">
                      <select
                        className="form-select form-select-sm"
                        id="cbo3"
                        onChange={handleSelectChangePrecio}
                        value={ordenPrecio}
                      >
                        <option value="0">Por default</option>
                        <option value="1">Menor a mayor</option>
                        <option value="2">Mayor a menor</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="ft-header" style={{ background: config.colors.primary }}>
            <div className="card3 card3-layout">
              <div className="header"></div>
              <div className="items">
                <div className="item">Cristales</div>
                <div className="item">Franquicia</div>
                <div className="item">Cobertura en Exterior</div>
                <div className="item">Auxilio</div>
                <div className="item">Granizo</div>
              </div>
              <div className="actions"></div>
            </div>
          </div>
        </div>
        <div key={grillaKey} className="ft-rows" ref={grillaRef}>
          {cotizacionesAjustadas &&
            cotizacionesAjustadas.map(
              (coti, index) =>
                coti.Mostrar && (
                  <div
                    className="card3 card3-layout"
                    key={index}
                  >
                    <div className="header">
                      <div className="col-6">
                        <div className="empresa">
                          <img
                            src={coti.LogoCompania}
                            height="45"
                            style={{ maxWidth: '100px' }}
                            alt={coti.NombreCompania}
                            loading="lazy"
                          />
                        </div>
                        <div className="cobertura" style={{ color: config.colors.secondary }}>
                          <b>{coti.nombreCobertura}</b>
                        </div>
                        {/* <div className="rating">Excelente 8.0</div> */}
                      </div>

                      <div className="col-6">
                        <div className="cont">
                          <div className="descuento">
                            <span className="antes">{"$" + coti.PrecioSinDescuento}</span>
                            {/* <span className="ahora">-30%</span> */}
                          </div>

                          <div className="precio">
                            <span className="monto">{"$" + coti.premio}</span>
                            <span className="periodo"> /mes</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        {renderIconDescResumen(coti.DescResumen[0])}
                      </div>
                      <div className="item">
                        {renderIconDescResumen(coti.DescResumen[1])}
                      </div>
                      <div className="item">
                        {renderIconDescResumen(coti.DescResumen[2])}
                      </div>
                      <div className="item">
                        {renderIconDescResumen(coti.DescResumen[3])}
                      </div>
                      <div className="item">
                        {renderIconDescResumen(coti.DescResumen[4])}
                      </div>
                    </div>
                    <div className="actions">
                      <Link
                        className="btn1 btn1-primary"
                        data-bs-toggle="offcanvas" to={"#offcanvas"} role="button"
                        onClick={(e: any) => handleDescripcion(e, coti)}
                        style={{ background: config.colors.primary }}
                      >
                        Ver detalle{" "}
                        <svg className="icon">
                          <use xlinkHref="#arrowright2" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                )
            )}
          {
            ["A", "B", "C", "D", ""].map(col => {
              if (opcionFiltrado === col && cotizando) {
                return (
                  <StyledDiv key={"skeloton-grilla-" + col}>
                    <Skeleton
                      containerClassName="col-2"
                      count={1}
                      circle={true}
                      style={{ borderRadius: "100%", width: "54px", height: "54px" }} />
                    <div className="col-10 d-flex gap-1">
                      {[1, 2, 3, 4, 5, 6, 7].map(ske => (
                        <Skeleton
                          key={"skeleton-" + ske}
                          containerClassName="col-1 skeleton-container mx-4"
                          count={1}
                          circle={false}
                          style={{ height: "12px", margin: "10px 0px" }}
                        />
                      ))}
                    </div>
                  </StyledDiv>
                )
              } else if (!cotizando && opcionFiltrado === col && opcionFiltrado !== "" && cotizacionesAjustadas.filter((coti) => coti.columna === col).length === 0) {
                return <SegSinCotis mostrarWpp={origen === "SEGURARSE"} />
              }
            })
          }
          {
            (!cotizando && opcionFiltrado === "" && cotizacionesAjustadas.length === 0)
            && <SegSinCotis mostrarWpp={origen === "SEGURARSE"} />
          }
          {/*
          {opcionFiltrado === "" && cotizacionesAjustadas.length === 0 &&
            (cotizando ?
              <StyledDiv>
                <Skeleton
                  containerClassName="col-4"
                  count={1}
                  circle={true}
                  style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                <Skeleton
                  containerClassName="col-6 skeleton-container"
                  count={1}
                  circle={false}
                  style={{ height: "15px", margin: "10px 0" }}
                />
              </StyledDiv>
              :
              <SegSinCotis />
            )
          }
          {opcionFiltrado === "A" && cotizacionesAjustadas.filter((coti) => coti.columna === "A").length === 0 &&
            (cotizando ?
              <StyledDiv>
                <Skeleton
                  containerClassName="col-4"
                  count={1}
                  circle={true}
                  style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                <Skeleton
                  containerClassName="col-6 skeleton-container"
                  count={1}
                  circle={false}
                  style={{ height: "15px", margin: "10px 0" }}
                />
              </StyledDiv> :
              <SegSinCotis />
            )
          }
          {opcionFiltrado === "B" && cotizacionesAjustadas.filter((coti) => coti.columna === "B").length === 0 &&
            (cotizando ?
              <StyledDiv>
                <Skeleton
                  containerClassName="col-4"
                  count={1}
                  circle={true}
                  style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                <Skeleton
                  containerClassName="col-6 skeleton-container"
                  count={1}
                  circle={false}
                  style={{ height: "15px", margin: "10px 0" }}
                />
              </StyledDiv> :
              <SegSinCotis />
            )
          }
          {opcionFiltrado === "C" && cotizacionesAjustadas.filter((coti) => coti.columna === "C").length === 0 &&
            (cotizando ?
              <StyledDiv>
                <Skeleton
                  containerClassName="col-4"
                  count={1}
                  circle={true}
                  style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                <Skeleton
                  containerClassName="col-6 skeleton-container"
                  count={1}
                  circle={false}
                  style={{ height: "15px", margin: "10px 0" }}
                />
              </StyledDiv> :
              <SegSinCotis />
            )
          }
          {opcionFiltrado === "D" && cotizacionesAjustadas.filter((coti) => coti.columna === "D").length === 0 &&
            (cotizando ?
              <StyledDiv>
                <Skeleton
                  containerClassName="col-4"
                  count={1}
                  circle={true}
                  style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                <Skeleton
                  containerClassName="col-6 skeleton-container"
                  count={1}
                  circle={false}
                  style={{ height: "15px", margin: "10px 0" }}
                />
              </StyledDiv>
              :
              <SegSinCotis />
            )
          }
          */}
        </div>
      </div>
    </>
  );
};
