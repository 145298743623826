import Axios from 'axios'
import { getPathName, retryRequest } from './helper';

const sendArrepentimiento = async (stringContacto, captchaToken) => {
  // const data = {
  //   data: stringContacto,
  //   g_recaptcha_response: captchaToken
  // }
  const data = {
    htmlBody: stringContacto
  };
  const headers = {
    'content-type': 'application/json',
    "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
  }

  const axiosPostRequest = () => {
    return Axios.post(getPathName() + "/SendMailFormularioArrepentimiento", data, {
      headers: headers
    });
  };

  try {
    const response = await retryRequest(axiosPostRequest);
    return response;
  } catch (error) {
    console.error('Fallo de hacer varios intentos: ' + error.message);
    return error;
  }
};



export default sendArrepentimiento;