import { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore';
import { TelefonosCompañia, telefonos } from '../../Assets/telefonosCompanias';
import { useDescripcionesStore } from '../../Services/Providers/DescripcionesStore';
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo';
import segurarse from "../../Assets/img/logo_segurarse.svg";
import { SegStepper } from '../../Components/SegUI/SegStepper/SegStepper';
import { TipoDocumento } from '../../Components/Slides/AP/TipoDocumento';
import { TipoPersona } from '../../Components/Slides/AP/TipoPersona';
import { NombreApellido } from '../../Components/Slides/AP/individuo/NombreApellido';
import { Telefono } from '../../Components/Slides/AP/Telefono';
import { Email } from '../../Components/Slides/AP/Email';
import { CodigoPostalManual } from '../../Components/Slides/AP/CodigoPostalManual';
import { CondicionIVA } from '../../Components/Slides/AP/CondicionIVA';
import { DatosAsegurado } from '../../Components/Slides/AP/DatosAsegurado';
import { DatosCobertura } from '../../Components/Slides/AP/DatosCobertura';
import useSEOConfig from '../../hooks/seo/useSEOConfig';
import parametrosInicialesAP from "../../Services/Data/ParametrosInicialesAP.json";

export interface IPasos {
  step: number;
  subStep: number;
}

export interface CotizadorProps {
  step?: number;
  subStep?: number;
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
`;

const Content = styled.div`
  flex-grow: 1;
`;

export interface IPasos {
  step: number;
  subStep: number;
}

export const Cotizador = ({ step = 0, subStep = 1 }: CotizadorProps) => {

  const { compania } = useParams();
  const nav = useNavigate();

  const Icon = styled.svg`
    color: #343434 !important;
  `;
  const referer = document.referrer;
  const landingUrl = document.location.href;
  const [steps, setSteps] = useState<IPasos>({ step, subStep });
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [paramsCargando, setParamsCargando] = useState(false);
  const [justifyContent, setJustifyContent] = useState(
    "justify-content-center"
  );
  const {
    descripcionesValues,
    setDescripcionesValues,
  } = useDescripcionesStore();

  useSEOConfig({
    title: "Accidentes Personales",
  })

  const tel = telefonos.filter((t: TelefonosCompañia) => {
    if (t.clave === compania) {
      return t;
    }
  });

  useEffect(() => {
    function ajustarJustifyContent() {
      if (window.innerWidth >= 768) {
        setJustifyContent("col-md-3 text-md-end align-self-center");
      } else {
        setJustifyContent(
          "d-flex align-items-center justify-content-center mt-3"
        );
      }
    }

    // Llamar a la función al cargar la página y al cambiar el tamaño de la ventana
    ajustarJustifyContent();
    window.addEventListener("resize", ajustarJustifyContent);

    // Limpieza del listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", ajustarJustifyContent);
    };
  }, []);

  useEffect(() => {
    //if (!cotizacionValues.parametrosIniciales) {
      setParamsCargando(true);
      setCotizacionValues({
        ...cotizacionValues,
        parametrosIniciales: parametrosInicialesAP,
        referer: referer,
        landingUrl: landingUrl,
      });
      setParamsCargando(false)
    //}
  }, []);


  useEffect(() => {
    setSteps((prev) => {
      return {
        ...prev,
        step: step,
        subStep: subStep,
      };
    });
  }, [step, subStep]);



  const renderVista = () => {

    switch (steps.step) {
      case 0:
        switch (steps.subStep) {
          case 1: return <TipoDocumento />
          case 2: return <TipoPersona />
          case 3: return <NombreApellido
            setSteps={setSteps}
            nombreValue={cotizacionValues.nombre}
            apellidoValue={cotizacionValues.apellido}
          />
          case 4: return <Telefono
            setSteps={setSteps}
            telefonoValue={cotizacionValues.telefono}
          />
          case 5: return <Email
            setSteps={setSteps}
            emailValue={cotizacionValues.email}
          />
        }
        break;
      case 1:
        switch (steps.subStep) {
          case 1: return <CodigoPostalManual
            setSteps={setSteps}
          />
          case 2: return <CondicionIVA
            setSteps={setSteps}
          />
          case 3: return <DatosAsegurado
            setSteps={setSteps}
          />
          case 4: return <DatosCobertura
            setSteps={setSteps}
          />
        }
        break
      default:
        return <h1>No se encontró step</h1>;
    }
    return (
      <>
      </>
    )
  }


  return (
    <>
      <PageContainer>
        <div className="sticky-top bg-white">
          <div className="container pt-3 pb-3">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                {" "}
                {/* Centra el contenido en la columna */}
                <SegHeaderLogo
                  img={segurarse}
                  alt="Logo Segurarse"
                  width="160px"
                />
              </div>
              {compania && tel.length > 0 && (
                <div className={`${justifyContent}`}>
                  {" "}
                  <a
                    href={"tel:" + tel[0]?.telefono}
                    className="tel align-items-center gap-2"
                  >
                    <Icon className="icon">
                      <use xlinkHref="#tel" />
                    </Icon>
                    {tel[0]?.telefono}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <SegStepper
            icon="ap"
            label="Cotizá tu seguro de Accidentes Personales"
            stepActive={steps.step === 0 ? 1 : 2}
            isStepper={true}
            finalStep="art"
          />
        </div>

        {paramsCargando ? (
          <div className="d-flex justify-content-center py-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Content>
            <div className="">{renderVista()}</div>
          </Content>
        )}
      </PageContainer>
    </>

  )
}
