import { useEffect } from "react";

export interface SeoProps {
    title?: string;
    author?: string;
    description?: string;
    keywords?: string;
    canonicalUrl?: string;
    robots?: string;

    //openGraph tag
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    ogSiteName?: string;

    //Twitter tag
    twitterCard?: string;
    twitterSite?: string;
    twitterImage?: string;
    twitterTitle?: string;
    twitterDescription?: string;

    marcaBlanca?: string;
}

const useSEOConfig = (props: SeoProps) => {
    useEffect(() => {
        if (props.title) {
            document.title = `${props.marcaBlanca ? props.marcaBlanca : "Segurarse"} | ${props.title}`;
        }

        const updateMetaTag = (name: string, content?: string) => {
            if (content) {
                let meta = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement;
                if (!meta) {
                    meta = document.createElement('meta');
                    meta.name = name;
                    document.head.appendChild(meta);
                }
                meta.content = content;
            }
        };

        const updatePropertyMetaTag = (property: string, content?: string) => {
            if (content !== undefined) {
                let meta = document.querySelector(`meta[property="${property}"]`);
                if (!meta) {
                    meta = document.createElement('meta');
                    meta.setAttribute('property', property);
                    document.head.appendChild(meta);
                }
                meta.setAttribute('content', content);
            }
        };

        updateMetaTag('author', props.author);
        updateMetaTag('description', props.description);
        updateMetaTag('keywords', props.keywords);

        updatePropertyMetaTag('og:title', props.ogTitle);
        updatePropertyMetaTag('og:description', props.ogDescription);
        updatePropertyMetaTag('og:image', props.ogImage);
        updatePropertyMetaTag('og:url', props.canonicalUrl);
        updatePropertyMetaTag('og:type', 'website');

        updateMetaTag('twitter:card', props.twitterCard);
        updateMetaTag('twitter:title', props.twitterTitle || props.ogTitle);
        updateMetaTag('twitter:description', props.twitterDescription || props.ogDescription);
        updateMetaTag('twitter:image', props.twitterImage || props.ogImage);
        updateMetaTag('twitter:site', props.twitterSite || props.ogSiteName);


        // Actualizar el tag robots
        if (props.robots) {
            updateMetaTag('robots', props.robots);
        }

        // Actualizar el canonical URL
        if (props.canonicalUrl) {
            let link = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'canonical';
                document.head.appendChild(link);
            }
            link.href = props.canonicalUrl;
        }
    }, [props]);
};

export default useSEOConfig;