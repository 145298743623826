import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import segurarse from "../../Assets/img/logo_segurarse.svg";
import afip from "../../Assets/img/DATAWEB.jpg";
import "@splidejs/react-splide/css";
import { imagesCompanias } from "../../Assets/imageCompania";
import { SegSvg } from "../../Components/SegUI/SegSvg/SegSvg";
import { useEffect, useMemo, useState } from "react";
import { ModalPrecios } from "../../Components/Slides/Auto/Precios/ModalPrecios";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../Services/Providers/CotizacionStore";
import cotizar from "../../Services/API/Cotizar";
import { Slider } from "../../Components/Slides/Auto/Precios/Slider";
import { Grilla } from "../../Components/Slides/Auto/Precios/Grilla";
import guardarContacto from "../../Services/API/GuardarContacto";
import { SegLoading } from "../../Components/SegUI/SegLoading/SegLoading";
import { Cards } from "../../Components/Slides/Auto/Precios/Cards";
import { GraciasAuto } from "../Gracias/GraciasAuto";
import axios from "axios";
import { useDescripcionesStore } from "../../Services/Providers/DescripcionesStore";
import { useEmisionStore } from "../../Services/Providers/EmisionStore";
import useSEOConfig from "../../hooks/seo/useSEOConfig";
import { useConfiguracionEmpresa } from "../../Services/Providers/useConfiguracionEmpresa";

export interface Cotizacion {
    PrecioSinDescuento: string;
    DescuentoPorcentaje: number;
    id: null | string;
    nroCotizacion: string;
    codigoCobertura: string;
    descripcionCobertura: string;
    descripcionCoberturaOriginal: string;
    descripciones: Descripcion[];
    nombreCobertura: string;
    prima: number;
    columna: string;
    franquicia: string;
    premio: string;
    Parametros: string;
    SumaAsegurada: string;
    DescResumen: string[];
    LogoCompania: string;
    NombreCompania: string;
    CodigoCompania?:string;
    Mostrar: boolean;
    Afip: string;
    EmisionHabilitada: boolean;
}

interface Descripcion {
    Nombre: string;
    Descripcion: string;
    Aclaracion: null | string;
}

export interface CotizacionDesc {
    id: string;
    planName: string;
    discountAmount: string;
    discountPerc: string;
    amountMonth: string;
    descList: any[];
    companyLogo: string;
    companyName: string;
    codCompany?:string;
    fiscalInfoImg: string;
    sumaAsegurada: string;
}

export const Precios = () => {
    const { emisionValues, setEmisionValues } = useEmisionStore();
    const { config, origen } = useConfiguracionEmpresa();
    const {
        cotizacionValues,
        setCotizacionValues,
        vaciarCotizaciones,
    } = useCotizacionStore();
    const { descripcionesValues } = useDescripcionesStore();
    const [cotizando, setCotizando] = useState<boolean>(false);
    const [cargando, setCargando] = useState<boolean>(false);
    const { compania } = useParams();
    const nav = useNavigate();

    const [isCollapsed, setCollapsed] = useState(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const handleScroll = () => {
        // Aquí puedes añadir lógica para determinar si el componente está abierto
        // y luego colapsarlo si es necesario
        if (!isCollapsed) {
            // Lógica para colapsar el componente
            setCollapsed(true);
        }
    };

    const handleSetCotizacionDesc = (cotizacion: CotizacionDesc) => {
        setEmisionValues({
            ...emisionValues,
            cotizacionDesc: cotizacion,
        });
    };

    useSEOConfig({
        title: "Auto",
        marcaBlanca: (config.origen !== "" && config.origen !== "SEGURARSE") ? config.origen.charAt(0).toUpperCase() +
            config.origen.slice(1).toLowerCase() : undefined
    })

    useEffect(() => {
        // Añade el event listener al objeto window
        window.addEventListener("scroll", handleScroll);
        // Limpia el event listener al desmontar el componente
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isCollapsed]);

    const toggleCollapse = () => {
        // Invierte el estado de colapsado al hacer clic en el enlace
        setCollapsed(!isCollapsed);
    };

    const ajustarDescripciones = async (coti: any) => {
        try {
            let descripciones: Descripcion[] = [];
            const descripcionesGuardadas = descripcionesValues;
            (Object.keys(
                descripcionesGuardadas
            ) as (keyof typeof descripcionesGuardadas)[]).forEach((key, index) => {
                const desc = descripcionesGuardadas[index];
                if (
                    //desc.CompaniaDescripcion.replace(/\s+/g, '').toLowerCase() === coti.NombreCompania.replace(/\s+/g, '').toLowerCase() &&
                    desc.CompaniaDescripcion === coti.NombreCompania &&
                    desc.CodigoCompania === coti.codigoCobertura
                ) {
                    let texto;
                    if (desc.Texto === "si") {
                        texto = "Si";
                    } else if (desc.Texto === "no") {
                        texto = "No";
                    } else {
                        texto = desc.Texto;
                    }
                    let nuevaDesc: Descripcion = {
                        Nombre: desc.ItemDescripcion2Nombre,
                        Aclaracion: desc.Aclaraciones,
                        Descripcion: texto,
                    };
                    descripciones.push(nuevaDesc);
                }
            });
            return descripciones;
        } catch (error) { }
    };

    const descripcionesResumen = (descripciones: any[]) => {
        // Crea un objeto que mapee los nombres de cobertura con sus descripciones
        const descripcionMap: { [nombre: string]: string } = {};
        descripciones.forEach((desc: any) => {
            descripcionMap[desc.Nombre] = desc.Descripcion || "No";
        });

        // Obtén las descripciones cortas
        const textosCortos = [
            descripcionMap["Cristales Laterales"] || "No",
            descripcionMap["Daño Parcial por Accidente con franquicia"] ||
            "No aplica",
            descripcionMap["Cobertura en Exterior"] || "No",
            descripcionMap["Auxilio Mecánico y Remolque"] || "No",
            descripcionMap["Granizo"] || "No",
        ];

        return textosCortos;
    };

    // const descripcionesResumen = (descripciones: any[]) => {
    //     var textosCortos = [
    //         descripciones.find((desc: any) => desc.Nombre === "Cristales Laterales") ? (descripciones.find((desc: any) => desc.Nombre === "Cristales Laterales").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Cristales Laterales").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Reposición a 0km") ? (descripciones.find((desc: any) => desc.Nombre === "Reposición a 0km").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Reposición a 0km").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Cobertura en Exterior") ? (descripciones.find((desc: any) => desc.Nombre === "Cobertura en Exterior").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Cobertura en Exterior").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Auxilio Mecánico y Remolque") ? (descripciones.find((desc: any) => desc.Nombre === "Auxilio Mecánico y Remolque").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Auxilio Mecánico y Remolque").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Granizo") ? (descripciones.find((desc: any) => desc.Nombre === "Granizo").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Granizo").Descripcion : "No") : "No",
    //     ]
    //     return textosCortos;
    // }

    const descripcionCobertura = (columna: string) => {
        switch (columna) {
            case "A":
                return "Responsabilidad Civil";
            case "B":
                return "Terceros Completo";
            case "C":
                return "Terceros Completo Full";
            case "D":
            case "E":
                return "Todo Riesgo";
            default:
                return "";
        }
    };

    const cotizarInterno = async (compania: any, indexCompania: number) => {
        let nuevoArray: Cotizacion[] = [];
        try {
            const response: any = await cotizar(
                compania.codigoCompania,
                cotizacionValues.contactoId!,
                compania.emisionOnlineHabilitada,
                cotizacionValues.token!,
                source
            );
            if (response.data.api_status) {
                if (
                    response.data.api_status === 200 &&
                    response.data.api_data !== null
                ) {
                    if (response.data.api_data.items.length > 0) {
                        for (
                            let index = 0;
                            index < response.data.api_data.items.length;
                            index++
                        ) {
                            let cotiNueva = response.data.api_data.items[index];
                            let auxPrecio: number = Number.parseInt(cotiNueva.premio);
                            let auxSuma: number = cotiNueva.SumaAsegurada
                                ? Number.parseInt(cotiNueva.SumaAsegurada.toString())
                                : Number.parseInt(
                                    response.data.api_data.sumaAsegurada.toString()
                                );
                            cotiNueva.DescuentoPorcentaje = 30;
                            cotiNueva.PrecioSinDescuento = (auxPrecio * 1.2).toLocaleString(
                                "es-AR"
                            );
                            cotiNueva.LogoCompania = encontrarRuta(compania.codigoCompania);
                            cotiNueva.NombreCompania = compania.nombreCompania;
                            cotiNueva.CodigoCompania = compania.codigoCompania;
                            cotiNueva.Mostrar = true;
                            cotiNueva.Afip = afip;
                            cotiNueva.premio = auxPrecio.toLocaleString("es-AR");
                            cotiNueva.SumaAsegurada = auxSuma
                                ? auxSuma.toLocaleString("es-AR")
                                : "";
                            cotiNueva.nombreCobertura = descripcionCobertura(
                                cotiNueva.columna
                            );
                            cotiNueva.descripciones = await ajustarDescripciones(cotiNueva);
                            cotiNueva.DescResumen = descripcionesResumen(
                                cotiNueva.descripciones
                            );
                            cotiNueva.columna =
                                cotiNueva.columna === "E" ? "D" : cotiNueva.columna;
                            cotiNueva.EmisionHabilitada = compania.emisionOnlineHabilitada;
                            if (cotiNueva.descripciones.length > 0) {
                                nuevoArray.push(cotiNueva);
                            }
                            if (index === response.data.api_data.items.length - 1) {
                                if (nuevoArray.length > 0) {
                                    return nuevoArray;
                                } else {
                                    return [];
                                }
                            }
                        }
                    }
                }
                return [];
            }
        } catch (error) { }
        return [];
    };

    useEffect(() => {
        vaciarCotizaciones();
        //cotizacionValues.cotizaciones = [];
        if (cotizacionValues.parametrosIniciales) {
            if (!esVigenciaCotizacionesValida()) {
                setTimeout(() => {
                    setCargando(false);
                }, 10000);
                setCargando(true);
            }
            if (!cotizacionValues?.contactoId) {
                const fetchData = async () => {
                    const response: any = await guardarContacto(
                        cotizacionValues?.parametrosIniciales.rubro,
                        cotizacionValues?.parametrosIniciales.origen,
                        cotizacionValues?.parametrosIniciales.tipoCotizador,
                        cotizacionValues?.landingUrl,
                        cotizacionValues?.referer,
                        cotizacionValues?.patente,
                        cotizacionValues?.marca?.value,
                        cotizacionValues?.modelo?.value,
                        cotizacionValues?.version?.value,
                        cotizacionValues?.año?.value,
                        cotizacionValues?.email,
                        cotizacionValues?.telefono?.prefijo,
                        cotizacionValues?.telefono?.numero,
                        cotizacionValues?.fechaNac?.dia +
                        "/" +
                        cotizacionValues?.fechaNac?.mes +
                        "/" +
                        cotizacionValues?.fechaNac?.año,
                        cotizacionValues?.genero?.value === "M" ? "Masculino" : "Femenino",
                        cotizacionValues?.provincia?.value,
                        cotizacionValues?.localidad?.value,
                        cotizacionValues?.nombre,
                        cotizacionValues?.apellido,
                        cotizacionValues?.cuandoContrata?.label,
                        cotizacionValues?.captchaToken
                    );
                    if (response.data) {
                        if (
                            response.data.api_status === 200 &&
                            response.data.api_data !== null
                        ) {
                            setCotizacionValues({
                                ...cotizacionValues,
                                contactoId: response.data.api_data.idContacto,
                                sumaAseguradaIA: response.data.api_data.sumaAseguradaIA,
                                token: response.data.api_data.token,
                            });
                        } else if (
                            response.data.api_data ===
                            "El Mail informado no es un mail válido"
                        ) {
                            compania
                                ? nav(
                                    "/auto/cotizacion/" +
                                    compania +
                                    "/email?error=Por favor, ingresá una dirección de correo electrónico válida"
                                )
                                : nav(
                                    "/auto/cotizacion/email?error=Por favor, ingresá una dirección de correo electrónico válida"
                                );
                        } else if (response.data.api_data === "El Captcha no es válido") {
                            compania
                                ? nav(
                                    "/auto/cotizacion/" +
                                    compania +
                                    "/codigo-postal?error=El Captcha no es válido"
                                )
                                : nav(
                                    "/auto/cotizacion/codigo-postal?error=El Captcha no es válido"
                                );
                        } else if (response.data.api_data === "Origen Inválido") {
                            compania
                                ? nav(
                                    "/auto/cotizacion/" +
                                    compania +
                                    "/patente?error=Orígen inválido"
                                )
                                : nav("/auto/cotizacion/patente?error=Orígen inválido");
                        } else if (
                            response.data.api_data === "El Teléfono informado no es válido"
                        ) {
                            compania
                                ? nav(
                                    "/auto/cotizacion/" +
                                    compania +
                                    "/telefono?error=Por favor, ingresá un n° de teléfono válido"
                                )
                                : nav(
                                    "/auto/cotizacion/telefono?error=Por favor, ingresá un n° de teléfono válido"
                                );
                        } else if (
                            response.data.api_data === "La localidad no es válida."
                        ) {
                            compania
                                ? nav(
                                    "/auto/cotizacion/" +
                                    compania +
                                    "/codigo-postal?error=Por favor, ingresá nuevamente la localidad"
                                )
                                : nav(
                                    "/auto/cotizacion/codigo-postal?error=Por favor, ingresá nuevamente la localidad"
                                );
                        } else if (
                            response.data.api_data.startsWith(
                                "*** Se recibió cotización BLOQUEADA"
                            )
                        ) {
                            var props = {
                                nombre:
                                    cotizacionValues.nombre + " " + cotizacionValues.apellido,
                                email: cotizacionValues.email!,
                                telefono:
                                    cotizacionValues.telefono?.prefijo +
                                    "" +
                                    cotizacionValues.telefono?.numero,
                                vehiculo:
                                    cotizacionValues.marca?.value +
                                    " " +
                                    cotizacionValues.version?.value +
                                    " " +
                                    cotizacionValues.año?.value,
                                aseguradora: "Sin seleccionar",
                                tipo: "emision",
                            };
                            compania
                                ? nav("/auto/gracias-alt/" + compania, { state: props })
                                : nav("/auto/gracias-alt", { state: props });
                        } else {
                            nav("/auto/error");
                        }
                    } else {
                        compania
                            ? nav(
                                "/auto/cotizacion/" +
                                compania +
                                "/codigo-postal?error=Error de conexion."
                            )
                            : nav(
                                "/auto/cotizacion/codigo-postal?error=Error de conexion."
                            );
                    }
                };
                fetchData();
            }
        } else {
            compania
                ? nav("/auto/cotizacion/" + compania + "/patente")
                : nav("/auto/cotizacion/patente");
        }
    }, []);

    useEffect(() => {
        if (cotizacionValues.contactoId) {
            dispararCotizacion();
        }
        return () => {
            source.cancel();
        };
    }, [cotizacionValues.contactoId]);

    const esVigenciaCotizacionesValida = (): boolean => {
        if (
            cotizacionValues.cotizaciones &&
            cotizacionValues.cotizaciones.length > 0
        ) {
            const fechaEnSesion = new Date(
                cotizacionValues.fechaConsultaCotizaciones + ""
            );
            const fechaActual = new Date();
            if (
                fechaActual.getFullYear() !== fechaEnSesion?.getFullYear() ||
                fechaActual.getMonth() !== fechaEnSesion.getMonth() ||
                fechaActual.getDate() !== fechaEnSesion.getDate()
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const dispararCotizacion = async () => {
        if (esVigenciaCotizacionesValida()) return;

        setCotizando(true);
        let nuevasCotis: Cotizacion[] = [];
        try {
            const fechaConsultaCotizaciones = new Date();
            await Promise.all(
                cotizacionValues.parametrosIniciales.companias.map(
                    async (compania: any, index: number, array: []) => {
                        const results = await cotizarInterno(compania, index);
                        if (results && results.length > 0) {
                            nuevasCotis = nuevasCotis.concat(results);
                            setCotizacionValues({
                                ...cotizacionValues,
                                fechaConsultaCotizaciones,
                                cotizaciones: nuevasCotis,
                            });
                        }
                    }
                )
            );
        } catch (error) {
            setCotizacionValues({
                ...cotizacionValues,
                cotizaciones: [],
            });
        } finally {
            setCotizando(false);
        }
    };

    /*const fetchCotis = async (compania: any, index: number) => {
          return await cotizarInterno(compania, index);
      }*/

    const encontrarRuta = (compania: string) => {
        if (compania) {
            let find = imagesCompanias.find(
                (comp) =>
                    comp.nombreCompania.toLocaleLowerCase() ===
                    compania.toLocaleLowerCase()
            );
            if (find) {
                return find.imgRuta;
            } else {
                return "";
            }
        } else {
            return "";
        }
    };

    const irAInicio = (event: any) => {
        event.preventDefault();
        setCotizacionValues({
            ...cotizacionValues,
            contactoId: undefined,
        });
        if (cotizacionValues.patente) {
            // nav("/auto/cotizacion/patente");
            compania
                ? nav("/auto/cotizacion/" + compania + "/patente")
                : nav("/auto/cotizacion/patente");
        } else {
            // nav("/auto/cotizacion/marca");
            compania
                ? nav("/auto/cotizacion/" + compania + "/marca")
                : nav("/auto/cotizacion/marca");
        }
    };

    const segLoadingComponent = useMemo(
        () => (
            <SegLoading
                loadingTitle="Cargando"
                product={
                    cotizacionValues?.marca?.label +
                    " " +
                    cotizacionValues?.version?.label
                }
                captions={[
                    {
                        value: "Gratuito",
                    },
                    {
                        value: "Online",
                    },
                    {
                        value: "Personalizado",
                    },
                ]}
                backgroundColor={origen !== "SEGURARSE" ? config.colors.primary : undefined}
                productTextColor={origen !== "SEGURARSE" ? config.colors.tertiary : undefined}
                dotsColors={origen !== "SEGURARSE" ? [config.colors.secondary, config.colors.tertiary, config.colors.accent] : undefined}
            />
        ),
        [cotizacionValues?.marca?.label, cotizacionValues?.version?.label]
    );
    return (
        <>
            <ModalPrecios cotizacionDesc={{ ...emisionValues.cotizacionDesc! }} />
            {cargando ? (
                segLoadingComponent
            ) : cotizacionValues.año?.value !== "0 Km" &&
                new Date().getFullYear() - parseInt(cotizacionValues.año?.value!) >=
                parseInt(process.env.REACT_APP_ANIO_GRACIAS!) ? (
                <GraciasAuto
                    nombre={cotizacionValues.nombre + " " + cotizacionValues.apellido}
                    email={cotizacionValues.email!}
                    telefono={
                        cotizacionValues.telefono?.prefijo +
                        "" +
                        cotizacionValues.telefono?.numero
                    }
                    vehiculo={
                        cotizacionValues.marca?.value +
                        " " +
                        cotizacionValues.version?.value +
                        " " +
                        cotizacionValues.año?.value
                    }
                    tipo="vehiculoantiguo"
                />
            ) : (
                <div>
                    <SegSvg />
                    <header className="site-header sticky-lg-top" 
                    style={{ background: origen !== "SEGURARSE" ? config.colors.navbar : "#ffffff" }}>
                        <div className="container">
                            {
                                origen !== "SEGURARSE" ?
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3 text-center d-flex justify-content-center gap-3">
                                            {" "}
                                            {/* Centra el contenido en la columna */}
                                            <SegHeaderLogo
                                                img={config.images.logo}
                                                alt="Logo Segurarse"
                                                width={window.innerWidth > 768 ? "130px" : "100px"}
                                                style={{ filter: "brightness(0) invert(1)" }}
                                            />
                                            <div style={{ height: "100%", width: "2px", backgroundColor: "#ffffff" }}></div>
                                            <SegHeaderLogo
                                                img={segurarse}
                                                alt="Logo Segurarse"
                                                width={window.innerWidth > 768 ? "140px" : "110px"}
                                                style={{ filter: "brightness(0) invert(1)" }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3 text-center">
                                            {" "}
                                            {/* Centra el contenido en la columna */}
                                            <SegHeaderLogo
                                                img={segurarse}
                                                alt="Logo Segurarse"
                                                width="160px"
                                            />
                                        </div>
                                    </div>
                            }

                        </div>
                    </header>
                    <main className="sec-precios">
                        <div className="main-wrapper">
                            <div className="ac-desktop" style={origen !== "SEGURARSE" ? { background: config.colors.accent } : {}}>
                                <div>
                                    <b>{"Vehículo cotizado > "}</b>
                                    <b>
                                        {cotizacionValues.marca?.value +
                                            " " +
                                            cotizacionValues.version?.value +
                                            " " +
                                            cotizacionValues.año?.value}{" "}
                                        |
                                    </b>{" "}
                                    Zona de guardado: <b>{cotizacionValues.localidad?.label}</b>{" "}
                                    GNC: <b>No</b>
                                    {/* Suma asegurada: <b>${Number.parseInt(cotizacionValues.sumaAseguradaIA || "").toLocaleString("es-AR")}</b> */}
                                </div>
                                <div>
                                    <a
                                        href="/#"
                                        onClick={(e) => irAInicio(e)}
                                        className="btn1 btn1-light"
                                    >
                                        Modificar datos
                                    </a>
                                </div>
                            </div>

                            <div className="ac-mobile d-lg-none">
                                <div className="wrapper">
                                    <a
                                        className={`trigger ${isCollapsed ? "collapsed" : ""}`}
                                        data-bs-toggle="collapse"
                                        href="#collapseExample"
                                        aria-controls="collapseExample"
                                        role="button"
                                        aria-expanded="false"
                                        onClick={toggleCollapse}
                                        style={origen !== "SEGURARSE" ? { background: config.colors.accent } : {}}
                                    >
                                        <svg className="icon ico-car">
                                            <use xlinkHref="#ico-auto" />
                                        </svg>{" "}
                                        cotizado
                                        <svg className="icon ico-arrow">
                                            <use xlinkHref="#arrowright2" />
                                        </svg>
                                    </a>
                                    <div
                                        className={`collapse ${isCollapsed ? "" : "show"}`}
                                        id="collapseExample"
                                    >
                                        <div className="detail"
                                            style={origen !== "SEGURARSE" ? { background: config.colors.accent } : {}}
                                        >
                                            <p>
                                                <b>
                                                    {cotizacionValues.marca?.value +
                                                        " " +
                                                        cotizacionValues.version?.value +
                                                        " " +
                                                        cotizacionValues.año?.value}
                                                </b>
                                                <br />
                                                <b>{cotizacionValues.localidad?.label}</b> | GNC:{" "}
                                                <b>No</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ban" 
                            style={origen !== "SEGURARSE" ? { background: config.colors.primary } : {}}>
                                <div>
                                    <div>
                                        <svg className="icon">
                                            <use xlinkHref="#moneyrecive" />
                                        </svg>
                                    </div>
                                    <div>
                                        <b>Descuento online contratando ahora. </b>
                                        <br className="d-md-none" />
                                        Válido con <b>tarjeta de crédito (no prepaga)</b>
                                    </div>
                                </div>
                                {cotizando && (
                                    <div className="clearfix">
                                        <div className="spinner-border spinner-border-sm float-end"></div>
                                        <span style={{ marginRight: "5px" }} role="status">
                                            Seguimos cotizando...
                                        </span>
                                    </div>
                                )}
                            </div>
                            {window.innerWidth > 768 && (
                                <>
                                    <Slider
                                        cotizando={cotizando}
                                        cotizaciones={cotizacionValues.cotizaciones || []}
                                        setDescripciones={handleSetCotizacionDesc}
                                    />
                                    <Grilla
                                        cotizando={cotizando}
                                        cotizaciones={cotizacionValues.cotizaciones || []}
                                        setDescripciones={handleSetCotizacionDesc}
                                    />
                                </>
                            )}
                            <Cards
                                cotizando={cotizando}
                                cotizaciones={cotizacionValues.cotizaciones || []}
                                setDescripciones={handleSetCotizacionDesc}
                            />
                        </div>
                    </main>
                </div>
            )}
        </>
    );
};
