import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imageCompaniaPrepaga'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { PasosCotizar } from '../Components/PasosCotizar'
import { CompaniasSlider } from "../Components/CompaniasSlider"
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const Prepaga = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Qué es una prepaga médica y cómo funciona?',
            subTitulo: 'Una prepaga médica es un sistema de salud privado en el cual abonás una cuota mensual para acceder a servicios médicos, hospitales y especialistas. '
        },
        {
            titulo: '¿Cuál es la diferencia entre una prepaga y una obra social?',
            subTitulo: 'La principal diferencia es que la obra social es un sistema obligatorio al que contribuís mediante tu salario, mientras que la prepaga es un servicio adicional y voluntario. Las prepagas suelen ofrecer más opciones de cobertura, menor tiempo de espera, y una red más amplia de profesionales y centros de atención.'
        },
        {
            titulo: '¿Puedo cambiar de plan dentro de mi prepaga?',
            subTitulo: 'Sí, la mayoría de las prepagas permiten cambiar de plan según tus necesidades y tu presupuesto. Este cambio puede implicar una modificación en la cuota mensual y en las coberturas incluidas.'
        },
        {
            titulo: '¿Puedo tener prepaga médica si ya tengo una obra social?',
            subTitulo: 'Sí, es posible tener ambos servicios. De hecho, muchas personas eligen complementar la cobertura de su obra social con una prepaga para acceder a una mayor gama de servicios, más opciones de profesionales y centros médicos.'
        }
    ];

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Prepaga Médica'
                    subTitulo={{ __html: 'Para poder hacer todo lo que te gusta, necesitas contar con una prepaga médica que te cuide en todo momento, cotizá online y encontrá la cobertura que necesitás para tu tranquilidad.<br/><br/>¿Empezamos?' }}
                    url='/cotizador-de-seguros-salud'
                    icon='#icon-seg-prepaga'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Con una prepaga médica, podés acceder a consultas con médicos especialistas, realizar estudios de diagnóstico, obtener tratamientos médicos necesarios, e internaciones con rapidez y en centros de alta calidad. Además de otros beneficios como; descuentos en farmacias, cobertura por métodos anticonceptivos, asistencia al viajero, video consultas, entre otros.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Las coberturas dependerán del plan contratado, en general, suelen incluir:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Consultas médicas y atención ambulatoria</b>: te permite acceder a consultas con médicos especialistas y generalistas sin necesidad de derivaciones previas. También incluye estudios de diagnóstico, análisis de laboratorio y tratamientos ambulatorios.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Internación</b>: cobertura ante gastos de internación en caso de necesitar hospitalización, ya sea por enfermedad o accidente.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Emergencias y urgencias médicas</b>: atención inmediata en casos de emergencias o urgencias, ya sea en centros de salud o a través de servicios de ambulancia, con cobertura todos los días, las 24hs.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Prácticas de diagnóstico y tratamiento</b>: incluye cobertura para estudios como radiografías, tomografías, resonancias magnéticas, ecografías y otros procedimientos.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Cobertura de medicamentos</b>: ofrece descuentos o cobertura total en medicamentos.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Maternidad y pediatría</b>: cobertura de controles prenatales, parto, internación de la madre y del recién nacido, así como las consultas y controles pediátricos durante los primeros años de vida del niño.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Salud mental y bienestar emocional</b>: algunos planes incluyen consultas psicológicas y psiquiátricas, y cobertura para tratamientos específicos relacionados con la salud mental.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Odontología</b>: consultas odontológicas de rutina, ortodoncia y ortopedia funcional con profesionales de cartilla.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Oftalmología</b>: consultas con profesionales, cobertura y/o descuentos en armazones, cristales y lentes de contacto blandas.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-4">
                    <div className="row gx-lg-80">
                        <div className="col-10 col-md-8 col-xl-6 mx-auto text-center">
                            <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center">
                                Si tenés dudas, contás con nuestro equipo de especialistas para asesorarte en todo momento.
                            </p>
                            <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                                <span className="fs-18 fs-lg-20 text-black fw-bold">Chatéanos</span>
                                <svg className="svg-icon ms-2 w-12em">
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
