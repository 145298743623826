import React, { useEffect, useMemo, useState } from 'react'
import { Cotizacion } from '../../../../Pages/Auto/Precios'
import { SegCardPriceMobile } from '../../../SegCardPriceMobile/SegCardPriceMobile';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import { SegSinCotis } from '../../../SegUI/SegSinCotis/SegSinCotis';
import { useConfiguracionEmpresa } from '../../../../Services/Providers/useConfiguracionEmpresa';

interface CardProps {
    cotizaciones: any[];
    setDescripciones: (descripciones: any) => void;
    cotizando: boolean
}

export const Cards = ({
    cotizaciones,
    setDescripciones,
    cotizando,
    ...rest
}: CardProps) => {



    const CardMob = styled.div`
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #363636;
    background: #fff;
    box-shadow: 0px 0px 4px #DCE0E2;
    border-radius: 5px;
    padding: 8px;
    display: block;
    @media (min-width: 1340px) {
        max-width: 45%;
        margin: 1em 0 1em 0;
    }
    @media (max-width: 1340px) {
        max-width: 48%;
    }
    @media (max-width: 1145px) {
        max-width: 400px;
    }
    @media (max-width: 992px) {
        max-width: 350px;
    }
    @media (max-width: 805px) {
        max-width: 320px;
    }

    @media (max-width: 778px) {
        max-width: 320px;
    }
    @media (max-width: 745px) {
        max-width: 500px;
    }
    @media (max-width: 340px) {
        max-width: 280px;
        margin: 2px 2px 1.5em 2px
    }

`

    const [opcionFiltrado, setOpcionFiltrado] = useState<string>("");
    const [isAseguradoraFilter, setIsAseguradoraFilter] = useState<boolean>(false);
    const [isPrecioFilter, setIsPrecioFilter] = useState<boolean>(false);
    const {config, origen} = useConfiguracionEmpresa();

    const filtrarCotizaciones = () => {
        if (cotizaciones) {
            return cotizaciones.map(objeto => {
                // Copiar el objeto original para no modificar el array existente
                const nuevoObjeto = objeto;
                // Cambiar propiedadAFiltrar a false si no cumple con la 
                if (opcionFiltrado === "") {
                    nuevoObjeto.Mostrar = true;
                } else if (nuevoObjeto.columna !== opcionFiltrado) {
                    nuevoObjeto.Mostrar = false;
                } else {
                    nuevoObjeto.Mostrar = true;
                }
                return nuevoObjeto;
            });
        } else {
            return cotizaciones;
        }
    }

    useEffect(() => {
    }, [cotizaciones])


    const cotizacionesAjustadas = useMemo(() => {
        if (isPrecioFilter) {
            filtrarCotizaciones()
            return cotizaciones.sort((a, b) => parseInt(a.premio) - parseInt(b.premio));
        }
        if (isAseguradoraFilter) {
            filtrarCotizaciones()
            return cotizaciones.sort((a, b) => a.NombreCompania.localeCompare(b.NombreCompania));
        }
        return filtrarCotizaciones()


    }, [isAseguradoraFilter, isPrecioFilter, cotizaciones, filtrarCotizaciones])


    useEffect(() => {
        document.querySelectorAll('.tab-pane').forEach((p) => {
            p.id === "tab-9-pane" && p.classList.add('show', 'active');
            p.id === "tab-0-pane" && p.classList.add('show', 'active');
        });
        document.querySelectorAll('.nav-item > .nav-link').forEach((p) => {
            p.id === "tab-0" && p.classList.add('active');
            p.id === "tab-9" && p.classList.add('active');
        });
    }, [])



    const handleDescripcion = (event: React.MouseEvent, coti: Cotizacion) => {
        if (coti) {
            const nuevaDesc: any = {
                id: coti.id,
                amountMonth: coti.premio,
                companyLogo: coti.LogoCompania,
                companyName: coti.NombreCompania,
                codCompany: coti.CodigoCompania,
                descList: coti.descripciones,
                discountAmount: coti.PrecioSinDescuento,
                discountPerc: coti.DescuentoPorcentaje,
                fiscalInfoImg: coti.Afip,
                nombreCompania: coti.NombreCompania,
                planName: coti.nombreCobertura,
                sumaAsegurada: coti.SumaAsegurada
            }
            setDescripciones(nuevaDesc);
        }
    }

    const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setOpcionFiltrado(e.target.value)
    }

    const handlePrecioFilterChange = () => {
        setIsPrecioFilter(!isPrecioFilter);
        if (isAseguradoraFilter) {
            setIsAseguradoraFilter(!isAseguradoraFilter)
        }
    };

    const handleAseguradoraFilterChange = () => {
        setIsAseguradoraFilter(!isAseguradoraFilter);
        if (isPrecioFilter) {
            setIsPrecioFilter(!isPrecioFilter)
        }
    };


    return (
        <>
            <div className="mobile-filter bg-d9d9d9 d-lg-none sticky-top">
                <form className="container py-2">
                    <div className="row g-0 mb-1">
                        <label htmlFor="cboCoberturas" className="col-4 col-form-label">Cobertura</label>
                        <div className="col-8">
                            <select onChange={onChangeSelect} value={opcionFiltrado} className="form-select form-select-sm border-0" id="cboCoberturas">
                                <option value="">Todas las coberturas</option>
                                <option value="B">Terceros Completo</option>
                                <option value="C">Terceros Completo Full</option>
                                <option value="D">Todo Riesgo</option>
                                <option value="A">Responsabilidad civil</option>
                            </select>
                        </div>
                    </div>
                    {/* <fieldset className="d-flex g-0">
                        <legend className="col-4 col-form-label pt-0">Ordenar por:</legend>
                        <div className="col-8">
                            <div className="form-check form-switch form-check-reverse form-check-inline" >
                                <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                                <label className="form-check-label fs-14" htmlFor="chkAseguradora">Aseguradora</label>
                            </div>
                            <div className="form-check form-switch form-check-reverse form-check-inline" style={{ paddingRight: "1.8em" }}>
                                <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                                <label className="form-check-label fs-14" htmlFor="chkPrecio">Precio</label>
                            </div>
                        </div>
                    </fieldset> */}

                    <div className="d-flex align-items-center">
                        <label className="col-4 col-form-label">Ordenar Por:</label>
                        <label className="form-check-label fs-14 mx-1" htmlFor="chkAseguradora">Aseguradora</label>
                        <label className="form-switch">
                            <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                        </label>
                        <label className="form-check-label fs-14 mx-1" htmlFor="chkPrecio">Precio</label>
                        <label className="form-switch">
                            <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                        </label>
                    </div>

                    {/* <div className="d-flex flex-wrap align-items-center">
                            <label className="col-4 col-form-label">Ordenar Por:</label>
                            <label className="form-check-label fs-14" htmlFor="chkAseguradora">Aseguradora</label>
                            <div className="form-switch" style={{ display: 'inline-block' }}>
                                <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                            </div>
                            <label className="fs-14" htmlFor="chkPrecio">Precio</label>
                            <div className="form-switch" style={{ display: 'inline-block' }}>
                                <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                            </div>
                        </div>

                    <fieldset className="row g-0">
                        <legend className="col-4 col-form-label pt-0">Ordenar por:</legend>
                        <div className="col-8">
                            <div className="form-check form-switch form-check-reverse" style={{ display: 'inline-block' }}>
                                <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                                <label className="form-check-label fs-14" htmlFor="chkAseguradora">Aseguradora</label>
                            </div>
                            <div className="form-check form-switch form-check-reverse" style={{ display: 'inline-block' }}>
                                <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                                <label className="form-check-label fs-14" htmlFor="chkPrecio">Precio</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="col-12 row g-0 align-items-center">
                        <div className="d-flex flex-wrap align-items-center">
                            <label className="col-4 col-form-label">Ordenar Por:</label>
                            <label className="form-check-label fs-14" htmlFor="chkAseguradora">Aseguradora</label>
                            <div className="form-switch" style={{ display: 'inline-block' }}>
                                <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                            </div>
                            <label className="fs-14" htmlFor="chkPrecio">Precio</label>
                            <div className="form-switch" style={{ display: 'inline-block' }}>
                                <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="col-12 row g-0 align-items-center">
                        <div className="d-flex flex-wrap align-items-center">
                            <label className="col-4 col-form-label">Ordenar Por:</label>

                            <div>
                                <label className="form-check-label fs-14" htmlFor="chkAseguradora">Aseguradora</label>
                            </div>
                            <div className="form-check form-switch form-check-reverse" style={{ display: 'inline-block' }}>
                                <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                            </div>
                            
                            <div><label className="form-check-label fs-14" htmlFor="chkPrecio">Precio</label></div>
                            <div className="form-check form-switch form-check-reverse" style={{ display: 'inline-block' }}>
                                <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="col-12 row g-0 align-items-center">
                        <div className="d-flex flex-wrap align-items-center">
                            <label className="col-4 col-form-label">Ordenar Por:</label>
                            <div className="col-3 form-check form-switch form-check-reverse" style={{ display: 'inline-block' }}>
                                <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                                <label className="form-check-label fs-14" htmlFor="chkAseguradora">Aseguradora</label>
                            </div>
                            <div className="col-3 form-check form-switch form-check-reverse" style={{ display: 'inline-block' }}>
                                <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                                <label className="form-check-label fs-14" htmlFor="chkPrecio">Precio</label>
                            </div>
                        </div>
                    </fieldset> */}
                </form>
            </div>
            <div className="cob d-none d-lg-block sticky-lg-top2">
                <ul className="nav nav-tabs gap-2" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-1" onClick={(e) => setOpcionFiltrado("A")} data-bs-toggle="tab" data-bs-target="#tab-1-pane" type="button" role="tab" aria-controls="tab-1-pane" aria-selected="true">Responsabilidad Civil</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-2" onClick={(e) => setOpcionFiltrado("B")} data-bs-toggle="tab" data-bs-target="#tab-2-pane" type="button" role="tab" aria-controls="tab-2-pane" aria-selected="false">Terceros Completo</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-3" onClick={(e) => setOpcionFiltrado("C")} data-bs-toggle="tab" data-bs-target="#tab-3-pane" type="button" role="tab" aria-controls="tab-3-pane" aria-selected="false">Terceros Completo Full</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-4" onClick={(e) => setOpcionFiltrado("D")} data-bs-toggle="tab" data-bs-target="#tab-4-pane" type="button" role="tab" aria-controls="tab-4-pane" aria-selected="false">Todo Riesgo</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-0" onClick={(e) => setOpcionFiltrado("")} data-bs-toggle="tab" data-bs-target="#tab-0-pane" type="button" role="tab" aria-controls="tab-0-pane" aria-selected="false">Todas las coberturas</button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show" id="tab-1-pane" role="tabpanel" aria-labelledby="tab-1" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio (No todas las compañías)</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-2-pane" role="tabpanel" aria-labelledby="tab-2" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Robo/Hurto</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Incendio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Parciales</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-3-pane" role="tabpanel" aria-labelledby="tab-3" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Robo/Hurto</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Incendio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Parciales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cristales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cerraduras</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Granizo</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-4-pane" role="tabpanel" aria-labelledby="tab-4" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Robo/Hurto</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Incendio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Parciales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cristales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cerraduras</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Granizo</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Daños parciales por accidente</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-0-pane" role="tabpanel" aria-labelledby="tab-0" tabIndex={0}>Todas las coberturas</div>
                </div>
            </div>

            <div className="cards2-wrapper">
                {cotizacionesAjustadas && cotizacionesAjustadas.map((coti, i) =>
                    coti && coti.Mostrar &&
                    (<SegCardPriceMobile 
                        rubro={"AUTO"} 
                        amountMonth={coti.premio} 
                        companyLogo={coti.LogoCompania} 
                        companyName={coti.NombreCompania} 
                        descList={coti.DescResumen} 
                        discountAmount={coti.PrecioSinDescuento} 
                        discountPerc={coti.DescuentoPorcentaje + "%"} 
                        fiscalInfoImg={coti.Afip} 
                        planName={coti.nombreCobertura} 
                        onClick={(e) => handleDescripcion(e, coti)} 
                        configEmpresa={config.origen !== "SEGURARSE" ? config : undefined}
                        key={i} 
                        />)
                )}
                {
                    ["A", "B", "C", "D", ""].map(col => {
                        if (opcionFiltrado === col && cotizando) {
                            return (
                                <CardMob key={"skeleton-cards-" + col}>
                                    <Skeleton
                                        containerClassName="flex-1"
                                        count={1}
                                        style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                                    <Skeleton
                                        containerClassName="flex-1"
                                        count={3}
                                        circle={false}
                                        style={{ height: "15px", margin: "10px 0" }}
                                    />
                                </CardMob>
                            )
                        } else if (!cotizando && opcionFiltrado === col && opcionFiltrado !== "" && cotizacionesAjustadas.filter((coti) => coti.columna === col).length === 0) {
                            return <SegSinCotis mostrarWpp={origen === "SEGURARSE"}/>
                        }
                    })
                }
                {
                    (!cotizando && opcionFiltrado === "" && cotizacionesAjustadas.length === 0) 
                    && <SegSinCotis mostrarWpp={origen === "SEGURARSE"}/>
                }
                {/*
                {opcionFiltrado === "A" && cotizacionesAjustadas.filter((coti) => coti.columna === "A").length === 0 &&
                    (cotizando ?
                        <CardMob>
                            <Skeleton
                                containerClassName="flex-1"
                                count={1}
                                style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                            <Skeleton
                                containerClassName="flex-1"
                                count={3}
                                circle={false}
                                style={{ height: "15px", margin: "10px 0" }}
                            />

                        </CardMob> :
                        <SegSinCotis />
                    )
                }
                {opcionFiltrado === "B" && cotizacionesAjustadas.filter((coti) => coti.columna === "B").length === 0 &&
                    (cotizando ?
                        <CardMob>
                            <Skeleton
                                containerClassName="flex-1"
                                count={1}
                                style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                            <Skeleton
                                containerClassName="flex-1"
                                count={3}
                                circle={false}
                                style={{ height: "15px", margin: "10px 0" }}
                            />

                        </CardMob> :
                        <SegSinCotis />
                    )
                }
                {opcionFiltrado === "C" && cotizacionesAjustadas.filter((coti) => coti.columna === "C").length === 0 &&
                    (cotizando ?
                        <CardMob>
                            <Skeleton
                                containerClassName="flex-1"
                                count={1}
                                style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                            <Skeleton
                                containerClassName="flex-1"
                                count={3}
                                circle={false}
                                style={{ height: "15px", margin: "10px 0" }}
                            />

                        </CardMob> :
                        <SegSinCotis />
                    )
                }
                {opcionFiltrado === "D" && cotizacionesAjustadas.filter((coti) => coti.columna === "D").length === 0 &&
                    (cotizando ?
                        <CardMob>
                            <Skeleton
                                containerClassName="flex-1"
                                count={1}
                                style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                            <Skeleton
                                containerClassName="flex-1"
                                count={3}
                                circle={false}
                                style={{ height: "15px", margin: "10px 0" }}
                            />

                        </CardMob>
                        :
                        <SegSinCotis />
                    )
                }
                {opcionFiltrado === "" && cotizacionesAjustadas.length === 0 &&
                    (cotizando ?
                        <CardMob>
                            <Skeleton
                                containerClassName="flex-1"
                                count={1}
                                style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                            <Skeleton
                                containerClassName="flex-1"
                                count={3}
                                circle={false}
                                style={{ height: "15px", margin: "10px 0" }}
                            />
                        </CardMob>
                        :
                        <SegSinCotis />
                    )
                }
                */}
            </div>
        </>
    )
}
