import React, { useEffect, useState } from 'react'
import { SegSvg } from '../../Components/SegUI/SegSvg/SegSvg'
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo'
import logoblue from "../../Assets/img/logo_segurarse.svg"
import styled from '@emotion/styled'
import { useCotizacionStore, ICotizacionValues } from '../../Services/Providers/CotizacionStore'
import { useNavigate, useParams } from 'react-router-dom'
import { RubroTypes } from '../../types/rubro';

interface GraciasProps {
    viewDetail?: RubroTypes.GraciasViewDetail
}

type Campos = {
    [key: string]: string;
};

const StyledDiv = styled.div`
height: 60vh;
@media (max-width: 458px) {
    height: 60vh;
    overflow-y: auto
}
 `
const StyledSvgContainer = styled.div`
height: 100%;
min-height: 25vh;
@media (max-width: 458px) {
    min-height: 20vh;
}`

const StyledWppContact = styled.a`
p{
margin-top: .5rem;
}
@media (max-width: 458px) {
flex-direction: column;
align-items: center;
p{
margin-bottom: 0;
}
.final-text{
    margin-top: 0;
margin-bottom: 16px;
}
}
`

const StyledDetailBox = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    @media (max-width: 458px) {
        padding: 0;
        }
`

function convertirObjeto(campos: Campos, data: ICotizacionValues): Campos {
    const resultado: Campos = {};

    for (const [clave, valor] of Object.entries(campos)) {
        const propiedades = valor.split(',');
        const valorFinal: string[] = [];

        if (clave === "Teléfono" && data.telefono) {
            const valorTelefono = `0${data.telefono.prefijo}${data.telefono.numero}`;
            valorFinal.push(valorTelefono);
        } else if (clave === "Fecha de Nacimiento" && data.fechaNac) {
            const valorFechaNac = `${data.fechaNac.dia}/${data.fechaNac.mes}/${data.fechaNac.año}`;
            valorFinal.push(valorFechaNac);
        } else {
            propiedades.forEach((propiedad) => {
                if (Object.prototype.hasOwnProperty.call(data, propiedad)) {
                    const valor = data[propiedad as keyof ICotizacionValues];
                    if (typeof valor === 'string') {
                        valorFinal.push(valor);
                    } else {
                        valorFinal.push('');
                    }
                } else {
                    valorFinal.push('');
                }
            });
        }

        resultado[clave] = valorFinal.join(' ').trim();
    }

    return resultado;
}

function hasValidValues(campos: Campos): boolean {
    return Object.values(campos).some(value => value !== '');
}


export const Gracias: React.FC<GraciasProps> = ({ viewDetail }) => {
    const nav = useNavigate();
    const [vistaGracias, setVistaGracias] = useState<GraciasProps>()
    const { cotizacionValues, resetCotizacionValues } = useCotizacionStore();
    const { compania } = useParams();
    const [camposCompletos, setCamposCompletos] = useState<Campos>()

    useEffect(() => {
        if (vistaGracias?.viewDetail) {
            const nuevosCampos = convertirObjeto(vistaGracias.viewDetail.campos, cotizacionValues);

            if (hasValidValues(nuevosCampos)) {
                setCamposCompletos(nuevosCampos);
            } else {
                const savedData = localStorage.getItem('camposGracias');
                if (savedData) {
                    setCamposCompletos(JSON.parse(savedData));
                }
            }
        } else {
            const savedData = localStorage.getItem('camposGracias');
            if (savedData) {
                setCamposCompletos(JSON.parse(savedData));
            }
        }
    }, [viewDetail, vistaGracias, cotizacionValues]);


    useEffect(() => {
        if (camposCompletos && hasValidValues(camposCompletos)) {
            localStorage.setItem('camposGracias', JSON.stringify(camposCompletos));
        }
    }, [camposCompletos])


    useEffect(() => {
        const savedVistaGracias = localStorage.getItem('vistaGracias');

        if (!vistaGracias && savedVistaGracias && savedVistaGracias !== "undefined") {
            setVistaGracias(JSON.parse(savedVistaGracias));
        } else {
            resetCotizacionValues()
            nav('/', { replace: true })
        }
    }, [])


    useEffect(() => {
        const handleBackNavigation = () => {
            resetCotizacionValues()
            window.removeEventListener('popstate', handleBackNavigation)
            nav('/', { replace: true })
        }
        window.addEventListener('popstate', handleBackNavigation)
    }, [nav])

    return (
        <div>
            <SegSvg />
            <div className=''>
                <div className="container d-flex align-items-center justify-content-center" style={{ height: "7vh" }}>
                    <div className="row">
                        <div className="">
                            <SegHeaderLogo img={logoblue} alt="Logo Segurarse" width="160px" />
                        </div>
                    </div>
                </div>
            </div>
            <StyledSvgContainer className="bg-primary text-center d-flex justify-content-center align-items-center pb-3">
                <svg className="" style={{ maxWidth: "30vw", height: "12vh", alignSelf: "self-end" }}>
                    <use xlinkHref={`#${vistaGracias?.viewDetail?.icon}`} fill='white' />
                </svg>
            </StyledSvgContainer>
            <StyledDiv className='bg-primary text-center d-flex flex-column'>
                <h1 className='fs-2 text-white'>¡Recibimos tu solicitud!</h1>
                <p className='mx-4 mt-3 text-white fs-5'>En breve nos comunicaremos con vos para asesorarte en la mejor cobertura {cotizacionValues?.tipoDoc?.value === "cuit" ? "para tu empresa" : ""}</p>
                <div className='mb-5 mt-4 ps-3 pe-3'>
                    <div className='bg-white rounded container h-100 d-flex flex-column justify-content-between min-vh-50'>
                        <StyledDetailBox>
                            {camposCompletos && Object.entries(camposCompletos).map(([key, value]) => (
                                <div key={key} className='col-12 col-sm-11 col-md-6 col-lg-4 pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div>
                                            <svg style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#card-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>{key}:</span>
                                        <span className='fw-bold'>{value}</span>
                                    </div>
                                </div>
                            ))}
                        </StyledDetailBox>
                        <StyledWppContact href={`https://wa.me/${vistaGracias?.viewDetail?.contactoWpp.numero}?text=${vistaGracias?.viewDetail?.contactoWpp.mensaje}`} target='_blank' rel='noreferrer' className='text-black mt-2 d-flex justify-content-center gap-2' >
                            <p className='fw-bold'>¿Querés continuar por WhatsApp?</p>
                            <p className='d-flex gap-1 align-items-center final-text'>
                                <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                                <span className='fw-normal'>
                                    Chatéanos</span></p>
                        </StyledWppContact>
                    </div>
                </div>

            </StyledDiv>
        </div>
    )
}
